import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(
  () => ({
    linkIcon: {
      width: 20,
      float: 'left',
      marginRight: 6
    },
    card: {
      '&:hover': {
        backgroundColor: 'lightgray'
      }
    },
    cardContent: {
      whiteSpace: 'nowrap',
      marginRight: 26
    }
  }),
  { classNamePrefix: "wp" }
);

function LinkCard({ linkInfo }) {
  const classes = useStyles();

  return (
    <a href={linkInfo.url} target="_new" rel="noreferrer">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <img
            src={process.env.PUBLIC_URL + "/img/links/" + linkInfo.icon}
            alt="linkicon"
            className={classes.linkIcon}
          ></img>

          <Typography>{linkInfo.name}</Typography>
        </CardContent>
      </Card>
    </a>
  );
}

export default React.memo(LinkCard);
