/** Upload View */
import { useState, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// local imports
import { AxiosGetHook } from '../../hooks/axiosHook';
import UpdateDocumentForm from '../../components/UpdateDocumentForm/UpdateDocumentForm';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { Routes as AppRoutes } from '../../routes/appRoutes';
import ApiAgent, { Routes as ApiRoutes } from '../../utils/apiAgent';
import { networkErrorMessageObjFactory } from '../../utils/userFeedback';
import Fab from '../UpdateDocument/Fab/Fab';

const useStyles = makeStyles(
  theme => ({
    cardWrapper: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(10),
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
  { classNamePrefix: 'wp' }
);

/**
 * UpdateDocument
 * @returns UpdateDocument View Component
 */

function UpdateDocument() {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const token = useSelector(state => state.user.token);
  const params = useRef({ _token: token });
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [deleteFormFeedback, setDeleteFormFeedback] = useState(null);

  // get document metadata hook
  const url = ApiRoutes.documentMetadata(id);
  const { resp, error, loading } = AxiosGetHook(url, params.current);

  const handleDelete = useCallback(async () => {
    const deleteResp = await ApiAgent.deleteDocument(id, {
      _token: token,
      category: resp.category,
    });
    if (deleteResp.error) {
      setDeleteFormFeedback(networkErrorMessageObjFactory(deleteResp.error));
    } else {
      history.replace(AppRoutes.documents);
    }
  }, [history, id, resp, token]);

  return (
    <>
      <Container component="main" maxWidth="lg">
        <div className={classes.cardWrapper}>
          <Card className={classes.card}>
            <Avatar className={classes.avatar}>
              <EditIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Update Document
            </Typography>
            <Box mt={2} />
            <CardContent className={'w-100 d-flex-col-start-center'}>
              <UpdateDocumentForm
                id={id}
                error={error}
                loading={loading}
                data={resp}
              />
            </CardContent>
          </Card>
          <Box mb={8} />
        </div>
      </Container>

      <Fab id={id} setDelModalOpen={setDelModalOpen} />
      <DeleteModal
        delModalOpen={delModalOpen}
        setDelModalOpen={setDelModalOpen}
        name={'document'}
        handleDelete={handleDelete}
        formFeedback={deleteFormFeedback}
      />
    </>
  );
}

export default UpdateDocument;
