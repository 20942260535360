import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { makeStyles, Button } from "@material-ui/core";
import { decode } from "jsonwebtoken";

import headerBg from "../../../assets/header-bg.png";
import logo from "../../../assets/logo.png";
import logout from "../../../redux/actions/user/logout";
import { Routes as AppRoutes } from "../../../routes/appRoutes";

const useStyles = makeStyles(
  (theme) => ({
    logoutButton: {
      color: "white",
      marginRight: 10,
      [theme.breakpoints.up("sm")]: {
        marginRight: 50,
      },
      "&:hover": {
        color: "lightblue",
      },
    },
  }),
  { classNamePrefix: "wp" }
);

export const SiteHeader = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onButtonClick, page, hideMenu, token, pathname } = props;
  const fullName = token ? `${decode(token).firstname} ${decode(token).lastname}` : "";

  const [isSmallerScreen, setIsSmallerScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    window.addEventListener("resize", resizeWindow);

    return () => {
      window.removeEventListener("resize", resizeWindow);
    };

    function resizeWindow() {
      setIsSmallerScreen(window.innerWidth < 768);
    }
  }, [isSmallerScreen]);

  const handleLogout = () => {
    dispatch(logout());
    const ssoIdToken = sessionStorage.getItem("sso_idToken");
    if (ssoIdToken) {
      sessionStorage.removeItem("sso_idToken");
      window.location = "/logoutsso/";
    }
  };

  const isWorkplacePage = (pathname) => {
    switch (pathname) {
      case AppRoutes.employmentApplication: {
        return false;
      }
      case AppRoutes.continueApplication: {
        return false;
      }
      default: {
        return true;
      }
    }
  };

  return (
    <header className="header no-print" style={{ backgroundImage: `url(${headerBg})` }}>
      <div className="nav-container">
        {!hideMenu && (
          <div className="menu-button">
            <span
              className={"k-icon hamburger-icon"}
              onClick={onButtonClick}
              onKeyPress={onButtonClick}
              tabIndex={0}
              role="button"
              aria-label="Toggle Menu"
            />
          </div>
        )}

        <div className="title">
          <img src={logo} alt={"Northeast Professional Home Care"} />
          {isWorkplacePage(pathname) && <h1>Workplace Intranet</h1>}

          {isWorkplacePage(pathname) && page && <span className="vl"></span>}

          {page && <h2>{page}</h2>}
        </div>
        {token && (
          <div>
            <Button onClick={handleLogout} className={classes.logoutButton}>
              logout
            </Button>
            {!isSmallerScreen && (
              <div>
                <small>{fullName}</small>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
});

SiteHeader.displayName = "Header";
SiteHeader.propTypes = {
  page: PropTypes.string,
  hideMenu: PropTypes.bool,
  onButtonClick: PropTypes.func,
};
