import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from '@progress/kendo-react-form';

// local
import { basicValidator } from '../../../../../components/form-validators';
import {
  FormInput,
  FormTextArea,
} from '../../../../../components/form-components';
import { FormFeedback } from '../../../../../utils/userFeedback';

const useStyles = makeStyles(
  () => ({
    answers: {
      marginTop: 30,
    },
    answersButton: {
      marginTop: 30,
    },
  }),
  { classNamePrefix: 'wp' }
);

const QuestionTaskFields = ({
  formFeedback,
  feedbackRef,
  dataItem,
  formRenderProps,
  answer,
  setAnswer,
}) => {
  const classes = useStyles();
  const [choices, setChoices] = useState(dataItem.choices || []);

  const handleRadioChange = useCallback(
    ev => {
      // make form change to un-disable submit button if radio button clicked
      formRenderProps.onChange('title', {
        value: formRenderProps.valueGetter('title'),
      });
      setAnswer(+ev.target.value);
    },
    [formRenderProps, setAnswer]
  );

  const addQuizChoice = useCallback(() => {
    setChoices(choices => [...choices, {}]);
  }, []);

  const removeQuizChoice = useCallback(
    choiceNumber => {
      // set choice value null to hide choice on form
      formRenderProps.onChange(`choice${choiceNumber}`, {
        value: null,
      });
      // if this was answer reset answer
      if (answer === choiceNumber) setAnswer(null);
    },
    [answer, setAnswer, formRenderProps]
  );

  return (
    <>
      <Field
        id={'text'}
        name={'text'}
        label={'Question'}
        component={FormTextArea}
        validator={basicValidator}
      />

      <Typography variant="body2" component="h2" className={classes.answers}>
        Answers
      </Typography>

      {formFeedback && (
        <FormFeedback feedback={formFeedback} feedbackRef={feedbackRef} />
      )}

      {choices.map((item, idx) => {
        const choiceName = `choice${idx + 1}`;
        const currentChoiceValue = formRenderProps.valueGetter(choiceName);
        // no choice input-row if value is null
        if (currentChoiceValue === null) return <div key={choiceName}></div>;
        return (
          <Grid
            container
            spacing={3}
            justify={'flex-start'}
            alignItems={'flex-end'}
            key={choiceName}
          >
            <Grid item xs="auto">
              <input
                className="k-radio"
                type="radio"
                name="answer"
                checked={answer === idx + 1}
                onChange={handleRadioChange}
                value={idx + 1}
              />
            </Grid>
            <Grid item xs={10}>
              <Field
                id={choiceName}
                name={choiceName}
                component={FormInput}
                type={'text'}
              />
            </Grid>
            <Grid item xs="auto">
              <IconButton
                aria-label="delete"
                onClick={() => removeQuizChoice(idx + 1)}
              >
                <DeleteForeverRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}

      <Button
        variant="contained"
        className={classes.answersButton}
        onClick={addQuizChoice}
      >
        Add Answer
      </Button>
    </>
  );
};

export default QuestionTaskFields;
