/** Login View */

import { useRef, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";

// local imports
import LoginForm from "../../components/LoginForm/LoginForm";
import City from "../../assets/City-day.mp4";

const useStyles = makeStyles(
  (theme) => ({
    videoBackground: {
      minHeight: "calc(100vh - 80px)",
      maxHeight: "calc(100vh - 80px)",
      position: "fixed",
      objectFit: "cover",
      width: "100%",
      zIndex: -10,
    },
    root: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        paddingTop: theme.spacing(2),
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(12),
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(20),
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: theme.spacing(25),
      },
    },
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      padding: `${theme.spacing(5)}px ${theme.spacing(4)}px`,
      [theme.breakpoints.up("sm")]: {
        padding: `${theme.spacing(1)}px ${theme.spacing(8)}px`,
      },
      [theme.breakpoints.up("md")]: {
        padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    formWrapper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(1),
        marginTop: 0,
      },
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
  { classNamePrefix: "wp" }
);

/**
 * Login
 * @returns Login View Component
 */

function Login() {
  const classes = useStyles();
  const videoBackgroundRef = useRef(null);

  useEffect(() => {
    videoBackgroundRef.current.playbackRate = 0.75;
  }, []);

  return (
    <>
      <video
        className={classes.videoBackground}
        autoPlay
        loop
        muted
        playsInline
        id="videoBackground"
        ref={videoBackgroundRef}
      >
        <source src={City} type="video/mp4"></source>
        <track default kind="captions" srcLang="en" />
      </video>
      <Container component="main" maxWidth="sm" className={classes.root}>
        <Card className={classes.card}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box className={classes.formWrapper}>
            <LoginForm />
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default Login;
