import { useEffect, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Form, FormElement, Field } from '@progress/kendo-react-form';

// local
import { updateTasksCompleted } from '../../../redux/actions/thunkCreators';
import { FormInput } from '../../form-components';

const useStyles = makeStyles(
  theme => ({
    header: {
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(6),
      },
    },
    title: {
      fontSize: 'min(2vw + 14px, 4vh + 14px)',
    },
    subtitle: {
      fontSize: 'min(1.5vw + 12px, 2.5vh + 12px)',
    },
    formElement: {
      width: 'clamp(300px, 500px, 90vw)',
      margin: 'auto',
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(7),
      },
    },
    nameInputDiv: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(6),
      },
    },
    nameInput: {
      backgroundColor: '#f5f5f5',
      height: 60,
      textAlign: 'center',
      '&:hover': {
        backgroundColor: '#e7e7e7',
      },
    },
    buttonBox: {
      paddingBottom: theme.spacing(2),
    },
  }),
  { classNamePrefix: 'wp' }
);

function Signature({
  currTask,
  setAllowNext,
  currTaskDone,
  setTaskNumber,
  userCourse,
  taskNumber,
  user,
  token,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const signatureValidator = value => {
    const name = `${user.firstname} ${user.lastname}`;

    const reName = new RegExp(`^${name}$`, 'i');

    return reName.test(value)
      ? ''
      : 'Signature must match first and last name.';
  };

  const handleSubmit = useCallback(
    ev => {
      // if a new task was completed for user update user DB record
      if (!userCourse.tasks_completed[taskNumber]) {
        dispatch(
          updateTasksCompleted(user.id, userCourse.course_id, taskNumber, token)
        );
      }
      setTaskNumber(taskNumber => taskNumber + 1);
      setAllowNext(true);
    },
    [
      dispatch,
      setAllowNext,
      setTaskNumber,
      taskNumber,
      token,
      userCourse.course_id,
      userCourse.tasks_completed,
      user.id,
    ]
  );

  useEffect(() => {
    if (!currTaskDone) setAllowNext(false);
    else setAllowNext(true);
  }, [setAllowNext, currTaskDone]);

  return (
    <>
      <header className={classes.header}>
        <Typography className={classes.title}>{currTask.title}</Typography>
        <Box mt={3} />
        <Typography className={classes.subtitle} component="h3">
          {currTask.text}
        </Typography>
      </header>

      <Form
        initialValues={{
          name: currTaskDone ? `${user.firstname} ${user.lastname}` : '',
        }}
        onSubmit={handleSubmit}
        render={formRenderProps => (
          <FormElement className={classes.formElement}>
            <fieldset className={'k-form-fieldset'}>
              <Box className={classes.nameInputDiv}>
                <Field
                  name={'name'}
                  id={'name'}
                  component={FormInput}
                  label={
                    'Please type your first and last name to be used as your digital signature.'
                  }
                  validator={signatureValidator}
                  className={classes.nameInput}
                  autoComplete="none"
                />
              </Box>
            </fieldset>
            <Box mt={1}>
              <Typography variant="subtitle1" component="span">
                Signature Expected:
              </Typography>
              <Typography variant="subtitle1" color="primary" component="span">
                {' '}
                <i>
                  {user.firstname} {user.lastname}
                </i>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" mt={4} pb={4}>
              <Button
                variant="contained"
                color="primary"
                disabled={!formRenderProps.allowSubmit}
                type="submit"
              >
                Digitally Sign
              </Button>
            </Box>
          </FormElement>
        )}
      />
    </>
  );
}

export default Signature;
