import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { useHistory } from 'react-router-dom';

// local
import { Routes as AppRoutes } from '../../../routes/appRoutes';

const items = [
  { icon: 'search', text: 'Search' },
  { icon: 'plus', text: 'Upload' },
  { icon: 'trash', text: 'Delete' },
  { icon: 'file', text: 'Details' },
];

function itemClickedHandler(e, id, history, setDelModalOpen) {
  const itemClicked = e.itemIndex;

  switch (itemClicked) {
    case 3:
      history.push(AppRoutes.makeViewDocUrl(id));
      break;
    case 2:
      setDelModalOpen(delModalOpen => !delModalOpen);
      break;
    case 1:
      history.push(AppRoutes.uploadDoc);
      break;
    case 0:
      history.push(AppRoutes.documents);
      break;
    default:
      history.push(AppRoutes.documents);
  }
}

function NaviFab({ id, setDelModalOpen }) {
  const history = useHistory();
  return (
    <FloatingActionButton
      icon={'gear'}
      items={items}
      onItemClick={e => itemClickedHandler(e, id, history, setDelModalOpen)}
      themeColor="tertiary"
      alignOffset={{ x: '30px', y: '30px' }}
    />
  );
}

export default NaviFab;
