import { useState } from 'react';
import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';

// local
import {
  basicValidator,
  booleanValidator,
} from '../../components/form-validators';
import MilitaryServiceFields from './MilitaryServiceFields';
import {
  FormInput,
  FormRadioGroup,
  FormDatePicker,
} from '../../components/form-components';

const EducationFields = ({ classes, initialValues }) => {
  const [showCollege, setShowCollege] = useState(
    initialValues ? initialValues.attended_college : true
  );
  const [showVocational, setShowVocational] = useState(
    initialValues ? initialValues.attended_vocational : true
  );
  const [showMilitary, setShowMilitary] = useState(
    initialValues ? initialValues.military_service : true
  );

  const handleVisibilityStateChange = (e, setter) => {
    const value = e.value;
    if (value === true) {
      setter(true);
    } else {
      setter(false);
    }
  };

  const handleCollegeChange = e => {
    handleVisibilityStateChange(e, setShowCollege);
  };

  const handleVocationalChange = e => {
    handleVisibilityStateChange(e, setShowVocational);
  };

  const handleMilitaryChange = e => {
    handleVisibilityStateChange(e, setShowMilitary);
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field
          name={'highest_grade_completed'}
          component={FormInput}
          label={'Select highest grade completed'}
          id={'highest_grade_completed'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'scholastic_honors'}
          component={FormInput}
          label={'Scholastic Honors Received'}
          id={'scholastic_honors'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <legend className={classes.formLegendAlternate}>High School</legend>
      <Grid item xs={12} sm={6}>
        <Field
          name={'high_school'}
          component={FormInput}
          label={'Name of High School Attended'}
          id={'high_school'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'courses_taken'}
          component={FormInput}
          label={'Courses Taken'}
          id={'courses_taken'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'high_school_city'}
          component={FormInput}
          label={'City'}
          id={'high_school_city'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'high_school_state'}
          component={FormInput}
          label={'State'}
          id={'high_school_state'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          name={'high_school_completed'}
          component={FormRadioGroup}
          data={[
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ]}
          label={'High School Completed'}
          id={'high_school_completed'}
          validator={booleanValidator}
          layout={'horizontal'}
          labelStyle={{ display: 'block', fontWeight: 'bold' }}
          disabled={!!initialValues}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          name={'ged_completed'}
          component={FormRadioGroup}
          data={[
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ]}
          label={'GED'}
          id={'ged_completed'}
          validator={booleanValidator}
          layout={'horizontal'}
          labelStyle={{ display: 'block', fontWeight: 'bold' }}
          disabled={!!initialValues}
        />
      </Grid>

      <legend className={classes.formLegendAlternate}>College</legend>
      <Grid item xs={12}>
        <Field
          name={'attended_college'}
          component={FormRadioGroup}
          data={[
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ]}
          label={'Did you attend college?'}
          id={'attended_college'}
          validator={booleanValidator}
          layout={'horizontal'}
          labelStyle={{ display: 'block', fontWeight: 'bold' }}
          onChange={handleCollegeChange}
          disabled={!!initialValues}
        />
      </Grid>
      {showCollege && (
        <>
          <Grid item xs={12} sm={6}>
            <Field
              name={'college_name'}
              component={FormInput}
              label={'Name of College Attended'}
              id={'college_name'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'college_specialty'}
              component={FormInput}
              label={'Specialty'}
              id={'college_specialty'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'college_city'}
              component={FormInput}
              label={'City'}
              id={'college_city'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'college_state'}
              component={FormInput}
              label={'State'}
              id={'college_state'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={'completed_college'}
              component={FormRadioGroup}
              data={[
                {
                  label: 'No',
                  value: false,
                },
                {
                  label: 'Yes',
                  value: true,
                },
              ]}
              label={'College Completed'}
              id={'completed_college'}
              validator={booleanValidator}
              layout={'horizontal'}
              labelStyle={{ display: 'block', fontWeight: 'bold' }}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'college_grad_date'}
              component={FormDatePicker}
              label={'If yes what date did you graduate?'}
              id={'college_grad_date'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'college_degree'}
              component={FormInput}
              label={'Type of Degree or Certificate Received'}
              id={'college_degree'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
        </>
      )}

      <legend className={classes.formLegendAlternate}>
        Vocational or Business
      </legend>
      <Grid item xs={12}>
        <Field
          name={'attended_vocational'}
          component={FormRadioGroup}
          data={[
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ]}
          label={'Did you receive any vocational or business training?'}
          id={'attended_vocational'}
          validator={booleanValidator}
          layout={'horizontal'}
          labelStyle={{ display: 'block', fontWeight: 'bold' }}
          onChange={handleVocationalChange}
          disabled={!!initialValues}
        />
      </Grid>
      {showVocational && (
        <>
          <Grid item xs={12} sm={6}>
            <Field
              name={'vocational_school'}
              component={FormInput}
              label={'Name of Vocation or Business School Attended'}
              id={'vocational_school'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'vocational_course_taken'}
              component={FormInput}
              label={'Course Taken'}
              id={'vocational_course_taken'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'vocational_city'}
              component={FormInput}
              label={'City'}
              id={'vocational_city'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'vocational_state'}
              component={FormInput}
              label={'State'}
              id={'vocational_state'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={'completed_vocational'}
              component={FormRadioGroup}
              data={[
                {
                  label: 'No',
                  value: false,
                },
                {
                  label: 'Yes',
                  value: true,
                },
              ]}
              label={'Vocational or Business Education Completed'}
              id={'completed_vocational'}
              validator={booleanValidator}
              disabled={!!initialValues}
              layout={'horizontal'}
              labelStyle={{ display: 'block', fontWeight: 'bold' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'vocational_grad_date'}
              component={FormDatePicker}
              label={'If yes what date did you graduate?'}
              id={'vocational_grad_date'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={'vocational_degree'}
              component={FormInput}
              label={'Type of Degree or Certificate Received'}
              id={'vocational_degree'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
        </>
      )}

      <legend className={classes.formLegendAlternate}>
        Community Involvement
      </legend>
      <Grid item xs={12} sm={6}>
        <Field
          name={'extracurricular_activities'}
          component={FormInput}
          label={'Extracurricular Activities While in School'}
          id={'extracurricular_activities'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'professional_organizations'}
          component={FormInput}
          label={'Member of Professional Organizations'}
          id={'professional_organizations'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name={'honors_received'}
          component={FormInput}
          label={
            'Honors Received, Volunteer or Community Service or other qualifications you have which you feel are related to the position for which you are applying'
          }
          id={'honors_received'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>

      <legend className={classes.formLegendAlternate}>Armed Forces</legend>
      <Grid item xs={12}>
        <Field
          name={'military_service'}
          component={FormRadioGroup}
          data={[
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ]}
          label={'Were you in the U.S. Armed Forces'}
          id={'military_service'}
          validator={booleanValidator}
          layout={'horizontal'}
          labelStyle={{ display: 'block', fontWeight: 'bold' }}
          onChange={handleMilitaryChange}
          disabled={!!initialValues}
        />
      </Grid>
      {showMilitary && <MilitaryServiceFields />}
    </>
  );
};

export default EducationFields;
