import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import ApiAgent from '../../utils/apiAgent';
import TasksComplete from '../CourseTask/TasksComplete/TasksComplete';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    minWidth: '28vw',
  },
}))(MuiDialogContent);

const CourseSummary = ({ userCourse, onClose, open }) => {
  const user = useSelector((state) => state.user);
  const params = useRef({ _token: user.token });
  const [loading, setLoading] = useState(true);
  const [resp, setResp] = useState(null);
  const [error, setError] = useState(null);

  // get course details after summary button clicked
  useEffect(() => {
    const getCourse = async () => {
      setLoading(true);
      const resp = await ApiAgent.getCourse(
        userCourse.course_id,
        userCourse.course_version,
        params.current
      );
      if (resp.error) {
        setError(resp.error);
      } else {
        setResp(resp);
      }
      setLoading(false);
    };
    if (open) getCourse();
  }, [open, userCourse.course_id, userCourse.course_version, setError]);

  if (error || loading) return null;

  return (
    <Dialog onClose={onClose} aria-labelledby="course summary" open={open}>
      <DialogTitle onClose={onClose}>Course Summary</DialogTitle>
      <DialogContent>
        <TasksComplete
          user={user.user}
          userCourse={userCourse}
          passingScore={resp.course.passing_percentage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CourseSummary;
