import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { useHistory } from 'react-router-dom';

// local
import { Routes as AppRoutes } from '../../../routes/appRoutes';

const items = [{ icon: 'search', text: 'Search' }];

function itemClickedToAppUrl(e, id) {
  const itemClicked = e.itemIndex;

  switch (itemClicked) {
    case 0:
      return AppRoutes.documents;
    default:
      return AppRoutes.documents;
  }
}

function Fab({ id }) {
  const history = useHistory();
  return (
    <FloatingActionButton
      icon={'search'}
      items={items}
      onItemClick={e => history.push(itemClickedToAppUrl(e, id))}
      themeColor="tertiary"
      alignOffset={{ x: '30px', y: '30px' }}
    />
  );
}

export default Fab;
