import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import { isBrowser, isMobile } from 'react-device-detect';

// local
import { Routes as ApiRoutes } from '../../../utils/apiAgent';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const DocumentTask = ({ currTask, token, setAllowNext }) => {
  useEffect(() => {
    setAllowNext(true);
  }, [setAllowNext]);

  const [numPages, setNumPages] = useState(null);

  const url = ApiRoutes.getDocument(currTask.document_id);
  const encoded = encodeURIComponent(token);
  const embedUrl = `${url}?_token=${encoded}&category=CourseViewer`;

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <>
      <Hidden smDown>
        <Typography>
          {currTask.title} - {currTask.text}
        </Typography>
      </Hidden>

      {isMobile && (
        <Document file={embedUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )}

      {isBrowser && (
        <object
          data={embedUrl}
          type="application/pdf"
          height="95%"
          width="100%"
        >
          <p>
            Your web browser doesn't have a PDF viewer. Instead you can
            <a href={embedUrl}>click here to download the PDF file.</a>
          </p>
        </object>
      )}
    </>
  );
};

export default DocumentTask;
