import "./AppError.css";

function AppError() {
  return (
    <div id="error">
      <div class="error">
        <div class="error-text">
        <h1>Error</h1>
        </div>
        <h2>Oops! Something went wrong at our end.</h2>
        <p>
          Don't worry, it's not you - it's us. Please try your request again. If
          you continue to receive this error please report it to the
          administrator.
        </p>
        <a href="/">Go To Homepage</a>
      </div>
    </div>
  );
}

export default AppError;
