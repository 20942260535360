/** App routes for main app routing. */

import { useEffect, useRef, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { decode } from 'jsonwebtoken';
import axios from 'axios';

// local imports
import Index from '../views/Index/Index';
import Dashboard from '../views/Dashboard/Dashboard';
import ViewDocument from '../views/ViewDocument/ViewDocument';
import UploadDocument from '../views/UploadDocument/UploadDocument';
import UpdateDocument from '../views/UpdateDocument/UpdateDocument';
import NotFoundPage from '../views/NotFoundPage/NotFoundPage';
import SiteLayout from '../components/SiteLayout/SiteLayout';
import Search from '../views/Search/Search';
import MyCourses from '../views/Courses/MyCourses/MyCourses';
import TakeCourse from '../views/Courses/TakeCourse/TakeCourse';
import AdminCourses from '../views/Courses/AdminCourses/AdminCourses';
import EditCourse from '../views/Courses/EditCourse/EditCourse';
import UserList from '../views/Users/UserList/UserList';
import UserEdit from '../views/Users/UserEdit/UserEdit';
import UserCreate from '../views/Users/UserCreate/UserCreate';
import Reports from '../views/Reports/Reports';
import PastDueReport from '../views/Reports/PastDueReport';
import AssignedCoursesReport from '../views/Reports/AssignedCoursesReport';
import AdminApplications from '../views/Applications/AdminApplications/AdminApplications';
import ViewApplication from '../views/Applications/ViewApplication/ViewApplication';
import EmploymentApplication from '../views/Applications/EmploymentApplication/EmploymentApplication';
import ContinueApplication from '../views/Applications/ContinueApplication/ContinueApplication';
import logout from '../redux/actions/user/logout';
import updateToken from '../redux/actions/user/updateToken';
import ApiAgent from '../utils/apiAgent';
import setNext from '../redux/actions/app/setNext';

function AppRoutes() {
  const token = useSelector(state => state.user.token);
  axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};

  return (
    <SiteLayout>
      <Switch>
        <Route exact path={Routes.index} component={Index} />
        <ProtectedRoute
          exact
          path={Routes.dashboard}
          token={token}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={Routes.myCourses}
          token={token}
          component={MyCourses}
        />
        <ProtectedRoute
          exact
          path={Routes.takeCourse}
          token={token}
          component={TakeCourse}
        />
        <ProtectedRoute
          exact
          path={Routes.adminCourses}
          token={token}
          component={AdminCourses}
        />
        <ProtectedRoute
          exact
          path={Routes.editCourse}
          token={token}
          component={EditCourse}
        />
        <ProtectedRoute
          exact
          path={Routes.documents}
          token={token}
          component={Search}
        />
        <ProtectedRoute
          exact
          path={Routes.viewDoc}
          token={token}
          component={ViewDocument}
        />
        <ProtectedRoute
          exact
          path={Routes.uploadDoc}
          token={token}
          component={UploadDocument}
        />
        <ProtectedRoute
          exact
          path={Routes.updateDoc}
          token={token}
          component={UpdateDocument}
        />
        <ProtectedRoute
          exact
          path={Routes.adminUsers}
          token={token}
          component={UserList}
        />
        <ProtectedRoute
          exact
          path={Routes.createUser}
          token={token}
          component={UserCreate}
        />
        <ProtectedRoute
          exact
          path={Routes.editUser}
          token={token}
          component={UserEdit}
        />
        <ProtectedRoute
          exact
          path={Routes.reports}
          token={token}
          component={Reports}
        />
        <ProtectedRoute
          exact
          path={Routes.reportPastDue}
          token={token}
          component={PastDueReport}
        />
        <ProtectedRoute
          exact
          path={Routes.reportAssignedCourses}
          token={token}
          component={AssignedCoursesReport}
        />
        <ProtectedRoute
          exact
          path={Routes.applications}
          token={token}
          component={AdminApplications}
        />
        <ProtectedRoute
          exact
          path={Routes.viewApplication}
          token={token}
          component={ViewApplication}
        />
        <Route
          exact
          path={Routes.employmentApplication}
          component={EmploymentApplication}
        />
        <Route
          exact
          path={Routes.continueApplication}
          component={ContinueApplication}
        />
        <Route path="/" component={NotFoundPage} />
      </Switch>
    </SiteLayout>
  );
}

export class Routes {
  static get index() {
    return '/';
  }
  static get dashboard() {
    return '/dashboard';
  }
  static get myCourses() {
    return '/my-courses';
  }
  static get takeCourse() {
    return '/take-course/:id/:version';
  }
  // URL factory function
  static makeTakeCourseUrl(id, version) {
    return `/take-course/${id}/${version}`;
  }
  static get adminCourses() {
    return '/admin-courses';
  }
  static get editCourse() {
    return '/edit-course/:id';
  }
  // URL factory function
  static makeEditCourse(id) {
    return `/edit-course/${id}`;
  }
  static get documents() {
    return '/documents';
  }
  static get uploadDoc() {
    return '/upload-document';
  }
  static get updateDoc() {
    return '/update-document/:id';
  }
  // URL factory function
  static makeUpdateUrl(id) {
    return `/update-document/${id}`;
  }
  static get viewDoc() {
    return '/view-document/:id';
  }
  // URL factory function
  static makeViewDocUrl(id) {
    return `/view-document/${id}`;
  }
  static get adminUsers() {
    return '/admin/users';
  }
  static get createUser() {
    return '/admin/users/create';
  }
  static get editUser() {
    return '/admin/users/edit/:id';
  }
  static makeEditUserUrl(id) {
    return `/admin/users/edit/${id}`;
  }
  static get reports() {
    return '/admin/reports';
  }
  static get reportPastDue() {
    return '/admin/reports/past-due';
  }
  static get reportAssignedCourses() {
    return '/admin/reports/assigned-courses';
  }
  static get applications() {
    return '/admin/applications';
  }
  static get viewApplication() {
    return '/admin/applications/:id';
  }
  static makeViewApplication(id) {
    return `/admin/applications/${id}`;
  }
  static get employmentApplication() {
    return '/employment-application';
  }
  static get continueApplication() {
    return '/continue-application';
  }
}

const ProtectedRoute = ({ component: Component, token, ...rest }) => {
  const dispatch = useDispatch();
  const [tokenExpired, setTokenExpired] = useState(false);
  const tokenExpiringSoon = useRef(false);
  const next = rest.location.pathname;

  useEffect(() => {
    if (token) {
      const data = decode(token);
      tokenExpiringSoon.current =
        data.exp * 1000 - Date.now() <= 60 * 60 * 1000;
      if (data.exp * 1000 - Date.now() <= 0) setTokenExpired(true);
    }
  }, [token]);

  useEffect(() => {
    // Create a timer that will run every five seconds to check if the token has expired, this will
    // address the scenario where a user just leaves the browser window open
    const interval = setInterval(() => {
      if (token) {
        const data = decode(token);
        if (data.exp * 1000 - Date.now() <= 0) setTokenExpired(true);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [token, setTokenExpired]);

  return (
    <Route
      {...rest}
      render={props => {
        if (!token) {
          dispatch(logout());
          dispatch(setNext(next));
          return <Redirect to="/" />;
        }

        if (tokenExpired) {
          dispatch(logout());
          dispatch(setNext(next));
          return <Redirect to="/" />;
        }

        if (tokenExpiringSoon.current) {
          ApiAgent.refreshToken(token)
            .then(resp => {
              if (resp.error) {
                throw new Error();
              } else {
                dispatch(updateToken(resp.token));
              }
            })
            .catch(err => {
              console.error('Unable to renew token', err);
              dispatch(logout());
              return <Redirect to="/" />;
            });
        }

        return <Component {...rest} {...props} />;
      }}
    />
  );
};

export default AppRoutes;
