import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

// local
import { basicValidator } from '../../components/form-validators';
import { FormInput, FormDatePicker } from '../../components/form-components';

const EmploymentUnderstanding = ({ initialValues }) => {
  return (
    <>
      <Typography variant="body1" color={'textSecondary'}>
        This institution does not discriminate in hiring or any other decision
        on basis of race, color, sex, citizenship, national origin, ancestry,
        Vietnam era veteran status, or on the basis of age or physical or mental
        disability unrelated to ability to perform the work required. No
        question on this application is intended to secure information to be
        used for such discrimination. Applicants who provide false information
        or omit any requested information on the application materials or in the
        interview process may be deemed to have falsified their application and
        may be ineligible for hire. Northeast Professional Home Care and
        Northeast Professional Caregivers (collectively "Northeast") is a
        drug-free workplace. Individuals offered employment may be required to
        successfully complete a pre-employment physical as a condition of
        employment that may include drug and/or alcohol testing. Refusing to
        take or failing a drug and/or alcohol test will result in removal from
        employment consideration. Northeast is required by federal law to verify
        the identity and work authorization of all new employees. All offers are
        contingent upon verification of identity and eligibility to be employed
        in the United States by completing an I-9 form. I voluntarily give this
        institution the right to make a thorough investigation of my past
        employment and activities, agree to cooperate in such investigation and
        release from all liability or responsibility all persons, companies or
        corporations supplying such information. I consent to take the physical
        examination and/or drug screens, and such future physical examinations
        and drug screens as may be required by this institution at such times
        and places as the institution shall designate. I understand that an
        offer of employment may be contingent on passing the physical
        examination and drug screen which relates to the essential duties I
        would be required to perform. I understand that my employment is at
        will, and that either party is free to terminate the employment
        relationship at any time without cause.
      </Typography>
      <Grid item xs={12} md={6}>
        <Field
          name={'applicant_signature'}
          component={FormInput}
          label={"Applicant's Signature (Please type your full name)"}
          id={'applicant_signature'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name={'applicant_signature_date'}
          component={FormDatePicker}
          label={'Date'}
          id={'applicant_signature_date'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
    </>
  );
};

export default EmploymentUnderstanding;
