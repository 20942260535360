import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: window.workplaceSettings?.appInsightsInstrumentationKey || "42b1b13c-5b37-4b28-8006-e8ae091cbf50",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(
  (envelope) => (envelope.tags["ai.cloud.role"] = "wp-web")
);
export { reactPlugin, appInsights };
