import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { Form, Field, FormElement } from '@progress/kendo-react-form';

import {
  FormInput,
  FormFloatingMultiSelect,
} from '../../../components/form-components';
import { Routes as appRoutes } from '../../../routes/appRoutes';
import {
  userNameValidator,
  emailValidator,
  requiredValidator,
  passwordValidator,
} from '../../../utils/validators';
import ApiAgent from '../../../utils/apiAgent';
import {
  errorMessageObjFactory,
  successMessageObjFactory,
  FormFeedback,
  scrollFeedbackIntoView,
} from '../../../utils/userFeedback';

import userTagData from '../../../testing/MockData/UserTags.json';

const useStyles = makeStyles(
  theme => ({
    section: {
      margin: 25,
    },
    list: {
      paddingTop: 0,
    },
    formButtons: {
      marginTop: theme.spacing(3),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formLegend: {
      borderColor: 'rgba(0, 0, 0, 0.12)',
      margin: '2rem 0 1rem',
      padding: 0,
      width: '100%',
      borderWidth: '0 0 2px',
      borderStyle: 'solid',
      fontSize: 14,
      textTransform: 'uppercase',
    },
  }),
  { classNamePrefix: 'wp' }
);

function UserCreate() {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const [formFeedback, setFormFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const feedbackRef = useRef(null);
  const [password, setPassword] = useState('');
  const passwordHandleChange = event => setPassword(event.target.value);
  const confirmPasswordValidator = value =>
    value === password ? '' : 'Password must match.';

  const handleSubmit = async formData => {
    setLoading(true);
    const newUser = {
      ...formData,
      is_active: true,
      is_ad_auth: false,
      created_date: DateTime.now().toISO(),
      groups: [],
      courses: [],
    };
    delete newUser.confirmPassword;

    const resp = await ApiAgent.createUser(uuidv4(), {
      ...newUser,
      _token: token,
    });
    if (resp.error) {
      setFormFeedback(errorMessageObjFactory(resp.error));
    } else {
      setFormFeedback(successMessageObjFactory('User created successfully!'));
    }

    scrollFeedbackIntoView(feedbackRef);
    setLoading(false);
  };

  return (
    <Card className={classes.section}>
      <CardHeader title="Create User" />
      <CardContent>
        <Form
          onSubmit={handleSubmit}
          render={formRenderProps => (
            <FormElement style={{ maxWidth: 650 }}>
              {formFeedback && (
                <FormFeedback
                  feedback={formFeedback}
                  feedbackRef={feedbackRef}
                />
              )}
              <Field
                name={'username'}
                component={FormInput}
                label={'Username'}
                autoComplete="off"
                validator={userNameValidator}
              />
              <Field
                name={'firstname'}
                component={FormInput}
                label={'First name'}
                autoComplete="off"
                validator={requiredValidator}
              />
              <Field
                name={'lastname'}
                component={FormInput}
                label={'Last name'}
                autoComplete="off"
                validator={requiredValidator}
              />
              <Field
                name={'email'}
                type={'email'}
                component={FormInput}
                label={'Email'}
                autoComplete="off"
                validator={emailValidator}
              />
              <Field
                id={'tags'}
                name={'tags'}
                component={FormFloatingMultiSelect}
                label={'Tags'}
                formOnChange={formRenderProps.onChange}
                data={userTagData.userTags}
                allowCustom={true}
                allowDuplicates={false}
              />
              <Field
                name={'password'}
                type={'password'}
                component={FormInput}
                label={'Password'}
                autoComplete="off"
                validator={passwordValidator}
                onChange={passwordHandleChange}
              />
              <Field
                name={'confirmPassword'}
                type={'password'}
                component={FormInput}
                label={'Confirm Password'}
                autoComplete="off"
                validator={confirmPasswordValidator}
              />

              <div className={classes.formButtons}>
                <Button
                  type={'submit'}
                  variant="contained"
                  color="primary"
                  disabled={!formRenderProps.allowSubmit || loading}
                >
                  Save
                </Button>
                <Link to={appRoutes.adminUsers}>
                  <Button variant="contained">Cancel</Button>
                </Link>
              </div>
            </FormElement>
          )}
        />
      </CardContent>
    </Card>
  );
}

export default UserCreate;
