/** View Document View */

import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@progress/kendo-react-indicators';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// local imports
import { Routes as ApiRoutes } from '../../utils/apiAgent';
import { AxiosGetHook } from '../../hooks/axiosHook';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
} from '../../utils/userFeedback';
import DocumentDetails from '../../components/DocumentDetails/DocumentDetails';
import Fab from './Fab/Fab';

const pdfCheckRegex = /^.pdf$/i;

const useStyles = makeStyles(
  theme => ({
    container: {
      minHeight: '100vh',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '75vw',
      },
    },
    paper: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100vw',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(6),
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    pdfSpacer: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(4),
      },
    },
    card: {
      width: '100%',
    },
    cardContent: {
      height: 'calc(100vh - 100px)',
      [theme.breakpoints.up('lg')]: {
        height: 'calc(100vh - 140px)',
        margin: 10,
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

/**
 * ViewDocument
 * @returns ViewDocument View Component
 */

function ViewDocument() {
  const classes = useStyles();
  const { id } = useParams();
  const token = useSelector(state => state.user.token);
  const params = useRef({ _token: token });
  const url = ApiRoutes.documentMetadata(id);
  const {
    resp: respMetadata,
    error: respMetaError,
    loading,
  } = AxiosGetHook(url, params.current);

  const urlDocument = ApiRoutes.getDocument(id);
  const encoded = encodeURIComponent(token);
  const embedUrl = `${urlDocument}?_token=${encoded}&category=CourseViewer`;

  return (
    <>
      <Container component="main" maxWidth="lg" className={classes.container}>
        <div className={classes.paper}>
          {respMetaError && (
            <FormFeedback
              feedback={networkErrorMessageObjFactory(respMetaError)}
            />
          )}
          {loading && (
            <Box mt={14}>
              <Loader size="large" type={'infinite-spinner'} />
            </Box>
          )}
          {respMetadata && (
            <DocumentDetails fileMetadata={respMetadata} url={embedUrl} />
          )}

          <Box className={classes.pdfSpacer} />
          {respMetadata && pdfCheckRegex.test(respMetadata.file_extension) && (
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <embed
                  src={embedUrl}
                  type="application/pdf"
                  frameBorder="0"
                  scrolling="auto"
                  height="100%"
                  width="100%"
                />
              </CardContent>
            </Card>
          )}
          <Box mb={2} />
        </div>
      </Container>
      <Fab id={id} />
    </>
  );
}

export default ViewDocument;
