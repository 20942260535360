import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Hint } from '@progress/kendo-react-labels';
import { Loader } from '@progress/kendo-react-indicators';
import {
  Grid as DataGrid,
  GridColumn,
  GridToolbar,
} from '@progress/kendo-react-grid';

// local
import EditTaskWindow from './EditTaskWindow/EditTaskWindow';
import ApiAgent, { Routes as apiRoutes } from '../../../utils/apiAgent';
import { getUserEditPermissions } from '../../../utils/auth/userAuth';
import { AxiosGetHook } from '../../../hooks/axiosHook';
import { basicValidator } from '../../../components/form-validators';
import { tags, imageTypes } from '../../../components/form-defaults';
import {
  networkErrorMessageObjFactory,
  errorMessageObjFactory,
  FormFeedback,
  successMessageObjFactory,
  scrollFeedbackIntoView,
} from '../../../utils/userFeedback';
import {
  FormFloatingComboBox,
  FormFloatingInput,
  FormFloatingNumericTextBox,
  FormFloatingMultiSelect,
} from '../../../components/form-components';

import './EditCourse.scss';

// TODO: fix card with on mobile portrait view
const useStyles = makeStyles(
  theme => ({
    section: {
      margin: 0,
      overflowX: 'auto',
      maxWidth: '100vw',
      [theme.breakpoints.up('sm')]: {
        margin: 25,
      },
    },
    formLegend: {
      borderColor: 'rgba(0, 0, 0, 0.12)',
      margin: '0 0 1rem',
      padding: 0,
      width: '100%',
      borderWidth: '0 0 2px',
      borderStyle: 'solid',
      fontSize: 14,
      textTransform: 'uppercase',
    },
    stateChip: {
      marginLeft: theme.spacing(2),
      marginBottom: '3px',
    },
    fieldset: {
      marginBottom: 20,
      border: 'None',
    },
    formButtons: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    loader: {
      marginTop: theme.spacing(26),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(12),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(30),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(36),
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

const MyContext = React.createContext({
  reorder: dataItem => {},
  dragStart: dataItem => {},
});

const DragCell = props => {
  const currentContext = React.useContext(MyContext);
  return (
    <td
      onDragOver={e => {
        currentContext.reorder(props.dataItem);
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      }}
    >
      <span
        className="k-icon k-i-reorder"
        draggable={true}
        style={{
          cursor: 'move',
        }}
        onDragStart={e => {
          currentContext.dragStart(props.dataItem);
          e.dataTransfer.setData('dragging', '');
        }}
      />
    </td>
  );
};

function EditCourse() {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const [activeItem, setActiveItem] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [formFeedback, setFormFeedback] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showEditWindow, setShowEditWindow] = useState(false);
  const feedbackRef = useRef(null);
  const formRenderPropsRef = useRef(null);
  const draftRef = useRef(false);
  const blankFormDefaultsRef = useRef({
    course_id: id,
    tags: [],
    passing_percentage: 70,
  });
  const editPermissions = useMemo(() => getUserEditPermissions(token), [token]);

  // get draft or latest version of course.
  // GET error is acceptable as it happens when creating a new course.
  const url = apiRoutes.courseAdmin(id);
  const params = useRef({ _token: token });
  const { resp, loading, setResp } = AxiosGetHook(url, params.current);

  // make form change to enable submit button
  const enableForm = useCallback(
    () =>
      formRenderPropsRef.current.onChange('title', {
        value: formRenderPropsRef.current.valueGetter('title'),
      }),
    []
  );

  // if resp set gridData to course tasks
  useEffect(() => {
    if (resp) {
      setGridData(resp.course.tasks);
      // allow draft to be published on load without needing form changes
      if (resp.course.state === 'Draft') setTimeout(() => enableForm(), 0);
    }
  }, [setGridData, resp, enableForm]);

  const saveAsDraft = useCallback(() => {
    // set draftRef true so handleSubmit creates draft
    draftRef.current = true;
    formRenderPropsRef.current.onSubmit();
  }, []);

  const handleSubmit = useCallback(
    async formData => {
      setUpdateLoading(true);
      const editData = {
        ...formData,
        _token: token,
        tasks: gridData,
      };
      let submitResp;
      // if draftRef was set true PUT data to endpoint to save draft.
      if (draftRef.current)
        submitResp = await ApiAgent.putCourseAdmin(id, editData);
      // else if course has tasks POST data to endpoint to publish course
      else if (gridData.length > 0)
        submitResp = await ApiAgent.pushCourseAdmin(id, editData);
      // create feedback
      if (submitResp?.error) {
        // error
        setFormFeedback(networkErrorMessageObjFactory(submitResp.error));
      } else if (submitResp) {
        // success
        const message = draftRef.current ? 'Draft updated!' : 'Course updated!';
        setFormFeedback(successMessageObjFactory(message));
        setResp(() => submitResp);
      } else {
        // the course has no tasks
        setFormFeedback(
          errorMessageObjFactory('Please add some tasks to your course.')
        );
      }
      // scroll feedback into view for xs/ sm screens
      draftRef.current = false;
      scrollFeedbackIntoView(feedbackRef);
      setUpdateLoading(false);
    },
    [gridData, id, token, setResp]
  );

  // DataGrid reorder
  const reorder = useCallback(
    dataItem => {
      if (activeItem === dataItem) {
        return;
      }
      let reorderedData = gridData.slice();
      let prevIndex = reorderedData.findIndex(x => x === activeItem);
      let nextIndex = reorderedData.findIndex(x => x === dataItem);
      reorderedData.splice(prevIndex, 1);
      reorderedData.splice(nextIndex, 0, activeItem || reorderedData[0]);
      setGridData(reorderedData);
    },
    [activeItem, gridData]
  );

  // DataGrid dragStart
  const dragStart = useCallback(dataItem => {
    setActiveItem(dataItem);
  }, []);

  // DataGrid CommandCell
  const CommandCell = useCallback(
    props => {
      const editTask = () => {
        setShowEditWindow({
          dataItem: props.dataItem,
          dataIdx: props.dataIndex,
        });
        enableForm();
      };
      const deleteTask = () => {
        setGridData(gridData => {
          const copy = [...gridData];
          copy.splice(props.dataIndex, 1);
          return copy;
        });
        enableForm();
      };
      return (
        <td className="k-command-cell">
          <Button color="primary" onClick={editTask}>
            Edit
          </Button>
          <Button color="secondary" onClick={deleteTask}>
            Delete
          </Button>
        </td>
      );
    },
    [enableForm]
  );

  // open new task window
  const createNewTask = useCallback(() => {
    setShowEditWindow({ dataItem: {}, dataIdx: gridData.length });
    enableForm();
  }, [enableForm, gridData.length]);

  // close new task window
  const closeEditTaskWindow = useCallback(() => setShowEditWindow(false), []);

  const deleteDraft = useCallback(async () => {
    setUpdateLoading(true);
    setFormFeedback(null);
    const delResp = await ApiAgent.deleteCourse(id, {
      delete_draft_only: true,
      _token: token,
    });
    if (delResp.error) {
      setFormFeedback(networkErrorMessageObjFactory(delResp.error));
    } else {
      const courseResp = await ApiAgent.getCourseAdmin(id, params.current);
      if (courseResp.error) {
        window.location.reload();
      } else {
        setResp(courseResp);
        formRenderPropsRef.current.onFormReset();
        setFormFeedback(
          successMessageObjFactory('Course reverted to last published version.')
        );
      }
    }
    setUpdateLoading(false);
  }, [id, setResp, token]);

  // close EditCourse
  const cancelEditCourse = useCallback(() => {
    history.goBack();
  }, [history]);

  if (loading)
    return (
      <Box
        display="flex"
        direction="row"
        justifyContent="center"
        mt={12}
        className={classes.loader}
      >
        <Loader size="large" type={'infinite-spinner'} />
      </Box>
    );

  return (
    <Card className={classes.section}>
      <CardHeader title={resp?.course ? 'Edit Course' : 'Add Course'} />
      <CardContent>
        <Form
          onSubmit={handleSubmit}
          initialValues={resp?.course || blankFormDefaultsRef.current}
          render={formRenderProps => {
            formRenderPropsRef.current = formRenderProps;

            return (
              <FormElement>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.formLegend}>
                    Course Details:
                    {resp?.course.state === 'Latest' && (
                      <Chip
                        label="Published"
                        color="primary"
                        size="small"
                        variant="outlined"
                        className={classes.stateChip}
                      />
                    )}
                    {resp?.course.state === 'Draft' && (
                      <Chip
                        label="Draft"
                        color="secondary"
                        size="small"
                        variant="outlined"
                        className={classes.stateChip}
                      />
                    )}
                  </legend>
                  {formFeedback && (
                    <FormFeedback
                      feedback={formFeedback}
                      feedbackRef={feedbackRef}
                    />
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        name={'title'}
                        component={FormFloatingInput}
                        label={'Title'}
                        id={'title'}
                        validator={basicValidator}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        name={'category'}
                        id={'category'}
                        component={FormFloatingComboBox}
                        label={'Category'}
                        data={editPermissions}
                        validator={basicValidator}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        id={'tags'}
                        name={'tags'}
                        component={FormFloatingMultiSelect}
                        label={'Tags'}
                        formOnChange={formRenderProps.onChange}
                        data={tags}
                        allowCustom={true}
                        allowDuplicates={false}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        name={'image'}
                        component={FormFloatingComboBox}
                        label={'Image'}
                        data={imageTypes}
                        validator={basicValidator}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        name={'due_days'}
                        component={FormFloatingNumericTextBox}
                        label={'Due In'}
                        validator={basicValidator}
                        min={1}
                      />
                      <Hint>Days</Hint>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Field
                        name={'passing_percentage'}
                        component={FormFloatingNumericTextBox}
                        label={'Passing Percentage'}
                        validator={basicValidator}
                        min={10}
                      />
                      <Hint>For courses with questions.</Hint>
                    </Grid>
                  </Grid>
                </fieldset>

                <fieldset className={classes.fieldset}>
                  <legend className={classes.formLegend}>Course Tasks:</legend>
                  <MyContext.Provider
                    value={{
                      reorder: reorder,
                      dragStart: (...rest) => {
                        enableForm();
                        dragStart(...rest);
                      },
                    }}
                  >
                    <DataGrid
                      style={{ maxHeight: '90vh' }}
                      data={gridData}
                      dataItemKey={'id'}
                      reorderable={true}
                      resizable={true}
                      className="task-grid"
                    >
                      <GridToolbar>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={createNewTask}
                        >
                          New Task
                        </Button>
                      </GridToolbar>

                      <GridColumn title="" width="40px" cell={DragCell} />
                      <GridColumn
                        field="type"
                        title="Type"
                        key="id"
                        width="120px"
                      />
                      <GridColumn field="text" title="Text" key="id" />
                      <GridColumn cell={CommandCell} width="300px" />
                    </DataGrid>
                  </MyContext.Provider>
                </fieldset>
                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    type={'submit'}
                    color="primary"
                    disabled={!formRenderProps.allowSubmit || updateLoading}
                  >
                    Save and Publish
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!formRenderProps.allowSubmit || updateLoading}
                    onClick={saveAsDraft}
                  >
                    Save as Draft
                  </Button>
                  {resp?.course.state === 'Draft' && (
                    <Tooltip
                      title="Revert to last published version of course"
                      placement="top"
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={deleteDraft}
                      >
                        Delete Draft
                      </Button>
                    </Tooltip>
                  )}
                  <Button variant="contained" onClick={cancelEditCourse}>
                    Cancel
                  </Button>
                </div>
              </FormElement>
            );
          }}
        />

        {showEditWindow && (
          <EditTaskWindow
            dataItem={showEditWindow.dataItem}
            dataItemIdx={showEditWindow.dataIdx}
            setGridData={setGridData}
            onCloseClick={closeEditTaskWindow}
          ></EditTaskWindow>
        )}
      </CardContent>
    </Card>
  );
}

export default EditCourse;
