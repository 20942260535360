import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormElement } from '@progress/kendo-react-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

// local
import ApiAgent from '../../../utils/apiAgent';
import { Routes as AppRoutes } from '../../../routes/appRoutes';
import {
  networkErrorMessageObjFactory,
  errorMessageObjFactory,
  FormFeedback,
  scrollFeedbackIntoView,
} from '../../../utils/userFeedback';
import AvailabilityFields from '../../../components/ApplicationFieldGroups/AvailabilityFields';
import MainApplicationFields from '../../../components/ApplicationFieldGroups/MainApplicationFields';
import ReferencesFields from '../../../components/ApplicationFieldGroups/ReferencesFields';
import EmploymentUnderstanding from '../../../components/ApplicationFieldGroups/EmploymentUnderstanding';
import AppImage from '../../../assets/application-image.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(
  theme => ({
    headerImageDiv: {
      backgroundImage: `url(${AppImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      position: 'relative',
      paddingTop: 'min(25.4vh, 25.4vw)',
      paddingBottom: 'min(25.4vh, 25.4vw)',
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(8),
      },
    },
    headerImgOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.55)',
    },
    headerText: {
      fontSize: 'max(6vh, 6vw)',
      textAlign: 'center',
      color: 'white',
      fontWeight: '900',
      [theme.breakpoints.up('md')]: {
        fontSize: 'min(7vh, 7vw)',
      },
      position: 'relative',
    },
    headerTextAlt: {
      fontSize: 'max(3vh, 3vw)',
      textAlign: 'center',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        fontSize: 'min(4vh, 4vw)',
      },
    },
    section: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    formLegend: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      fontWeight: '900',
      fontSize: 26,
      color: '#0266b0',
      width: '100%',
    },
    formLegendAlternate: {
      marginTop: theme.spacing(2),
      fontWeight: '700',
      width: '100%',
      fontSize: 18,
      color: '#0266b0',
    },
    fieldset: {
      marginBottom: theme.spacing(4),
      border: 'None',
    },
    formButtons: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    submitButton: {
      width: '100%',
      backgroundColor: '#0266b0',
      color: 'white',
    },
    textCenter: {
      textAlign: 'center',
    },
    link: {
      color: 'blue',
      fontSize: 'inherit',
    },
  }),
  { classNamePrefix: 'wp' }
);

function convertDates(initialValues) {
  initialValues.date_of_application = new Date(
    initialValues.date_of_application
  );
  initialValues.date_available = new Date(initialValues.date_available);
  initialValues.applicant_signature_date = new Date(
    initialValues.applicant_signature_date
  );
}

function EmploymentApplication({ initialValues }) {
  const classes = useStyles();
  const [formFeedback, setFormFeedback] = useState(null);
  const feedbackRef = useRef(null);
  const [submitting, setSubmitting] = useState(null);
  const [showSubmittedDialog, setShowSubmittedDialog] = useState(false);
  const formRenderPropsRef = useRef(null);
  const runOnceRef = useRef(!!initialValues);

  if (runOnceRef.current) {
    convertDates(initialValues);
    runOnceRef.current = false;
  }

  useEffect(() => {
    document.body.style.setProperty('--bodyBgColor', '#fff');
  }, []);
  const handleClose = () => {
    window.location.href = 'https://neprohomecare.com';
  };

  const handleSubmit = useCallback(async formData => {
    setSubmitting(true);
    setFormFeedback(null);

    formData.continueApplication = true;

    const resp = await ApiAgent.submitApplication(
      { application: formData },
      null
    );
    if (resp.error) {
      setFormFeedback(networkErrorMessageObjFactory(resp.error));
      scrollFeedbackIntoView(feedbackRef);
    } else {
      setShowSubmittedDialog(true);
    }
    setSubmitting(false);
  }, []);

  return (
    <>
      {!initialValues && (
        <Box className={classes.headerImageDiv} id="application-header">
          <Box className={classes.headerImgOverlay} />
          <Typography
            component="h1"
            className={classes.headerText}
            id="application-header-text"
          >
            CONTINUE EMPLOYMENT APPLICATION
          </Typography>
        </Box>
      )}

      {initialValues && (
        <Typography className={classes.headerTextAlt}>
          CONTINUE EMPLOYMENT APPLICATION
        </Typography>
      )}
      <Container component="section" maxWidth="lg" className={classes.section}>
        <Typography
          component="h2"
          variant="h5"
          gutterBottom
          id="explanation-text"
        >
          <strong>
            This form is for those who have already applied through Indeed.com.
            If you have not submitted an application through Indeed, please
            complete our{' '}
            <Link to={AppRoutes.employmentApplication} className={classes.link}>
              full application.
            </Link>
          </strong>
        </Typography>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={formRenderProps => {
            formRenderPropsRef.current = formRenderProps;
            return (
              <FormElement>
                <fieldset className={classes.fieldset}>
                  {formFeedback && (
                    <FormFeedback
                      feedback={formFeedback}
                      feedbackRef={feedbackRef}
                    />
                  )}
                  <Grid container spacing={3}>
                    <MainApplicationFields
                      continueApplication={true}
                      initialValues={initialValues}
                    />

                    <legend className={classes.formLegend}>Availability</legend>
                    <AvailabilityFields initialValues={initialValues} />

                    <legend className={classes.formLegend}>References</legend>
                    <ReferencesFields
                      classes={classes}
                      initialValues={initialValues}
                    />

                    <legend className={classes.formLegendAlternate}>
                      Employment Understanding (Please Read and Sign)
                    </legend>
                    <EmploymentUnderstanding initialValues={initialValues} />
                  </Grid>
                </fieldset>

                {formRenderProps.touched &&
                  formRenderProps.visited &&
                  !formRenderProps.valid && (
                    <Box mb={3} ml={2} className={classes.textCenter}>
                      <FormFeedback
                        feedback={errorMessageObjFactory(
                          'Please correct all form errors.'
                        )}
                      />
                    </Box>
                  )}

                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    type={'submit'}
                    disabled={
                      !formRenderProps.allowSubmit ||
                      submitting ||
                      initialValues
                    }
                    className={classes.submitButton}
                  >
                    Send
                  </Button>
                </div>
              </FormElement>
            );
          }}
        />
      </Container>
      <Dialog
        open={showSubmittedDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'sm'}
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">{'Success!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Application successfully submitted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <Button onClick={handleClose} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmploymentApplication;
