import { useRef, useState, useMemo, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Loader } from '@progress/kendo-react-indicators';
import { v4 as uuid4 } from 'uuid';
import { DateTime } from 'luxon';

//local
import { Routes as AppRoutes } from '../../../routes/appRoutes';
import ApiAgent, { Routes as ApiRoutes } from '../../../utils/apiAgent';
import { AxiosGetHook } from '../../../hooks/axiosHook';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
} from '../../../utils/userFeedback';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import AssignCourseWindow from '../../../components/AssignCourseWindow/AssignCourseWindow';

const useStyles = makeStyles(
  theme => ({
    section: {
      margin: 25,
    },
    loader: {
      marginTop: theme.spacing(26),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(12),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(30),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(36),
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

function AdminCourses() {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const params = useRef({ _token: token });
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [assignWindowOpen, setAssignWindowOpen] = useState(false);
  const [deleteFormFeedback, setDeleteFormFeedback] = useState(null);
  const [courseDetails, setCourseDetails] = useState(null);
  // get course
  const url = ApiRoutes.getCourses;
  const { resp, error, loading, setResp } = AxiosGetHook(url, params.current);

  const courses = useMemo(
    () =>
      resp?.courses.map(el => {
        return {
          ...el,
          timestamp: DateTime.fromISO(el.timestamp).toLocaleString({
            ...DateTime.DATETIME_MED,
          }),
        };
      }),
    [resp]
  );

  const handleDelete = useCallback(async () => {
    const deleteResp = await ApiAgent.deleteCourse(courseDetails.course_id, {
      _token: token,
      category: courseDetails.category,
    });
    if (deleteResp.error) {
      setDeleteFormFeedback(networkErrorMessageObjFactory(deleteResp.error));
    } else {
      // update courses resp to reflect deleted entry
      setResp(resp => ({
        ...resp,
        courses: resp.courses.filter(
          el => el.course_id !== courseDetails.course_id
        ),
      }));
    }
    setDelModalOpen(false);
  }, [courseDetails, setResp, token]);

  const CommandCell = useCallback(
    props => (
      <td className="k-command-cell">
        <Link
          to={AppRoutes.makeEditCourse(
            props.dataItem.course_id,
            props.dataItem.category
          )}
        >
          <Button color="primary">Edit</Button>
        </Link>

        <Button
          onClick={() => {
            setCourseDetails({
              course_id: props.dataItem.course_id,
              category: props.dataItem.category,
              title: props.dataItem.title,
            });
            setAssignWindowOpen(true);
          }}
        >
          Assign
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setCourseDetails({
              course_id: props.dataItem.course_id,
              category: props.dataItem.category,
              title: props.dataItem.title,
            });
            setDelModalOpen(true);
          }}
        >
          Delete
        </Button>
      </td>
    ),
    []
  );

  if (error)
    return <FormFeedback feedback={networkErrorMessageObjFactory(error)} />;

  if (loading)
    return (
      <Box
        display="flex"
        direction="row"
        justifyContent="center"
        className={classes.loader}
      >
        <Loader size="large" type={'infinite-spinner'} />
      </Box>
    );

  return (
    <>
      <Card className={classes.section}>
        <CardHeader title="Courses" />
        <CardContent>
          <Grid
            style={{ maxHeight: '90vh' }}
            data={courses}
            reorderable={true}
            resizable={true}
          >
            <GridToolbar>
              <Link to={AppRoutes.makeEditCourse(uuid4())}>
                <Button variant="contained" color="primary">
                  New Course
                </Button>
              </Link>
            </GridToolbar>

            <GridColumn field="title" title="Title" key="id" />
            <GridColumn field="category" title="Category" key="id" />
            <GridColumn field="version" title="Version" key="id" />
            <GridColumn field="timestamp" title="Modified On" key="id" />
            <GridColumn cell={CommandCell} width="300px" />
          </Grid>
        </CardContent>
      </Card>

      <DeleteModal
        delModalOpen={delModalOpen}
        setDelModalOpen={setDelModalOpen}
        name={'course'}
        itemDetail={courseDetails?.title}
        handleDelete={handleDelete}
        formFeedback={deleteFormFeedback}
      />

      {assignWindowOpen && (
        <AssignCourseWindow
          setAssignWindowOpen={setAssignWindowOpen}
          courseDetails={courseDetails}
        />
      )}
    </>
  );
}

export default AdminCourses;
