import { useState, useCallback } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import {
  Grid,
  GridColumn,
  GridNoRecords,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";

import SearchUserForm from "../../SearchUserForm/SearchUserForm";

const idGetter = getter("id");

const useStyles = makeStyles(
  () => ({
    section: {
      border: "none",
      boxShadow: "none",
    },
    dataGrid: {
      height: "calc(100vh - 300px)",
      maxHeight: 340,
      minHeight: 200,
    },
  }),
  { classNamePrefix: "wp" }
);

function UserList({ usersSelectedCallback = () => {} }) {
  const classes = useStyles();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(null);
  const [selectedState, setSelectedState] = useState({});
  const [initialSearchCompleted, setInitialSearchCompleted] = useState(false);

  const onSearchResults = useCallback(
    (results) => {
      const users = results.filter(x => x.is_active);
      setSearchResults(users);
      setInitialSearchCompleted(true);
    },
    [setSearchResults, setInitialSearchCompleted]
  );

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: "id",
      });
      setSelectedState(newSelectedState);
      usersSelectedCallback(
        searchResults.filter((x) => newSelectedState[x.id])
      );
    },
    [selectedState, searchResults, usersSelectedCallback]
  );

  const onHeaderSelectionChange = useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {};
      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
      usersSelectedCallback(
        searchResults.filter((x) => newSelectedState[x.id])
      );
    },
    [searchResults, usersSelectedCallback]
  );

  return (
    <Card className={classes.section}>
      <CardContent>
        <Box mb={2}>
          <SearchUserForm
            setLoading={setLoading}
            setSearchResults={onSearchResults}
          ></SearchUserForm>
        </Box>
        {loading && (
          <Box textAlign="center" m={3}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Grid
            className={classes.dataGrid}
            reorderable={true}
            resizable={true}
            data={searchResults.map((item) => ({
              ...item,
              selected: selectedState[idGetter(item)],
            }))}
            dataItemKey="id"
            selectedField="selected"
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: "multiple",
            }}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
          >
            {!initialSearchCompleted && (
              <GridNoRecords>Perform a search to show results</GridNoRecords>
            )}
            <GridColumn
              field="selected"
              width="65px"
              headerSelectionValue={
                searchResults.findIndex(
                  (item) => !selectedState[idGetter(item)]
                ) === -1
              }
            />
            <GridColumn field="username" title="Username" />
            <GridColumn field="firstname" title="First name" />
            <GridColumn field="lastname" title="Last name" />
            <GridColumn field="tags" title="Tags" />
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default UserList;
