import { useState, useCallback, useRef } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Link } from 'react-router-dom';

import { Routes as appRoutes } from '../../../routes/appRoutes';
import { BooleanCell } from '../../../utils/gridCells';
import SearchUserForm from '../../../components/SearchUserForm/SearchUserForm';

const useStyles = makeStyles(
  () => ({
    section: {
      margin: 25,
    },
    dataGrid: {
      height: 'calc(100vh - 330px)',
      minHeight: 200,
    },
  }),
  { classNamePrefix: 'wp' }
);

const CommandCell = (props) => (
  <td className="k-command-cell">
    <Link to={appRoutes.makeEditUserUrl(props.dataItem['id'])}>
      <Button color="primary">Edit</Button>
    </Link>
  </td>
);

function UserList() {
  const classes = useStyles();
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(null);
  const [initialSearchCompleted, setInitialSearchCompleted] = useState(false);

  const onSearchResults = useCallback(
    (results) => {
      setSearchResults(results);
      setInitialSearchCompleted(true);
    },
    [setSearchResults, setInitialSearchCompleted]
  );

  const excelExport = useRef(null);
  const exportExport = () => {
    if (excelExport.current !== null)
      excelExport.current.save(searchResults);
  };

  return (
    <Card className={classes.section}>
      <CardHeader title="Users" />
      <CardContent>
        <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
          <Box pr={2}>
            <SearchUserForm
              setLoading={setLoading}
              setSearchResults={onSearchResults}
            ></SearchUserForm>
          </Box>
          <Link to={appRoutes.createUser}>
            <Button variant="contained" color="primary">
              New User
            </Button>
          </Link>
        </Box>
        {loading && (
          <Box textAlign="center" m={3}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <ExcelExport ref={excelExport}>
            <Button color="primary" onClick={exportExport}>
              Export to Excel
            </Button>
            <Grid
              className={classes.dataGrid}
              reorderable={true}
              resizable={true}
              data={searchResults}
            >
              {!initialSearchCompleted && (
                <GridNoRecords>Perform a search to show results</GridNoRecords>
              )}
              <GridColumn field="username" title="Username" width="140" />
              <GridColumn field="firstname" title="First name" />
              <GridColumn field="lastname" title="Last name" />
              <GridColumn field="email" title="Email" />
              <GridColumn field="tags" title="Tags" />
              <GridColumn
                cell={BooleanCell}
                field="is_active"
                title="Active"
                width="90"
              />
              <GridColumn
                field="timestamp"
                title="Modified On"
                format="{0:d}"
                width="120"
              />
              <GridColumn cell={CommandCell} width="120" />
            </Grid>
          </ExcelExport>
        )}
      </CardContent>
    </Card>
  );
}

export default UserList;
