import { useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Routes as ApiRoutes } from '../../utils/apiAgent';
import { AxiosGetHook } from '../../hooks/axiosHook';

import { DateDisplayCell, CourseTitleVersionCell } from '../../utils/gridCells';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
} from '../../utils/userFeedback';

const useStyles = makeStyles(
  () => ({
    section: {
      margin: 25,
    },
  }),
  { classNamePrefix: 'wp' }
);

function PastDueReport() {
  const classes = useStyles();
  const token = useSelector((state) => state.user.token);
  const params = useRef({ _token: token });
  const [formFeedback, setFormFeedback] = useState(null);
  const [sort, setSort] = useState([{ field: 'due_date', dir: 'asc' }]);

  const excelExport = useRef(null);
  const exportExport = () => {
    if (excelExport.current !== null)
      excelExport.current.save(orderBy(searchResults, sort));
  };

  const { resp, error, loading } = AxiosGetHook(
    ApiRoutes.getPastDueCourses,
    params.current
  );
  if (error) setFormFeedback(networkErrorMessageObjFactory(error));
  const searchResults = useMemo(() => resp, [resp]);

  return (
    <Card className={classes.section}>
      <CardHeader title="Past Due Report" />
      <CardContent>
        {formFeedback && <FormFeedback feedback={formFeedback} />}
        {loading && (
          <Box textAlign="center" m={3}>
            <CircularProgress />
          </Box>
        )}
        {searchResults && !loading && (
          <ExcelExport ref={excelExport}>
            <Button color="primary" onClick={exportExport}>
              Export to Excel
            </Button>
            <Grid
              reorderable={true}
              resizable={true}
              data={orderBy(searchResults, sort)}
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
            >
              <GridColumn
                field="title"
                title="Title (Version)"
                cell={CourseTitleVersionCell}
                minResizableWidth={50}
              />
              <GridColumn
                field="username"
                title="User"
                minResizableWidth={50}
              />
              <GridColumn
                field="assigned_date"
                title="Assigned"
                cell={DateDisplayCell}
                width="120"
              />
              <GridColumn
                field="due_date"
                title="Due"
                cell={DateDisplayCell}
                width="120"
              />
            </Grid>
          </ExcelExport>
        )}
      </CardContent>
    </Card>
  );
}

export default PastDueReport;
