import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';

// local
import {
  basicValidator,
  zipCodeValidator,
  phoneValidator,
} from '../../components/form-validators';
import { FormInput, FormMaskedTextBox } from '../../components/form-components';

const PresentAddressFields = ({ initialValues }) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field
          name={'street_address'}
          component={FormInput}
          label={'Street'}
          id={'street_address'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'city'}
          component={FormInput}
          label={'City'}
          id={'city'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Field
          name={'state'}
          component={FormInput}
          label={'State'}
          id={'state'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Field
          id={'zip_code'}
          name={'zip_code'}
          label={'Zip Code'}
          component={FormMaskedTextBox}
          mask="00000"
          validator={zipCodeValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Field
          id={'home_phone'}
          name={'home_phone'}
          label={'Home Phone'}
          component={FormMaskedTextBox}
          mask="(000) 000-0000"
          validator={phoneValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Field
          id={'cell_phone'}
          name={'cell_phone'}
          label={'Cell Phone'}
          component={FormMaskedTextBox}
          mask="(000) 000-0000"
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
    </>
  );
};

export default PresentAddressFields;
