import { useState } from 'react';
import { Upload } from '@progress/kendo-react-upload';

// local imports
import ApiAgent, { Routes as ApiRoutes } from '../../utils/apiAgent';
import {
  networkErrorMessageObjFactory,
  errorMessageObjFactory,
  documentSuccessMessageObjFactory,
} from '../../utils/userFeedback';
import { allowedUploadExtensions } from '../../utils/documentTypes';

// File  Input
export const FileInput = ({
  setFormFeedback,
  id,
  token,
  formRenderProps,
  lastUpdate,
  setLastUpdate,
}) => {
  const [files, setFiles] = useState([]);
  const [saveUrl, setSaveUrl] = useState(null);

  function onAdd(event) {
    // get file and file data variables
    const file = event.affectedFiles[0];
    const fileSizeKb = Math.round(file.size / 1024);

    // set form data from file data
    formRenderProps.onChange('file_size_KB', { value: fileSizeKb });
    formRenderProps.onChange('file_extension', { value: file.extension });

    // create / set file upload URL
    // category is used for upload authorization
    const category = formRenderProps.valueGetter('category') || 'General';
    const uploadUrl = ApiRoutes.uploadDoc(id, token, category);
    setSaveUrl(uploadUrl);

    const feedback =
      file.size === lastUpdate.file_size &&
      file.extension === lastUpdate.file_extension
        ? errorMessageObjFactory(
            <>
              <div>
                You may be uploading the same file that was last uploaded.
              </div>
              <div>The new file has the same size and extension.</div>
            </>
          )
        : null;
    setFormFeedback(feedback);
    setFiles(event.newState);
  }

  function onRemove(event) {
    setFormFeedback(null);
    formRenderProps.onChange('file_size_KB', { value: 0 });
    formRenderProps.onChange('file_extension', { value: '' });
    setFiles(event.newState);
  }

  function onProgress(event) {
    setFiles(event.newState);
  }

  function onStatusChange(event) {
    const uploadFailureStatus = 0;
    const uploadSuccessfulStatus = 4;
    const status = event.newState[0].status;

    if (status === uploadFailureStatus) {
      setFormFeedback(networkErrorMessageObjFactory(event.response));
    }
    // if file uploaded successfully submit file size metadata update
    else if (status === uploadSuccessfulStatus) {
      const file = event.affectedFiles[0];
      // update document metadata
      ApiAgent.updateDocMetadata({
        _token: token,
        id,
        file_extension: file.extension,
        file_size: file.size,
      }).then(resolve => {
        if (resolve.error)
          setFormFeedback({
            message: 'Network error. File size update failed.',
            type: 'error',
          });
        else {
          setFormFeedback(
            documentSuccessMessageObjFactory('Document Updated!', 'update', id)
          );
          setLastUpdate(resolve);
        }
      });
    }

    setFiles(event.newState);
  }

  return (
    <Upload
      batch={false}
      restrictions={{
        allowedExtensions: allowedUploadExtensions,
        // max size 25 Megabytes
        maxFileSize: 26214400,
      }}
      multiple={false}
      files={files}
      onAdd={onAdd}
      onRemove={onRemove}
      onProgress={onProgress}
      onStatusChange={onStatusChange}
      withCredentials={false}
      autoUpload={false}
      saveUrl={saveUrl}
      // removeUrl necessary for delete button to show after file upload complete
      // which is used to reset form and upload another file if necessary.
      removeUrl={ApiRoutes.return200}
    />
  );
};
