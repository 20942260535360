/** User feedback messaging utilities. */

import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// local
import { Routes as appRoutes } from '../routes/appRoutes';
import { Typography } from '@material-ui/core';

/** createErrorFeedbackMessage()
 *
 * Create and error feedback message for network (HTTP) errors.
 *
 * @param {object literal} error response error
 * @returns jsx || string
 */

export function createErrorFeedbackMessage(error, login = false, ssoLogin = false) {
  switch (error.response?.status) {
    case 401:
      return 'Authorization Required';
    case 403:
      if (ssoLogin)
        return (
          <>
            <div>There was an error authenticating.</div>
            <div>{error.response.data.error.message}</div>
          </>
        );
      if (login)
        return (
          <>
            <div>There was an error authenticating.</div>
            <div>Please check you username and password.</div>
          </>
        );
      return 'You are not authorized for this action';
    case 423:
      if (login)
        return (
          <>
            <div>You account has been locked for invalid attempts</div>
            <div>Please try again later or contact the system admin.</div>
          </>
        );
      return 'Network Error';
    case 500:
      return 'Network Error';
    default:
      return 'Network Error';
  }
}

/** FormFeedback component that consumes message object.
 *
 * feedbackRef used for scrolling FormFeedback into view.
 */
export const FormFeedback = ({
  feedback,
  feedbackRef,
  variant = 'body1',
  ...rest
}) => (
  <Typography
    {...rest}
    variant={variant}
    className={`FormFeedback k-messagebox k-messagebox-${feedback.type} fade-in`}
    role="alert"
    ref={feedbackRef}
  >
    {feedback.message}
  </Typography>
);

/** Factory function for error feedback object for forms. */
export const networkErrorMessageObjFactory = (error, login, ssoLogin) => ({
  message: createErrorFeedbackMessage(error, login, ssoLogin),
  type: 'error',
});

/** Factory function for error feedback object for forms. */
export const successMessageObjFactory = message => ({
  message,
  type: 'success',
});

/** Factory function for error feedback object for forms. */
export const errorMessageObjFactory = message => ({
  message,
  type: 'error',
});

/** Factory function for success feedback object for document forms. */
export const documentSuccessMessageObjFactory = (msg, page, id) => ({
  message: (
    <>
      <div>{msg}</div>
      <nav>
        <Box display="flex">
          <Box component="span" mr={4}>
            <Link to={appRoutes.documents} className="link-noStyle">
              <Tooltip anchorElement="pointer" position="right">
                <span
                  className="k-icon k-i-home"
                  title="Go to Documents Home"
                ></span>
              </Tooltip>
            </Link>
          </Box>
          {id && (
            <Box component="span" mr={4}>
              <Link to={appRoutes.makeViewDocUrl(id)} className="link-noStyle">
                <Tooltip anchorElement="pointer" position="right">
                  <span
                    className="k-icon k-i-file"
                    title="Document Details"
                  ></span>
                </Tooltip>
              </Link>
            </Box>
          )}

          {/* Upload page shows link to update another document */}
          {page === 'upload' && <UploadLink />}
          {/* End upload page link */}
        </Box>
      </nav>
    </>
  ),
  type: 'success',
});

/** Factory function for "document name already exists" feedback object for upload document form. */
export const nameExistsMessageObjFactory = (name, setSearchQuery) => ({
  message: (
    <>
      <div>{`Document with name "${name}" already exists!`}</div>
      <Box
        component="span"
        mr={4}
        role="button"
        tabIndex={0}
        onKeyPress={setSearchQuery}
        onClick={setSearchQuery}
      >
        <Link to={appRoutes.documents}>See Document</Link>
      </Box>
    </>
  ),
  type: 'error',
});

/** Link style button to reset form. For the document upload form upload success alert.
 * Clicking this button clicks ".k-delete" button of file upload component.
 */
const UploadLink = () => (
  <Box component="span" mr={4}>
    {document.querySelector('.k-delete') && (
      <span
        to="/upload-document"
        // reset form by clicking close button
        onClick={() => document.querySelector('.k-delete').click()}
        onKeyPress={() => document.querySelector('.k-delete').click()}
        role="button"
        tabIndex={0}
        name={'reset form'}
      >
        <Tooltip anchorElement="pointer" position="right">
          <span className="k-icon k-i-reset" title="Reset Form"></span>
        </Tooltip>
      </span>
    )}
  </Box>
);

/** Make each word in a string have first letter upper case. */
export const toTitleCase = str => {
  return str
    ? str
      .trim()
      .split(' ')
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join(' ')
    : str;
};

/**
 * Scroll form feedback display into view on small screens
 * after small delay to ensure feedback has rendered.
 *
 * @param {React ref} feedbackRef form feedback ref
 */

export const scrollFeedbackIntoView = feedbackRef => {
  setTimeout(() => {
    if (feedbackRef.current)
      feedbackRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }, 100);
};
