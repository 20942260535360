import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from '@progress/kendo-react-indicators';
import { Button } from '@progress/kendo-react-buttons';
import { saveAs } from '@progress/kendo-file-saver';
import { PDFExport } from '@progress/kendo-react-pdf';
import { Ripple } from '@progress/kendo-react-ripple';
import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
} from '@material-ui/core';

import EmploymentApplication from '../EmploymentApplication/EmploymentApplication';
import ContinueApplication from '../ContinueApplication/ContinueApplication';
import { AxiosGetHook } from '../../../hooks/axiosHook';
import ApiAgent, { Routes as ApiRoutes } from '../../../utils/apiAgent';
import {
  errorMessageObjFactory,
  FormFeedback,
  networkErrorMessageObjFactory,
} from '../../../utils/userFeedback';
import './ViewApplication.css';

const useStyles = makeStyles(
  theme => ({
    main: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    loader: {
      marginTop: theme.spacing(26),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(12),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(30),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(36),
      },
    },
    resumeSpacer: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(4),
      },
    },
    resumeCardContent: {
      height: 'calc(100vh - 100px)',
      [theme.breakpoints.up('lg')]: {
        height: 'calc(100vh - 140px)',
        margin: 10,
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

const pdfCheckRegex = /^.pdf$/i;

const ViewApplication = () => {
  const classes = useStyles();
  const { id } = useParams();
  const token = useSelector(state => state.user.token);
  const params = useRef({ _token: token });
  const [formFeedback, setFormFeedback] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const pdfExportComponent = useRef(null);

  // get application hook
  const url = ApiRoutes.applicationId(id);
  const { resp, error, loading } = AxiosGetHook(url, params.current);

  // get resume method
  const getResume = useCallback(async id => {
    const resumeResp = await ApiAgent.getResume(id, params.current);
    if (resumeResp.error) {
      setFormFeedback(errorMessageObjFactory('Resume Network Error.'));
    } else {
      setResumeData(resumeResp);
    }
  }, []);

  // get resume if resume is PDF hook
  useEffect(() => {
    if (
      resp?.application?.resume?.length > 0 &&
      pdfCheckRegex.test(resp?.application.resume[0].extension)
    ) {
      getResume(resp.application.resume[0].uid);
    }
  }, [resp, getResume]);

  // save resume to file method
  const handleSaveFile = useCallback(
    async e => {
      e.preventDefault();
      const resume = resp.application.resume[0];
      if (!resumeData) {
        var resumeResp = await getResume(resume.uid);
      }
      const dataUrl = resumeData || resumeResp;
      saveAs(dataUrl, `${resume.name}`);
    },
    [resp, getResume, resumeData]
  );

  const handleExportWithComponent = () => {
    pdfExportComponent.current.save();
  };

  if (error)
    return <FormFeedback feedback={networkErrorMessageObjFactory(error)} />;
  if (loading)
    return (
      <Box
        className={classes.loader}
        display={'flex'}
        justifyContent="center"
        alignItems="center"
      >
        <Loader size="large" type={'infinite-spinner'} />
      </Box>
    );

  const application = resp.application;
  const resume = resp.application.resume && resp.application.resume[0];
  const FormComponent = application.continueApplication
    ? ContinueApplication
    : EmploymentApplication;

  return (
    <Container
      component="main"
      maxWidth="xl"
      className={classes.main}
      id="ViewApplication"
    >
      <PDFExport ref={pdfExportComponent} paperSize="A4" keepTogether="div, p" scale={0.6}>
        {formFeedback && <FormFeedback feedback={formFeedback} />}
        <Card>
          <FormComponent initialValues={application} />
        </Card>

        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <Button
            primary={true}
            onClick={handleExportWithComponent}
            className={'no-print'}
          >
            PRINT TO PDF
          </Button>
        </Box>

        <Box className={classes.resumeSpacer} />

        {resume && (
          <div className={'no-print'}>
            <Card className={classes.card}>
              {resumeData &&
                pdfCheckRegex.test(resp.application.resume[0].extension) && (
                  <CardContent className={classes.resumeCardContent}>
                    <embed
                      src={resumeData}
                      type="application/pdf"
                      frameBorder="0"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                    />
                  </CardContent>
                )}
            </Card>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={4}
            >
              <Ripple>
                <Button
                  icon="file"
                  primary={true}
                  onClick={handleSaveFile}
                  type="submit"
                  className={classes.DownloadButton}
                >
                  Download Resume
                </Button>
              </Ripple>
            </Box>
          </div>
        )}
      </PDFExport>
    </Container>
  );
};

export default ViewApplication;
