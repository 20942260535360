import React, { useState, useRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { makeStyles } from '@material-ui/core';

// local
import {
  basicValidator,
  urlValidator,
} from '../../../../components/form-validators';
import { taskTypes } from '../../../../components/form-defaults';
import {
  FormTextArea,
  FormComboBox,
  FormInput,
} from '../../../../components/form-components';
import {
  errorMessageObjFactory,
  scrollFeedbackIntoView,
} from '../../../../utils/userFeedback';
import QuestionTaskFields from './QuestionTaskFields/QuestionTaskFields';
import DocumentTaskFields from './DocumentTaskFields/DocumentTaskFields';

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
    },
    window: {
      paddingBottom: theme.spacing(6),
    },
    actionBar: {
      position: 'absolute',
      bottom: -80,
      width: '100%',
    },
  }),
  { classNamePrefix: 'wp' }
);

function EditTaskWindow({ dataItem, dataItemIdx, setGridData, onCloseClick }) {
  const classes = useStyles();
  const [documentFormFeedback, setDocumentFormFeedback] = useState(null);
  const [questionFormFeedback, setQuestionFormFeedback] = useState(null);
  const [additionalDocumentForm, setAdditionalDocumentForm] = useState(null);
  const [formType, setFormType] = useState(dataItem.type);
  const documentFeedbackRef = useRef(null);
  const questionFeedbackRef = useRef(null);
  const formRenderPropsRef = useRef(null);
  // track answer value from radio buttons (*not part of form data)
  const [answer, setAnswer] = useState(+dataItem.answer);
  const documentId = formRenderPropsRef.current?.valueGetter('document_id');

  const [initialState] = useState(() => {
    const initialState = { ...dataItem };
    // convert any question choices to input properties on initialState
    if (dataItem.choices) {
      dataItem.choices.forEach(choice => {
        const inputName = `choice${choice.value}`;
        initialState[inputName] = choice.label;
      });
      delete initialState.choices;
    }
    return initialState;
  });

  const handleSubmit = useCallback(
    formData => {
      setDocumentFormFeedback(null);
      setQuestionFormFeedback(null);
      const updatedTask = {
        type: formData.type,
        title: formData.title,
        text: formData.text,
      };
      // check and update formData per type of task created
      if (updatedTask.type === 'Document') {
        if (!formData.document_id) {
          setDocumentFormFeedback(
            errorMessageObjFactory('Please upload a document.')
          );
          scrollFeedbackIntoView(documentFeedbackRef);
          return;
        }
        updatedTask.document_id = formData.document_id;
      } else if (updatedTask.type === 'Question') {
        if (!answer) {
          setQuestionFormFeedback(
            errorMessageObjFactory('Please create and choose a correct answer.')
          );
          scrollFeedbackIntoView(questionFeedbackRef);
          return;
        }
        // create question choices array and calculate adjusted answer
        const { choices, adjustedAnswer } = makeChoicesFromFormData(
          formData,
          answer
        );
        updatedTask.answer = adjustedAnswer;
        updatedTask.choices = choices;
      } else if (updatedTask.type === 'Video') {
        // url needs to start with protocol for external link
        updatedTask.video_url = /^http(s)?:\/\//.test(formData.video_url)
          ? formData.video_url
          : 'https://' + formData.video_url;
        updatedTask.video_password = formData.video_password;
      }

      setGridData(gridData => {
        gridData[dataItemIdx] = updatedTask;
        return gridData;
      });
      onCloseClick();
    },
    [answer, dataItemIdx, onCloseClick, setGridData]
  );

  const handleTypeChange = useCallback(
    ev => {
      const { value } = ev.target;
      if (!value) return;
      setFormType(value);
      if (value === 'Document') {
        if (documentId || initialState.document_id) {
          setAdditionalDocumentForm('update');
        } else {
          setAdditionalDocumentForm('upload');
        }
      } else {
        setAdditionalDocumentForm(null);
      }
    },
    [documentId, initialState.document_id]
  );

  return (
    <Window
      title={initialState.title ? 'Edit Task' : 'Create Task'}
      initialHeight={750}
      initialWidth={850}
      maximizeButton={() => null}
      minimizeButton={() => null}
      onClose={onCloseClick}
      modal={true}
      appendTo={document.body}
      className={classes.window}
    >
      <div className={classes.root}>
        <Form
          initialValues={initialState}
          onSubmit={handleSubmit}
          render={formRenderProps => {
            formRenderPropsRef.current = formRenderProps;

            return (
              <FormElement style={{ width: '100%', marginBottom: '20px' }}>
                <Field
                  id={'type'}
                  name={'type'}
                  label={'Type'}
                  component={FormComboBox}
                  data={taskTypes}
                  style={{ maxWidth: '200px' }}
                  onChange={handleTypeChange}
                  validator={basicValidator}
                />
                {formType !== 'Question' && (
                  <>
                    <Field
                      id={'title'}
                      name={'title'}
                      label={'Task Title'}
                      component={FormTextArea}
                      validator={basicValidator}
                    />

                    <Field
                      id={'text'}
                      name={'text'}
                      label={'Task Text'}
                      component={FormTextArea}
                      validator={basicValidator}
                    />
                  </>
                )}

                {formType === 'Document' && (
                  <Field
                    id={'document_id'}
                    name={'document_id'}
                    component={FormInput}
                    type={'hidden'}
                  />
                )}

                {formType === 'Question' && (
                  <QuestionTaskFields
                    formFeedback={questionFormFeedback}
                    feedbackRef={questionFeedbackRef}
                    dataItem={dataItem}
                    formRenderProps={formRenderProps}
                    answer={answer}
                    setAnswer={setAnswer}
                  />
                )}

                {formType === 'Video' && (
                  <>
                    <Field
                      id={'video_url'}
                      name={'video_url'}
                      label={'Video URL'}
                      component={FormInput}
                      validator={urlValidator}
                    />
                    <Field
                      id={'video_password'}
                      name={'video_password'}
                      label={'Video Password'}
                      component={FormInput}
                      validator={basicValidator}
                    />
                  </>
                )}

                <Box className={classes.actionBar}>
                  <WindowActionsBar layout="center">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      {initialState.title ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="contained" onClick={onCloseClick}>
                      Cancel
                    </Button>
                  </WindowActionsBar>
                </Box>
              </FormElement>
            );
          }}
        />

        <DocumentTaskFields
          additionalDocumentForm={additionalDocumentForm}
          setAdditionalDocumentForm={setAdditionalDocumentForm}
          documentFormFeedback={documentFormFeedback}
          setDocumentFormFeedback={setDocumentFormFeedback}
          documentFeedbackRef={documentFeedbackRef}
          formRenderPropsRef={formRenderPropsRef}
          initialState={initialState}
          documentId={documentId}
        />
      </div>
    </Window>
  );
}

/**
 * makeChoicesFromFormData
 *
 * Convert from formData key/val e.g.- {choice1: 'answer'}
 * to choices array [{label: 'answer', value: '1'}]
 *
 * Calculate how many digits to offset answer because of
 * deleted choices set to null value.
 *
 * @param {object} formData
 * @param {number} answer
 * @returns
 */
const makeChoicesFromFormData = (formData, answer) => {
  let choicesSeen = 0;
  let adjustAnswer = 0;
  const choices = Object.entries(formData)
    .map(([key, val]) => {
      if (key.startsWith('choice')) {
        choicesSeen++;
        if (!val && choicesSeen < answer) adjustAnswer++;
        return val;
      } else return null;
    })
    .filter(el => el)
    .map((el, idx) => ({ label: el, value: `${idx + 1}` }));

  const adjustedAnswer = `${answer - adjustAnswer}`;
  return { adjustedAnswer, choices };
};

export default EditTaskWindow;
