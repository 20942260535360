/** Index View */

import { useSelector } from 'react-redux';
import Login from '../Login/Login';
import { Redirect } from 'react-router-dom';

/**
 * Index
 * @returns Index View Component
 */

function Index() {
  const token = useSelector(state => state.user.token);

  const Component = token ? <Redirect to="/dashboard" /> : <Login />;

  return Component;
}

export default Index;
