import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Box, makeStyles, Typography } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import { updateTasksCompleted } from "../../../redux/actions/thunkCreators";

const useStyles = makeStyles(
  (theme) => ({
    title: {
      textAlign: "center",
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(6),
      },
    },
    subtitle: {
      textAlign: "center",
      marginTop: theme.spacing(1),
    },
    playButtonDiv: {},
    playButton: {
      fontSize: "15vh",
      marginTop: theme.spacing(3),
      "&:hover": {
        transform: "scale(1.1)",
        transition: ".4s",
        color: "lightblue",
      },
    },
  }),
  { classNamePrefix: "wp" }
);

const VideoTask = ({
  currTask,
  userCourse,
  taskNumber,
  userId,
  token,
  setAllowNext,
  currTaskDone,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const videoLinkClicked = useCallback(
    (ev) => {
      // if a new task was completed for user update user DB record
      if (!userCourse.tasks_completed[taskNumber]) {
        dispatch(
          updateTasksCompleted(userId, userCourse.course_id, taskNumber, token)
        );
      }
      setAllowNext(true);
    },
    [
      dispatch,
      setAllowNext,
      taskNumber,
      token,
      userCourse.course_id,
      userCourse.tasks_completed,
      userId,
    ]
  );

  useEffect(() => {
    if (!currTaskDone) setAllowNext(false);
    else setAllowNext(true);
  }, [setAllowNext, currTaskDone]);

  return (
    <>
      <Typography component="h1" variant="h2" className={classes.title}>
        {currTask.title}
      </Typography>
      <Typography component="h2" variant="h5" className={classes.subtitle}>
        {currTask.text}
      </Typography>
      <Box
        mt={10}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={classes.playButtonDiv}
      >
        <Typography component="h3" variant="h6" color={"secondary"}>
          Video Playback Password: {currTask.video_password}
        </Typography>
        <a
          href={currTask.video_url}
          target={"_blank"}
          rel="noreferrer"
          onClick={videoLinkClicked}
        >
          <PlayCircleOutlineIcon
            className={classes.playButton}
            color="primary"
          />
        </a>

        <Box mt={8}>
          <Typography component="h3" variant="h7">
            Click on the above play button to open the video a new browser
            window. After watching the video return to this browser window to
            complete the course.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default VideoTask;
