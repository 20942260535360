import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { Routes as appRoutes } from '../../routes/appRoutes';

const useStyles = makeStyles(
  (theme) => ({
    section: {
      margin: 25,
    },
    reportCard: {
      width: 345,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      minHeight: 100,
    },
    reportTitle: {
      fontSize: 8,
    },
    avatarRed: {
      backgroundColor: theme.palette.secondary.main,
    },
    avatarBlue: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  { classNamePrefix: "wp" }
);

function Reports() {
  const classes = useStyles();

  return (
    <Card className={classes.section}>
      <CardHeader title="Reports" />
      <CardContent>
        <Box display="flex" flexWrap="wrap" p={1} m={1}>
          <ReportCard
            name="Past Due"
            avatarText="PD"
            avatarClass={classes.avatarRed}
            link={appRoutes.reportPastDue}
            description="Includes all courses that have a due date in the past and are not completed"
          ></ReportCard>
          <ReportCard
            name="Assigned Courses"
            avatarText="AC"
            avatarClass={classes.avatarBlue}
            link={appRoutes.reportAssignedCourses}
            description="Displays users assigned to a course"
          ></ReportCard>
        </Box>
      </CardContent>
    </Card>
  );
}

function ReportCard({ name, avatarText, avatarClass, description, link }) {
  const classes = useStyles();

  return (
    <Link to={link}>
      <Card className={classes.reportCard}>
        <CardHeader
          avatar={
            <Avatar aria-label={name} className={avatarClass}>
              {avatarText}
            </Avatar>
          }
          title={name}
          subheader={description}
        ></CardHeader>
      </Card>
    </Link>
  );
}

export default Reports;
