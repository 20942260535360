import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Avatar from '@material-ui/core/Avatar';
import * as colors from '@material-ui/core/colors';

import { fileIconDictionary } from '../../utils/documentTypes';

const useStyles = makeStyles(
  () => ({
    card: {
      width: 310,
    },
    documentDetails: {
      display: 'block',
      overflow: 'hidden',
      width: 230,
    },
    avatar: {
      backgroundColor: colors.teal[600],
    },
  }),
  { classNamePrefix: 'wp' }
);

function DocumentCard({ document }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={'auto'}>
            <Avatar
              aria-label="file symbol"
              className={classes.avatar}
              role="img"
            >
              {fileIconDictionary(document.file_extension)}
            </Avatar>
          </Grid>
          <Grid item xs={'auto'} className={classes.documentDetails}>
            <Typography noWrap variant="h6" component="h2">
              {document.name}
            </Typography>
            <Typography noWrap variant="caption" component="p">
              Category: {document.category}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo(DocumentCard);
