import React, { useState, useCallback, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Routes as appRoutes } from '../../routes/appRoutes';
import onboarding from '../../assets/onboarding.jpg';
import training from '../../assets/training.jpg';
import welcome from '../../assets/welcome.jpg';
import CourseSummary from '../CourseSummary/CourseSummary';

const useStyles = makeStyles(
  () => ({
    card: {
      width: 300,
    },
    cardHeader: {
      display: 'block',
      overflow: 'hidden',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    actions: {
      justifyContent: 'center',
    },
  }),
  { classNamePrefix: 'wp' }
);

function UserCourseCard({ userCourse }) {
  const classes = useStyles();
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);

  // Format assignment date.
  const assignedOn = useMemo(
    () =>
      DateTime.fromISO(userCourse.assigned_date).toLocaleString({
        ...DateTime.DATE_SHORT,
      }),
    [userCourse.assigned_date]
  );
  // Format date of completion or calculate days until course completion is due.
  if (!userCourse.complete) {
    var due_in_days = Math.round(
      (Date.parse(userCourse.due_date) - Date.now()) / (1000 * 60 * 60 * 24)
    );
  } else {
    var completedDate = DateTime.fromISO(
      userCourse.completed_date
    ).toLocaleString({
      ...DateTime.DATE_SHORT,
    });
  }

  const closeModal = useCallback(() => setSummaryDialogOpen(false), []);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography noWrap variant="h6" component="h2">
              {userCourse.title}
            </Typography>
          }
          className={classes.cardHeader}
        />
        <CardMedia
          image={
            userCourse.image === 'onboarding'
              ? onboarding
              : userCourse.image === 'welcome'
              ? welcome
              : training
          }
          className={classes.media}
        />
        <CardContent>
          <Grid container spacing={1} justify={'space-between'}>
            <Grid item xs={12}>
              {userCourse.complete && (
                <Typography>Completed on {completedDate}</Typography>
              )}
              {!userCourse.complete && due_in_days > 0 && (
                <Typography>Due in {due_in_days} days</Typography>
              )}
              {!userCourse.complete && due_in_days <= 0 && (
                <Typography color="error">Past due</Typography>
              )}
            </Grid>

            <Grid item xs={'auto'}>
              <Typography variant="caption" color="textSecondary" component="p">
                Assigned on {assignedOn}
              </Typography>
            </Grid>

            <Grid item xs={'auto'}>
              <Typography variant="caption" color="textSecondary" component="p">
                Version {userCourse.course_version}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          {userCourse.complete && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSummaryDialogOpen(true)}
            >
              View Summary
            </Button>
          )}
          {!userCourse.complete && (
            <Link
              to={appRoutes.makeTakeCourseUrl(
                userCourse.course_id,
                userCourse.course_version
              )}
            >
              <Button variant="contained" color="primary">
                Start Course
              </Button>
            </Link>
          )}
        </CardActions>
      </Card>
      <CourseSummary
        userCourse={userCourse}
        open={summaryDialogOpen}
        onClose={closeModal}
      />
    </>
  );
}

export default React.memo(UserCourseCard);
