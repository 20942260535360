/**
 * allowedUploadExtensions
 *
 * File upload allowed extension types array.
 */

export const allowedUploadExtensions = [
  '.pdf',

  // word document types
  '.doc',
  '.dot',
  '.docm',
  '.docx',
  '.dotm',
  '.dotx',
  '.odt',
  '.rtf',
  '.wps',
  '.txt',

  // excel document types
  '.xml',
  '.xps',
  '.xls',
  '.xla',
  '.xlt',
  '.xlw',
  '.xlam',
  '.xlsx',
  '.xlsb',
  '.xlsm',
  '.csv',
];

/**
 * getMimeType
 * @param {string} extension file extension
 * @returns MIME type for the extension type
 */

export function getMimeType(extension) {
  switch (extension.toLowerCase()) {
    case '.pdf':
      return `application/pdf`;
    case '.doc':
      return `application/msword`;
    case '.dot':
      return `application/msword`;
    case '.docm':
      return `application/vnd.ms-word.document.macroEnabled.12`;
    case '.docx':
      return `application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
    case '.dotm':
      return `application/vnd.ms-word.template.macroEnabled.12`;
    case '.dotx':
      return `application/vnd.openxmlformats-officedocument.wordprocessingml.template`;
    case '.odt':
      return `application/vnd.oasis.opendocument.text`;
    case '.rtf':
      return `application/rtf`;
    case '.wps':
      return `application/wps`;
    case '.txt':
      return `text/plain`;
    case '.xml':
      return `application/xml`;
    case '.xps':
      return `application/vnd.ms-xpsdocument`;
    case '.xls':
      return `application/vnd.ms-excel`;
    case '.xla':
      return `application/msexcel`;
    case '.xlt':
      return `application/msexcel`;
    case '.xlw':
      return `application/msexcel`;
    case '.xlam':
      return `application/vnd.ms-excel.addin.macroEnabled.12`;
    case '.xlsx':
      return `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
    case '.xlsb':
      return `application/vnd.ms-excel.sheet.binary.macroEnabled.12`;
    case '.xlsm':
      return `	application/vnd.ms-excel.sheet.macroEnabled.12`;
    case '.csv':
      return `text/csv`;
    default:
      return `application/octet-stream`;
  }
}

// create a dataURL from base64 string.
export function createDataUrl(data, extension) {
  return `data:${getMimeType(extension)};base64,${data}`;
}

export function fileIconDictionary(extension) {
  switch (extension) {
    case '.pdf':
      return <span className="k-icon k-icon-2x k-i-file-pdf"></span>;
    case '.doc':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.dot':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.docm':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.docx':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.dotm':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.dotx':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.odt':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.rtf':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.wps':
      return <span className="k-icon k-icon-2x k-i-file-word"></span>;
    case '.txt':
      return <span className="k-icon k-icon-2x k-i-file-txt"></span>;
    case '.xml':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xps':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xls':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xla':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xlt':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xlw':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xlam':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xlsx':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xlsb':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.xlsm':
      return <span className="k-icon k-icon-2x k-i-file-excel"></span>;
    case '.csv':
      return <span className="k-icon k-icon-2x k-i-file-csv"></span>;
    default:
      return <span>{extension}</span>;
  }
}
