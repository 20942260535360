import { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// local
import { FormFeedback } from '../../utils/userFeedback';

function DeleteDialog({
  delModalOpen,
  setDelModalOpen,
  name,
  itemDetail,
  handleDelete,
  formFeedback,
}) {
  const [delDisabled, setDelDisabled] = useState(true);
  const nameTitleCase = name[0].toUpperCase() + name.slice(1);

  const handleClose = useCallback(() => {
    setDelModalOpen(false);
  }, [setDelModalOpen]);

  const handleChange = useCallback(e => {
    const { value } = e.target;

    if (/^confirm$/i.test(value)) {
      setDelDisabled(false);
    } else {
      setDelDisabled(true);
    }
  }, []);

  return (
    <div>
      <Dialog
        open={delModalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Delete {nameTitleCase} {itemDetail && ` - ${itemDetail}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To delete the current {name} please type "confirm" below then click
            the delete button.
          </DialogContentText>
          {formFeedback && <FormFeedback feedback={formFeedback} />}
          <TextField
            margin="dense"
            id="delete"
            label="Type Confirm"
            type="text"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
            disabled={delDisabled}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteDialog;
