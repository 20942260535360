import { useState, useCallback } from "react";
import SearchBar from "material-ui-search-bar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import ApiAgent from "../../utils/apiAgent";

function SearchUserForm({
  setSearchResults = () => {},
  setLoading = () => {},
}) {
  const [searchQuery, setSearchQuery] = useState(null);

  const handleSubmit = useCallback(() => {
    async function getResults() {
      setLoading(true);
      const resp = await ApiAgent.getUsers(searchQuery);
      setLoading(false);
      if (resp.error) {
      } else {
        setSearchResults(resp);
      }
    }
    getResults();
  }, [searchQuery, setSearchResults, setLoading]);

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      <Box mr={2}>
        <SearchBar
          onChange={(newValue) => setSearchQuery(newValue)}
          onCancelSearch={() => setSearchQuery(null)}
          onRequestSearch={handleSubmit}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Search
      </Button>
    </Box>
  );
}

export default SearchUserForm;
