import { DateTime } from 'luxon';

// local
import { toTitleCase } from '../../../../../utils/userFeedback';

/** Process edit history array of edit records for display in Kendo
 * Grid.
 *
 * @param {DocumentMetadataDB} metadata - document metadata
 * @returns array of EditRecords w/ additional properties added
 *
 */

export default function processEditHistory(metadata) {
  const editHistory = [...metadata.edit_history];
  const metadataLast = { ...metadata };

  // show latest edits first
  editHistory.reverse();

  // add properties to each edit history record for display
  editHistory.forEach(editRecord => {
    // make timestamp human readable and save at edit_date property.
    // zero pad day and hour for sort-ability
    editRecord.edit_date = DateTime.fromISO(
      editRecord.timestamp
    ).toLocaleString({
      ...DateTime.DATETIME_MED,
      ...{ hour: '2-digit', day: '2-digit' },
    });

    processPreviousData(editRecord, metadataLast);
  });

  return editHistory;
}

/** Process previous_data object.
 *
 * Create "fields_altered" property to hold array of field
 * names that had values changed when this edit happened.
 *
 * Create object at property "changed_to" to hold data in this
 * Edit Record about what the each changed value was changed to.
 *
 * Convert Tags for display.
 *
 * @param {EditRecord} editRecord
 * @param {DocumentMetadataDB} metadataLast
 */

function processPreviousData(editRecord, metadataLast) {
  editRecord.fields_altered = [];
  editRecord.changed_to = {};

  // If edits happened
  if (editRecord.previous_data) {
    Object.entries(editRecord.previous_data).forEach(([key, val]) => {
      // Remove "_" from field name and replace with a space and capitalize
      // first letter of each word then add to fields_altered array.
      editRecord.fields_altered.push(
        key
          .split('_')
          .map(word => toTitleCase(word))
          .join(' ')
      );

      // set value of what this field was change to in changed_to object.
      editRecord.changed_to[key] = metadataLast[key] || 'current value';
      metadataLast[key] = val;

      // if key is tags convert tag strings for display
      if (key === 'tags') val = val?.map(tag => toTitleCase(tag)).join(', ');
    });
  }
  return editRecord;
}
