import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardHeader,
  CardBody,
  CardSubtitle,
} from '@progress/kendo-react-layout';

const useStyles = makeStyles(theme => ({
  cardBody: {
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  cardUl: {
    lineHeight: 1.8,
  },
  cardSearchIcon: {
    marginBottom: '-6px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const UseCard = () => {
  const classes = useStyles();

  return (
    <>
      <CardHeader className={classes.cardHeader}>
        <CardSubtitle className={''}>
          Search documents by name, category, tags, or type.
        </CardSubtitle>
      </CardHeader>
      <CardBody className={classes.cardBody}>
        <ul className={classes.cardUl}>
          <li>
            Click{' '}
            <SearchIcon
              fontSize="small"
              className={classes.cardSearchIcon}
              alt="magnifying glass search icon"
              aria-hidden="false"
            />{' '}
            button with blank input to search all documents.
          </li>
          <li>
            <b>"Double Quote"</b> - Search for an exact match with <b>"</b>
          </li>
          <li>
            <b>name:progress report</b> - Search within a column with '<b>:</b>'
          </li>
          <li>
            <b>guide + taxes</b> - Multiple requirements. Use '<b>+</b>'
          </li>
          <li>
            <b>name:marketing + type:".xml"</b> Combine previous operators.
          </li>
        </ul>
      </CardBody>
    </>
  );
};

export default UseCard;
