import React, { useState, useRef, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DateTime } from "luxon";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";

import {
  FormInput,
  FormCheckbox,
  FormFloatingMultiSelect,
} from "../../../components/form-components";
import ApiAgent, { Routes as apiRoutes } from "../../../utils/apiAgent";
import { Routes as appRoutes } from "../../../routes/appRoutes";
import { AxiosGetHook } from "../../../hooks/axiosHook";
import UserCourseGrid from "./UserCourseGrid";
import {
  networkErrorMessageObjFactory,
  successMessageObjFactory,
  errorMessageObjFactory,
  FormFeedback,
  scrollFeedbackIntoView,
} from "../../../utils/userFeedback";
import {
  userNameValidator,
  emailValidator,
  requiredValidator,
  passwordValidator,
} from "../../../utils/validators";

import userTagData from "../../../testing/MockData/UserTags.json";

const useStyles = makeStyles(
  (theme) => ({
    section: {
      margin: 25,
    },
    list: {
      paddingTop: 0,
    },
    formButtons: {
      marginTop: theme.spacing(2),
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    formLegend: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      margin: "2rem 0 1rem",
      padding: 0,
      width: "100%",
      borderWidth: "0 0 2px",
      borderStyle: "solid",
      fontSize: 14,
      textTransform: "uppercase",
    },
  }),
  { classNamePrefix: "wp" }
);

function UserEdit() {
  const classes = useStyles();
  const { id } = useParams();
  const [formFeedback, setFormFeedback] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const token = useSelector((state) => state.user.token);
  const params = useRef({ _token: token });
  const feedbackRef = useRef(null);
  const [password, setPassword] = useState("");
  const passwordHandleChange = (event) => setPassword(event.target.value);
  const confirmPasswordValidator = (value) =>
    value === password ? "" : "Password must match.";

  const groupAxiosCall = AxiosGetHook(apiRoutes.getAccessGroups());
  const allGroups = groupAxiosCall.resp;
  const loadingGroups = groupAxiosCall.loading;

  const { resp, loading } = AxiosGetHook(apiRoutes.getUser(id), params.current);
  const user = resp;

  const resetPasswordHandleChange = (event) => {
    setResetPassword(event.value);
  };

  const handleSubmit = useCallback(
    async (formData) => {
      setFormFeedback(null);
      setUpdateLoading(true);
      // remove the fields that shouldn't be included in the update (confirmPassword, courses)
      const { confirmPassword, courses, ...editData } = {
        ...formData,
        _token: token,
      };

      const resp = await ApiAgent.patchUser(id, editData);

      if (resp?.error) {
        // error
        setFormFeedback(networkErrorMessageObjFactory(resp.error));
      } else if (resp) {
        // success
        setFormFeedback(successMessageObjFactory("User Updated!"));
      } else {
        setFormFeedback(errorMessageObjFactory("Unknown error"));
      }

      scrollFeedbackIntoView(feedbackRef);
      setUpdateLoading(false);
    },
    [id, token]
  );

  return (
    <Card className={classes.section}>
      <CardHeader title="Edit User" />
      <CardContent>
        {(loading || loadingGroups) && (
          <Box textAlign="center" m={3}>
            <CircularProgress aria-label="loading indicator" />
          </Box>
        )}
        {!(loading || loadingGroups) && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Form
                  onSubmit={handleSubmit}
                  initialValues={user}
                  render={(formRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                      {formFeedback && (
                        <FormFeedback
                          feedback={formFeedback}
                          feedbackRef={feedbackRef}
                        />
                      )}
                      {user.is_ad_auth && (
                        <Box
                          borderColor="secondary.main"
                          borderRadius={8}
                          bgcolor="warning.main"
                          color="warning.contrastText"
                          lineHeight={1.5}
                          p={1}
                          pl={1}
                          pr={1}
                        >
                          User management is controlled by Active Directory.
                          Disabled fields must be updated from the source
                          system.
                        </Box>
                      )}
                      <Field
                        name={"username"}
                        component={FormInput}
                        label={"Username"}
                        validator={user.is_ad_auth ? null : userNameValidator}
                        disabled={user.is_ad_auth}
                      />
                      <Field
                        name={"firstname"}
                        component={FormInput}
                        label={"First name"}
                        validator={user.is_ad_auth ? null : requiredValidator}
                        disabled={user.is_ad_auth}
                      />
                      <Field
                        name={"lastname"}
                        component={FormInput}
                        label={"Last name"}
                        validator={user.is_ad_auth ? null : requiredValidator}
                        disabled={user.is_ad_auth}
                      />
                      <Field
                        name={"email"}
                        type={"email"}
                        component={FormInput}
                        label={"Email"}
                        validator={user.is_ad_auth ? null : emailValidator}
                        disabled={user.is_ad_auth}
                      />

                      <Field
                        id={"tags"}
                        name={"tags"}
                        component={FormFloatingMultiSelect}
                        label={"Tags"}
                        formOnChange={formRenderProps.onChange}
                        data={userTagData.userTags}
                        allowCustom={true}
                        allowDuplicates={false}
                      />

                      <Field
                        id={"groups"}
                        name={"groups"}
                        component={FormFloatingMultiSelect}
                        label={"Groups"}
                        formOnChange={formRenderProps.onChange}
                        data={allGroups}
                        allowCustom={false}
                        allowDuplicates={false}
                      />

                      {!user.is_ad_auth && (
                        <>
                          <div className="k-form-field">
                            <Checkbox
                              checked={resetPassword}
                              onChange={resetPasswordHandleChange}
                              label={"Reset Password"}
                            />
                          </div>
                          {resetPassword && (
                            <>
                              <Field
                                name={"password"}
                                type={"password"}
                                component={FormInput}
                                label={"Password"}
                                autoComplete="off"
                                validator={passwordValidator}
                                onChange={passwordHandleChange}
                              />
                              <Field
                                name={"confirmPassword"}
                                type={"password"}
                                component={FormInput}
                                label={"Confirm Password"}
                                autoComplete="off"
                                validator={confirmPasswordValidator}
                              />
                            </>
                          )}
                        </>
                      )}
                      <Field
                        name={"is_active"}
                        label={"Active"}
                        component={FormCheckbox}
                        disabled={user.is_ad_auth}
                      />
                      <Field
                        name={"is_locked"}
                        label={"Account Locked"}
                        component={FormCheckbox}
                      />
                      <div className={classes.formButtons}>
                        <Button
                          type={"submit"}
                          variant="contained"
                          color="primary"
                          disabled={
                            !formRenderProps.allowSubmit || updateLoading
                          }
                        >
                          Save
                        </Button>
                        <Link to={appRoutes.adminUsers}>
                          <Button variant="contained">Cancel</Button>
                        </Link>
                      </div>
                    </FormElement>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  border={1}
                  borderRadius={4}
                  borderColor="primary.light"
                  p={2}
                >
                  <Typography variant="body2" gutterBottom={true}>
                    Source:
                    {user.is_ad_auth ? " Active Directory" : " Manual Entry"}
                  </Typography>
                  {user.created_date && (
                    <Typography variant="body2" gutterBottom={true}>
                      Created On:{" "}
                      {DateTime.fromISO(user.created_date).toLocaleString(
                        DateTime.DATETIME_SHORT
                      )}
                    </Typography>
                  )}
                  {!user.is_active && (
                    <Typography variant="body2" gutterBottom={true}>
                      Inactivated On: {user.inactive_date}
                    </Typography>
                  )}
                  {user.groups && user.groups.length > 0 && (
                    <Typography variant="body2" component="div">
                      Groups:
                      <List dense={true} className={classes.list}>
                        {user.groups.map((group) => (
                          <ListItem className={classes.list}>
                            <ListItemText primary={group} />
                          </ListItem>
                        ))}
                      </List>
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <legend className={classes.formLegend}>Courses:</legend>
            <UserCourseGrid courses={user.courses || []}></UserCourseGrid>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default UserEdit;
