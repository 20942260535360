const emailRegex = new RegExp(/\S+@\S+\.\S+/);

export const emailValidator = (value) =>
  !value
    ? "Email field is required."
    : emailRegex.test(value)
    ? ""
    : "Email is not in a valid format.";

export const userNameValidator = (value) =>
  !value
    ? "User Name is required"
    : value.length < 5
    ? "User name should be at least 5 characters long."
    : "";

export const requiredValidator = (value) =>
  value ? "" : "This field is required.";

export const passwordValidator = (value) =>
  value && value.length >= 8 ? "" : "Password must be at least 8 symbols.";