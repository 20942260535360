import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';

// local
import { basicValidator } from '../../components/form-validators';
import { FormInput, FormDatePicker } from '../../components/form-components';

const MilitaryServiceFields = ({ initialValues }) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field
          name={'military_branch'}
          component={FormInput}
          label={'If yes, what branch?'}
          id={'military_branch'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'military_start_date'}
          component={FormDatePicker}
          label={'Start Date of Duty'}
          id={'military_start_date'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'military_end_date'}
          component={FormDatePicker}
          label={'End Date of Duty'}
          id={'military_end_date'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'military_rank'}
          component={FormInput}
          label={'Rank at Discharge'}
          id={'military_rank'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
    </>
  );
};

export default MilitaryServiceFields;
