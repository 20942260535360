/** Document search results reducer. */

import { RESET_SEARCH, SET_RESULTS, SET_QUERY } from '../actions/types';

const INIT_STATE = {
  results: null,
  query: null,
};

function search(state = INIT_STATE, action) {
  switch (action.type) {
    case RESET_SEARCH:
      return { ...INIT_STATE };

    case SET_RESULTS:
      return {
        ...state,
        results: action.payload,
      };

    case SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };

    default:
      return state;
  }
}

export default search;
