import { DateTime } from "luxon";

export const BooleanCell = (props) => (
  <td>{props.dataItem[props.field] ? "Yes" : "No"}</td>
);

export const DateDisplayCell = (props) => (
  <td className="k-command-cell">
    {props.dataItem[props.field]
      ? DateTime.fromISO(props.dataItem[props.field]).toLocaleString(
          DateTime.DATE_SHORT
        )
      : ""}
  </td>
);

export const CourseTitleVersionCell = (props) => (
  <td className="k-command-cell">
    {props.dataItem["title"]} ({props.dataItem["course_version"]})
  </td>
);