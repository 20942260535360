import { useState } from 'react';
import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';

// local
import {
  FormInput,
  FormRadioGroup,
  FormDatePicker,
} from '../../components/form-components';
import {
  basicValidator,
  booleanValidator,
} from '../../components/form-validators';

const LicensesCertificatesFields = ({ initialValues, classes }) => {
  const [showLicenses, setShowLicenses] = useState(
    initialValues ? initialValues.professional_licenses : true
  );

  const handleLicenseChange = e => {
    const value = e.value;
    if (value === true) {
      setShowLicenses(true);
    } else {
      setShowLicenses(false);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Field
          name={'professional_licenses'}
          component={FormRadioGroup}
          data={[
            {
              label: 'No',
              value: false,
            },
            {
              label: 'Yes',
              value: true,
            },
          ]}
          label={'Do you have any professional licenses and/or certifications'}
          id={'professional_licenses'}
          layout={'horizontal'}
          labelStyle={{ display: 'block', fontWeight: 'bold' }}
          onChange={handleLicenseChange}
          validator={booleanValidator}
          disabled={!!initialValues}
        />
      </Grid>
      {showLicenses && (
        <>
          {/* First license input */}
          <Hidden lgUp>
            <legend className={classes.formLegendAlternate}>
              License/Certificate One
            </legend>
          </Hidden>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'licenses_1_type'}
              component={FormInput}
              label={'Type'}
              id={'licenses_1_type'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'licenses_1_issued_by'}
              component={FormInput}
              label={'Organization or State Issued'}
              id={'licenses_1_issued_by'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Field
              name={'licenses_1_date_issued'}
              component={FormDatePicker}
              label={'Date Issued'}
              id={'licenses_1_date_issued'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Field
              name={'licenses_1_number'}
              component={FormInput}
              label={'Number'}
              id={'licenses_1_number'}
              validator={basicValidator}
              disabled={!!initialValues}
            />
          </Grid>

          {/* Second license input */}
          <Hidden lgUp>
            <legend className={classes.formLegendAlternate}>
              License/Certificate Two
            </legend>
          </Hidden>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'licenses_2_type'}
              component={FormInput}
              label={'Type'}
              id={'licenses_2_type'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'licenses_2_issued_by'}
              component={FormInput}
              label={'Organization or State Issued'}
              id={'licenses_2_issued_by'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Field
              name={'licenses_2_date_issued'}
              component={FormDatePicker}
              label={'Date Issued'}
              id={'licenses_2_date_issued'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Field
              name={'licenses_2_number'}
              component={FormInput}
              label={'Number'}
              id={'licenses_2_number'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>

          {/* Third license input */}
          <Hidden lgUp>
            <legend className={classes.formLegendAlternate}>
              License/Certificate Three
            </legend>
          </Hidden>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'licenses_3_type'}
              component={FormInput}
              label={'Type'}
              id={'licenses_3_type'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'licenses_3_issued_by'}
              component={FormInput}
              label={'Organization or State Issued'}
              id={'licenses_3_issued_by'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Field
              name={'licenses_3_date_issued'}
              component={FormDatePicker}
              label={'Date Issued'}
              id={'licenses_3_date_issued'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <Field
              name={'licenses_3_number'}
              component={FormInput}
              label={'Number'}
              id={'licenses_3_number'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default LicensesCertificatesFields;
