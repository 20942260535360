import { Button } from '@progress/kendo-react-buttons';
import { Ripple } from '@progress/kendo-react-ripple';
import { makeStyles } from '@material-ui/core';

// local
import MetadataCard from './MetadataCard/MetadataCard';

const useStyles = makeStyles(
  theme => ({
    DownloadButton: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

function DocumentDetails({ fileMetadata, url }) {
  const classes = useStyles();

  const DownloadButton = (
    <a href={url} download>
      <Ripple>
        <Button icon="file" primary={true} className={classes.DownloadButton}>
          Download
        </Button>
      </Ripple>
    </a>
  );

  return (
    <MetadataCard fileMetadata={fileMetadata} downloadButton={DownloadButton} />
  );
}

export default DocumentDetails;
