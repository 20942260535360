import { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// local
import ApiAgent from '../../utils/apiAgent';
import {
  FormFeedback,
  successMessageObjFactory,
  scrollFeedbackIntoView,
  errorMessageObjFactory,
} from '../../utils/userFeedback';
import { FormCheckbox } from '../form-components';
import UserSelectionList from './UserSelectionList/UserSelectionList';

const useStyles = makeStyles(
  theme => ({
    formElement: {
      width: '100%',
      marginBottom: '20px',
    },
    formLegend: {
      borderColor: 'rgba(0, 0, 0, 0.12)',
      margin: '0 0 1rem',
      padding: 0,
      width: '100%',
      borderWidth: '0 0 2px',
      borderStyle: 'solid',
      fontSize: 14,
      textTransform: 'uppercase',
    },
    formInputs: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(5),
    },
    FormCheckbox: {
      marginLeft: theme.spacing(2),
    },
  }),
  { classNamePrefix: 'wp' }
);

function AssignCourseWindow({ courseDetails, setAssignWindowOpen }) {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const [usersSelected, setUsersSelected] = useState([]);
  const [updateAssignment, setUpdateAssignment] = useState(false);
  const [formFeedback, setFormFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const feedbackRef = useRef(null);
  const formRenderPropsRef = useRef(null);

  const handleSubmit = useCallback(
    async formData => {
      if (usersSelected.length === 0)
        return setFormFeedback(
          errorMessageObjFactory('Please select one or more users.')
        );
      setLoading(true);
      const requests = usersSelected.map(x =>
        ApiAgent.assignCourse(
          x.id,
          courseDetails.course_id,
          token,
          updateAssignment
        )
      );

      const responses = await Promise.all(requests);
      const errors = responses.filter(x => x.error).map(x => x.error);

      if (errors.length > 0) {
        setFormFeedback(
          errorMessageObjFactory(
            <>
              Encountered {errors.length} errors while assigning courses:
              <ul>
                {errors.map((x, index) => (
                  <li key={index}>{x.message}</li>
                ))}
              </ul>
            </>
          )
        );
      } else {
        setFormFeedback(
          successMessageObjFactory(
            `Course assigned to ${responses.length} user(s).`
          )
        );
      }

      scrollFeedbackIntoView(feedbackRef);
      setLoading(false);
    },
    [courseDetails.course_id, token, updateAssignment, usersSelected]
  );

  const onCloseClick = useCallback(() => {
    setAssignWindowOpen(false);
  }, [setAssignWindowOpen]);

  return (
    <Window
      title={'Assign Course'}
      initialHeight={800}
      initialWidth={1200}
      maximizeButton={() => null}
      minimizeButton={() => null}
      onClose={onCloseClick}
      modal={true}
      appendTo={document.body}
    >
      <Typography variant="h6">Course: {courseDetails?.title}</Typography>
      <Form
        initialValues={null}
        render={formRenderProps => {
          formRenderPropsRef.current = formRenderProps;
          return (
            <FormElement className={classes.formElement}>
              <legend className={classes.formLegend}>
                Please choose the users to assign the course to
              </legend>
              {formFeedback && (
                <FormFeedback
                  feedback={formFeedback}
                  feedbackRef={feedbackRef}
                />
              )}
              <UserSelectionList
                usersSelectedCallback={users => setUsersSelected(users)}
              />

              <div>{usersSelected.length} users selected for assignment</div>

              <Field
                value={updateAssignment}
                onChange={e => setUpdateAssignment(e.value)}
                component={FormCheckbox}
                label={'Update user to latest course version.'}
                hint={
                  'If this course was previously assigned to this user and has not been completed.'
                }
              />

              <Box mt={3} />
              <WindowActionsBar layout="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Assign
                </Button>
                <Button variant="contained" onClick={onCloseClick}>
                  Cancel
                </Button>
              </WindowActionsBar>
            </FormElement>
          );
        }}
      />
    </Window>
  );
}

export default AssignCourseWindow;
