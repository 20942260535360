import { useRef, useMemo, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Loader } from '@progress/kendo-react-indicators';
import { DateTime } from 'luxon';

//local
import { Routes as AppRoutes } from '../../../routes/appRoutes';
import { Routes as ApiRoutes } from '../../../utils/apiAgent';
import { AxiosGetHook } from '../../../hooks/axiosHook';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
} from '../../../utils/userFeedback';

const useStyles = makeStyles(
  theme => ({
    section: {
      margin: 25,
    },
    loader: {
      marginTop: theme.spacing(26),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(12),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(30),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(36),
      },
    },
    dataGrid: {
      height: 'calc(100vh - 230px)',
      minHeight: 200,
    },
  }),
  { classNamePrefix: 'wp' }
);

const AdminApplications = () => {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const params = useRef({ _token: token });

  // get course
  const url = ApiRoutes.getApplications;
  const { resp, error, loading } = AxiosGetHook(url, params.current);

  const applications = useMemo(
    () =>
      resp?.applications.map(el => {
        return {
          ...el,
          applicant_signature_date: DateTime.fromISO(
            el.applicant_signature_date
          ).toLocaleString({
            ...DateTime.DATE_MED,
          }),
          continueApplication: el.continueApplication ? 'Yes' : 'No',
        };
      }),
    [resp]
  );

  const CommandCell = useCallback(
    props => (
      <td className="k-command-cell">
        <Link to={AppRoutes.makeViewApplication(props.dataItem.id)}>
          <Button color="primary">View</Button>
        </Link>
      </td>
    ),
    []
  );

  if (error)
    return <FormFeedback feedback={networkErrorMessageObjFactory(error)} />;
  if (loading)
    return (
      <Box
        display="flex"
        direction="row"
        justifyContent="center"
        className={classes.loader}
      >
        <Loader size="large" type={'infinite-spinner'} />
      </Box>
    );

  return (
    <>
      <Card className={classes.section}>
        <CardHeader title="Applications" />
        <CardContent>
          <Grid
            className={classes.dataGrid}
            data={applications}
            reorderable={true}
            resizable={true}
          >
            {/* <GridToolbar>
              <Link to={AppRoutes.makeEditCourse(uuid4())}>
                <Button variant="contained" color="primary">
                  Set Application Alert Emails
                </Button>
              </Link>
            </GridToolbar> */}

            <GridColumn field="firstname" title="First Name" key="id" />
            <GridColumn field="lastname" title="Last Name" key="id" />
            <GridColumn
              field="position_desired"
              title="Position Desired"
              key="id"
            />
            <GridColumn
              field="continueApplication"
              title="Continue Application"
              key="id"
            />
            <GridColumn
              field="applicant_signature_date"
              title="Application Date"
              key="id"
            />
            <GridColumn cell={CommandCell} width="300px" />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AdminApplications;
