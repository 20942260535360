import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { DateDisplayCell, CourseTitleVersionCell } from "../../../utils/gridCells";

function UserCourseGrid({ courses }) {
  const [sort, setSort] = useState([{ field: "assigned_date", dir: "desc" }]);

  return (
    <Grid
      reorderable={true}
      resizable={true}
      data={orderBy(courses, sort)}
      sortable={true}
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
      }}
    >
      <GridColumn
        field="title"
        title="Title (Version)"
        cell={CourseTitleVersionCell}
        minResizableWidth={50}
      />
      <GridColumn
        field="assigned_date"
        title="Assigned"
        cell={DateDisplayCell}
        width="120"
      />
      <GridColumn
        field="due_date"
        title="Due"
        cell={DateDisplayCell}
        width="120"
      />
      <GridColumn
        field="completed_date"
        title="Completed"
        cell={DateDisplayCell}
        width="120"
      />
      <GridColumn field="score" title="Score" />
    </Grid>
  );
}

export default UserCourseGrid;
