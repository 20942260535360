import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';

// local
import { basicValidator } from '../../components/form-validators';
import { FormInput, FormRadioGroup } from '../../components/form-components';

const employmentTypeData = [
  {
    label: 'Full Time',
    value: 'Full Time',
  },
  {
    label: 'Part Time',
    value: 'Part Time',
  },
  {
    label: 'Temporary',
    value: 'Temporary',
  },
];

const workedAsAideTypeData = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];

const PositionDesiredFields = ({ initialValues }) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field
          name={'position_desired'}
          component={FormInput}
          label={'Position'}
          id={'position_desired'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'shift_desired'}
          component={FormInput}
          label={'Shift'}
          id={'shift_desired'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          id={'salary_desired'}
          name={'salary_desired'}
          label={'Salary'}
          component={FormInput}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'how_heard_about'}
          component={FormInput}
          label={'How did you learn of this opening?'}
          id={'how_heard_about'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name={'employment_type_sought'}
          component={FormRadioGroup}
          data={employmentTypeData}
          label={'Will you accept employment of:'}
          id={'employment_type_sought'}
          layout={'horizontal'}
          labelStyle={{ fontWeight: 'bold' }}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name={'employment_worked_as_aide'}
          component={FormRadioGroup}
          data={workedAsAideTypeData}
          label={'Have you worked as an aide for at least 1 year in a supervised setting?'}
          id={'employment_worked_as_aide'}
          layout={'horizontal'}
          labelStyle={{ fontWeight: 'bold' }}
          optional={true}
          hint={'Only applicable when applying for an aide position.'}
          disabled={!!initialValues}
        />
      </Grid>
    </>
  );
};

export default PositionDesiredFields;
