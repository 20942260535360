import { decode } from 'jsonwebtoken';

/**
 *
 * @param tokenData
 * @returns string[] - list of groups which user has view permission
 */

export const getUserViewPermissions = token => {
  const tokenData = decode(token);

  // memo to remove duplicate category in case user has both "View" permission and "Contrib"
  const memo = new Set();
  // the category is at index "1" when permission split on "-"
  return tokenData?.groups
    .map(permission => permission.split('-')[1])
    .filter(category => {
      if (memo.has(category)) {
        return false;
      }
      memo.add(category);
      return true;
    });
};

/**
 *
 * @param tokenData
 * @returns string[] - list of groups which user has view permission
 */

export const getUserEditPermissions = token => {
  const tokenData = decode(token);

  // collect all "Contrib" permissions.
  return tokenData?.groups
    .map(permission => permission.split('-'))
    .filter(([, , permType]) => /^contrib$/i.test(permType))
    .map(el => el[1]);
};
