/** Upload View */

import React from 'react';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// local imports
import UploadDocumentForm from '../../components/UploadDocumentForm/UploadDocumentForm';
import Fab from './Fab/Fab';

const useStyles = makeStyles(
  theme => ({
    cardWrapper: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(10),
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
  { classNamePrefix: 'wp' }
);

/**
 * UploadDocument
 * @returns UploadDocument View Component
 */

function UploadDocument() {
  const classes = useStyles();

  return (
    <>
      <Container component="main" maxWidth="lg">
        <div className={classes.cardWrapper}>
          <Card className={classes.card}>
            <Avatar className={classes.avatar}>
              <CloudUploadIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Upload Document
            </Typography>
            <Box mt={2} />
            <CardContent className={'w-100 d-flex-col-start-center'}>
              <UploadDocumentForm />
            </CardContent>
          </Card>
          <Box mb={8} />
        </div>
      </Container>
      <Fab />
    </>
  );
}

export default React.memo(UploadDocument);
