import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

// local imports
import ApiAgent from '../../../../../../utils/apiAgent';
import { FileInput } from '../../../../../../components/UploadDocumentForm/form-components';
import { FormFloatingInput } from '../../../../../../components/form-components';
import {
  networkErrorMessageObjFactory,
  documentSuccessMessageObjFactory,
  FormFeedback,
  scrollFeedbackIntoView,
} from '../../../../../../utils/userFeedback';

const useStyles = makeStyles(
  theme => ({
    formWidth: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '82vw',
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

/**
 * UploadDocumentForm
 * @returns UploadDocumentForm Component
 *
 * Note: Metadata portion of form has no validators but will use
 *       sensible defaults. This is to guarantee if file upload has
 *       success metadata upload will have success as well.
 */

function UploadDocumentForm({ idCallback }) {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const [formFeedback, setFormFeedback] = useState(null);
  const feedbackRef = useRef(null);

  // upload document metadata on submit.
  const handleSubmit = useCallback(
    async formData => {
      const editedFormData = { ...formData };
      // delete display-only data size KB
      delete editedFormData.file_size_KB;
      // edit form data making sure defaults are added if needed
      // as form currently has no validation checks.
      // if category is blank or missing use "Course"
      editedFormData.category = editedFormData.category || 'Course';
      // if "name" was blank use original filename
      editedFormData.name =
        editedFormData.name === ''
          ? formData.id.match(/^\S+?_(.+)$/)[1]
          : formData.name;

      const resp = await ApiAgent.uploadDocMetadata({
        ...editedFormData,
        _token: token,
      });
      if (resp.error) {
        setFormFeedback(networkErrorMessageObjFactory(resp.error));
      } else {
        setFormFeedback(
          documentSuccessMessageObjFactory(
            'Document uploaded successfully!',
            'upload',
            formData.id
          )
        );
        if (idCallback) idCallback(formData.id);
      }
      scrollFeedbackIntoView(feedbackRef);
    },
    [token, idCallback]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      render={formRenderProps => (
        <FormElement className={classes.formWidth}>
          <fieldset className={'k-form-fieldset'}>
            {formFeedback && (
              <FormFeedback feedback={formFeedback} feedbackRef={feedbackRef} />
            )}

            <Field
              name={'name'}
              id={'name'}
              component={FormFloatingInput}
              label={'Document Name'}
              autoComplete={'off'}
              hint={'Leave blank to autofill when file is chosen'}
              className={classes.formWidth}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Field
                  name={'file_size_KB'}
                  id={'file_size_KB'}
                  component={FormFloatingInput}
                  label={'File Size in KB'}
                  type={'number'}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name={'file_extension'}
                  id={'file_extension'}
                  component={FormFloatingInput}
                  label={'File Extension'}
                  disabled={true}
                />
              </Grid>
            </Grid>

            <Box my={4} className={classes.formWidth}>
              <FileInput
                formRenderProps={formRenderProps}
                setFormFeedback={setFormFeedback}
                token={token}
                feedbackRef={feedbackRef}
              />
            </Box>

            <Field name={'id'} component={Input} type={'hidden'} />
            <Field name={'file_size'} component={Input} type={'hidden'} />
          </fieldset>
        </FormElement>
      )}
    />
  );
}

export default UploadDocumentForm;
