import { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { FormDropDownList } from '../../components/form-components';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
} from '../../utils/userFeedback';
import ApiAgent, { Routes as ApiRoutes } from '../../utils/apiAgent';
import { AxiosGetHook } from '../../hooks/axiosHook';
import { DateDisplayCell, CourseTitleVersionCell } from '../../utils/gridCells';

const useStyles = makeStyles(
  (theme) => ({
    section: {
      margin: 25,
    },
    sectionHeader: {
      paddingBottom: 0,
    },
    courseDropdown: {
      minWidth: 300,
    },
    searchButton: {
      maxHeight: 35,
      marginTop: 30,
      margin: theme.spacing(1),
    },
  }),
  { classNamePrefix: 'wp' }
);

function AssignedCoursesReport() {
  const classes = useStyles();
  const token = useSelector((state) => state.user.token);
  const params = useRef({ _token: token });
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [formFeedback, setFormFeedback] = useState(null);
  const [sort, setSort] = useState([{ field: 'assigned_date', dir: 'desc' }]);

  const excelExport = useRef(null);
  const exportExport = () => {
    if (excelExport.current !== null)
      excelExport.current.save(orderBy(searchResults, sort));
  };

  const { resp, error, loadingCourses } = AxiosGetHook(
    ApiRoutes.getCourses,
    params.current
  );

  if (error) setFormFeedback(networkErrorMessageObjFactory(error));

  const courses = useMemo(
    () => resp ? [ { title: 'All', course_id: '' }, ...resp.courses] : [],
    [resp]
  );

  useEffect(() => {
    // Don't search until a course is selected
    if (!course) return;

    async function fetchData() {
      setLoading(true);
      setSearchResults(null);
      const resp = await ApiAgent.getCourseAssignments(token, course.course_id);

      if (resp.error) {
        setFormFeedback(networkErrorMessageObjFactory(resp.error));
      } else {
        setSearchResults(resp);
      }
      setLoading(false);
    }
    fetchData();
  }, [token, course]);

  return (
    <Card className={classes.section}>
      <CardHeader
        title="Assigned Courses Report"
        className={classes.sectionHeader}
      />
      <CardContent>
        {formFeedback && courses && <FormFeedback feedback={formFeedback} />}
        <Form
          onSubmit={(formData) => setCourse(formData.course)}
          render={(formRenderProps) => (
            <FormElement>
              <Box display="flex" flexWrap="wrap" p={1} m={1}>
                <Field
                  name="course"
                  label="Course"
                  component={FormDropDownList}
                  data={courses}
                  textField="title"
                  dataItemKey="course_id"
                  wrapperClassName={classes.courseDropdown}
                  className={classes.courseDropdown}
                  hint={loadingCourses ? 'Loading' : ''}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formRenderProps.allowSubmit}
                  className={classes.searchButton}
                >
                  Search
                </Button>
              </Box>
            </FormElement>
          )}
        />
        {!course && <div>Select a course and click search</div>}
        {loading && (
          <Box textAlign="center" m={3}>
            <CircularProgress />
          </Box>
        )}
        {!loading && !formFeedback && course && (
          <ExcelExport ref={excelExport}>
            <Button color="primary" onClick={exportExport}>
              Export to Excel
            </Button>
            <Grid
              reorderable={true}
              resizable={true}
              data={orderBy(searchResults, sort)}
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
            >
              <GridColumn
                field="title"
                title="Title (Version)"
                cell={CourseTitleVersionCell}
                minResizableWidth={50}
              />
              <GridColumn
                field="username"
                title="User"
                minResizableWidth={50}
              />
              <GridColumn
                field="assigned_date"
                title="Assigned"
                cell={DateDisplayCell}
                width="120"
              />
              <GridColumn
                field="due_date"
                title="Due"
                cell={DateDisplayCell}
                width="120"
              />
              <GridColumn
                field="completed_date"
                title="Completed"
                cell={DateDisplayCell}
                width="120"
              />
              <GridColumn field="score" title="Score" width="120" />
            </Grid>
          </ExcelExport>
        )}
      </CardContent>
    </Card>
  );
}

export default AssignedCoursesReport;
