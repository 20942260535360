import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';
import { Hint } from '@progress/kendo-react-labels';

// local
import { FormInput, FormMaskedTextBox } from '../../components/form-components';

const PermanentAddressFields = ({ initialValues }) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field
          name={'permanent_street_address'}
          component={FormInput}
          label={'Street'}
          id={'permanent_street_address'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'permanent_city'}
          component={FormInput}
          label={'City'}
          id={'permanent_city'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Field
          name={'permanent_state'}
          component={FormInput}
          label={'State'}
          id={'permanent_state'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Field
          id={'permanent_zip_code'}
          name={'permanent_zip_code'}
          label={'Zip Code'}
          component={FormMaskedTextBox}
          mask="00000"
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Field
          id={'permanent_phone'}
          name={'permanent_phone'}
          label={'Phone Number'}
          component={FormMaskedTextBox}
          mask="(000) 000-0000"
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Field
          id={'permanent_phone_alt'}
          name={'permanent_phone_alt'}
          label={'Alt Phone Number'}
          component={FormMaskedTextBox}
          mask="(000) 000-0000"
          optional={true}
          disabled={!!initialValues}
        />
        <Hint>In case you can't be reached at your main phone number.</Hint>
      </Grid>
    </>
  );
};

export default PermanentAddressFields;
