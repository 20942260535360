import { useEffect, useState } from 'react';
import axios from 'axios';
import { appInsights } from '../AppInsights';

// local imports

/**
 *
 * @param {string} url url for GET request
 * @param {object literal} params query parameters
 * @returns
 */

export function AxiosGetHook(url, params, abort) {
  const [resp, setResp] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const resp = await axios.get(url, { params });
        setResp(resp.data);
      } catch (error) {
        console.error('AxiosGetHookError', error);
        appInsights.trackException({
          exception: error,
          properties: {
            name: 'AxiosGetHook.getData',
          },
        });
        setError(error);
      }
      setLoading(false);
    }
    if (abort) setLoading(false);
    else getData();
  }, [url, params, abort]);

  return { resp, error, loading, setResp, setError };
}
