/** Search Documents View */

import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Window } from '@progress/kendo-react-dialogs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Loader } from '@progress/kendo-react-indicators';

// local imports
import SearchDocumentForm from '../../components/SearchDocumentForm/SearchDocumentForm';
import SearchResultsGrid from '../../components/SearchResultsGrid/SearchResultsGrid';
import processSearchResults from './utils/processSearchResults';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
} from '../../utils/userFeedback';
import ApiAgent from '../../utils/apiAgent';
import UseCard from './UseCard/UseCard';
import Fab from './Fab/Fab';

const useStyles = makeStyles(
  theme => ({
    container: {
      // minHeight: '100vh',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '75vw',
      },
    },
    paper: {
      marginTop: theme.spacing(2),
      maxWidth: '100vw',
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(6),
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    window: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 350,
        minHeight: 450,
      },
    },
    infoButton: {
      borderRadius: '10px',
      padding: 10,
      marginTop: theme.spacing(4),
      opacity: 0.5,
    },
    loaderSpacing: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(4),
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

/**
 * Search
 * @returns Search View Component
 */

function Search() {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  const query = useSelector(state => state.search.query);
  const [loading, setLoading] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [infoWindowVisible, setInfoWindowVisible] = useState(false);
  const [formFeedback, setFormFeedback] = useState(null);

  const makeSearch = useCallback(async () => {
    setFormFeedback(null);
    setLoading(true);
    setSearchResults(null);
    const resp = await ApiAgent.searchDocMetadata({
      q: query,
      _token: token,
    });
    if (resp.error) {
      setFormFeedback(networkErrorMessageObjFactory(resp.error));
    } else {
      setSearchResults(processSearchResults(resp));
    }
    setLoading(false);
  }, [token, query]);

  // load results from previous query if previous query
  useEffect(() => {
    if (query !== null) makeSearch();
  }, [query, makeSearch]);

  const toggleUseWindow = useCallback(() => {
    setInfoWindowVisible(!infoWindowVisible);
  }, [infoWindowVisible]);

  return (
    <>
      <Container component="main" maxWidth="lg" className={classes.container}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SearchIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Search Documents
          </Typography>
          <Box mt={4} />
          <SearchDocumentForm
            setSearchResults={setSearchResults}
            previousQuery={query}
            className={'w-100'}
          />
          <Box mt={4}></Box>
          {loading && (
            <Box className={classes.loaderSpacing}>
              <Loader size="large" type={'infinite-spinner'} />
            </Box>
          )}

          {formFeedback && <FormFeedback feedback={formFeedback} />}

          {searchResults && <SearchResultsGrid searchResults={searchResults} />}
          {!searchResults && !loading && (
            <Tooltip anchorElement="target" position="top">
              <button
                title="How to Search"
                className={`k-button ${classes.infoButton}`}
                onClick={toggleUseWindow}
              >
                <span className="k-icon k-i-info k-icon-16"></span>
              </button>
            </Tooltip>
          )}
          {infoWindowVisible && (
            <Window
              className={classes.window}
              title={'How to Search'}
              onClose={toggleUseWindow}
              initialHeight={300}
              initialWidth={600}
            >
              <UseCard />
            </Window>
          )}
          <Box mb={10} />
        </div>
      </Container>
      <Fab />
    </>
  );
}

export default Search;
