import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

// local
import ApiAgent from '../../../utils/apiAgent';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
  scrollFeedbackIntoView,
  errorMessageObjFactory,
} from '../../../utils/userFeedback';
import { FormUpload } from '../../../components/form-components';
import LicensesCertificatesFields from '../../../components/ApplicationFieldGroups/LicensesCertificatesFields';
import EducationFields from '../../../components/ApplicationFieldGroups/EducationFields';
import AvailabilityFields from '../../../components/ApplicationFieldGroups/AvailabilityFields';
import PositionDesiredFields from '../../../components/ApplicationFieldGroups/PositionDesiredFields';
import PermanentAddressFields from '../../../components/ApplicationFieldGroups/PermanentAddressFields';
import PresentAddressFields from '../../../components/ApplicationFieldGroups/PresentAddressFields';
import MainApplicationFields from '../../../components/ApplicationFieldGroups/MainApplicationFields';
import EmploymentFields from '../../../components/ApplicationFieldGroups/EmploymentFields';
import ReferencesFields from '../../../components/ApplicationFieldGroups/ReferencesFields';
import EmploymentUnderstanding from '../../../components/ApplicationFieldGroups/EmploymentUnderstanding';
import AppImage from '../../../assets/application-image.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const allowedUploadExtensions = ['.pdf', '.rtf', '.doc', '.docx'];

const useStyles = makeStyles(
  (theme) => ({
    headerImageDiv: {
      backgroundImage: `url(${AppImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      position: 'relative',
      paddingTop: 'min(25.4vh, 25.4vw)',
      paddingBottom: 'min(25.4vh, 25.4vw)',
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(8),
      },
    },
    headerImgOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.55)',
    },
    headerText: {
      fontSize: 'max(6vh, 6vw)',
      textAlign: 'center',
      color: 'white',
      fontWeight: '900',
      [theme.breakpoints.up('md')]: {
        fontSize: 'min(7vh, 7vw)',
      },
      position: 'relative',
    },
    headerTextAlt: {
      fontSize: 'max(3vh, 3vw)',
      textAlign: 'center',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        fontSize: 'min(4vh, 4vw)',
      },
    },
    section: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    formLegend: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      fontWeight: '900',
      fontSize: 26,
      color: '#0266b0',
      width: '100%',
    },
    formLegendAlternate: {
      marginTop: theme.spacing(2),
      fontWeight: '700',
      width: '100%',
      fontSize: 18,
      color: '#0266b0',
    },
    fieldset: {
      marginBottom: theme.spacing(4),
      border: 'None',
    },
    uploadDiv: {
      maxWidth: '80vw',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'unset',
      },
    },
    formButtons: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    submitButton: {
      width: '100%',
      backgroundColor: '#0266b0',
      color: 'white',
    },
    textCenter: {
      textAlign: 'center',
    },
  }),
  { classNamePrefix: 'wp' }
);

function convertDates(initialValues) {
  const fields = [
    'date_available',
    'college_grad_date',
    'vocational_grad_date',
    'military_start_date',
    'military_end_date',
    'licenses_1_date_issued',
    'licenses_2_date_issued',
    'licenses_3_date_issued',
    'employment_1_start_date',
    'employment_1_end_date',
    'employment_2_start_date',
    'employment_2_end_date',
    'employment_3_start_date',
    'employment_3_end_date',
    'applicant_signature_date',
  ];

  fields.forEach((el) => {
    if (initialValues[el]) {
      initialValues[el] = new Date(initialValues[el]);
    }
  });
}

async function processFileData(uploadData, setFormFeedback, feedbackRef) {
  if (uploadData.resume && uploadData.resume[0]) {
    delete uploadData.resume[0].progress;
    delete uploadData.resume[0].status;
    var fileId = uploadData.resume[0].uid;
    const resumeFile = uploadData.resume[0].getRawFile();
    var dataURL = await toBase64(resumeFile);
    if (dataURL instanceof Error) {
      setFormFeedback(
        errorMessageObjFactory(
          'DataURL creation failed for resume. Please try again.'
        )
      );
      scrollFeedbackIntoView(feedbackRef);
      return { fileId: false, uploadData };
    }
  }
  return { fileId, uploadData, fileData: dataURL };
}

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function EmploymentApplication({ initialValues }) {
  const classes = useStyles();
  const [formFeedback, setFormFeedback] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const feedbackRef = useRef(null);
  const [submitting, setSubmitting] = useState(null);
  const [showSubmittedDialog, setShowSubmittedDialog] = useState(false);
  const formRenderPropsRef = useRef(null);
  const runOnceRef = useRef(!!initialValues);

  if (runOnceRef.current) {
    convertDates(initialValues);
    runOnceRef.current = false;
  }

  useEffect(() => {
    document.body.style.setProperty('--bodyBgColor', '#fff');
  }, []);

  const handleClose = () => {
    window.location.href = 'https://neprohomecare.com';
  };

  const handleSubmit = useCallback(async (formData) => {
    setSubmitting(true);
    setFormFeedback(null);
    setSubmitError(null);

    const { fileId, uploadData, fileData } = await processFileData(
      { ...formData },
      setFormFeedback,
      feedbackRef
    );
    if (fileId === false) return;

    const resp = await ApiAgent.submitApplication(
      { application: uploadData, fileData },
      fileId
    );
    if (resp.error) {
      setSubmitError(resp.error?.message || 'Unknown error');
      setFormFeedback(networkErrorMessageObjFactory(resp.error));
      scrollFeedbackIntoView(feedbackRef);
    } else {
      setShowSubmittedDialog(true);
    }
    setSubmitting(false);
  }, []);

  const ApplicationError = () => {
    return (
      <>
        {submitError && (
          <Box color="white" bgcolor="red" textAlign="center" m={3} p={2}>
            <h3>
              There was an error submitting your application, please try again.
              If you continue to receive this error, print this page as a PDF
              and email to{' '}
              <a href="mailto:employment@neprohomecare.com">
                employment@neprohomecare.com
              </a>
            </h3>
            <div>
              <small>Error details: {submitError}</small>
            </div>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {!initialValues && (
        <Box className={classes.headerImageDiv} id="application-header">
          <Box className={classes.headerImgOverlay} />
          <Typography
            component="h1"
            className={classes.headerText}
            id="application-header-text"
          >
            EMPLOYMENT APPLICATION
          </Typography>
        </Box>
      )}

      {initialValues && (
        <Typography className={classes.headerTextAlt}>
          EMPLOYMENT APPLICATION
        </Typography>
      )}

      <Container component="section" maxWidth="lg" className={classes.section}>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formRenderProps) => {
            formRenderPropsRef.current = formRenderProps;
            return (
              <FormElement>
                <fieldset className={classes.fieldset}>
                  {formFeedback && (
                    <FormFeedback
                      feedback={formFeedback}
                      feedbackRef={feedbackRef}
                    />
                  )}
                  <ApplicationError></ApplicationError>
                  <Grid container spacing={3}>
                    <MainApplicationFields initialValues={initialValues} />

                    <legend className={classes.formLegend}>
                      Present Address
                    </legend>
                    <PresentAddressFields initialValues={initialValues} />

                    <legend className={classes.formLegend}>
                      Permanent Address (If different than present address)
                    </legend>
                    <PermanentAddressFields initialValues={initialValues} />

                    <legend className={classes.formLegend}>
                      Position Desired
                    </legend>
                    <PositionDesiredFields initialValues={initialValues} />

                    <legend className={classes.formLegend}>Availability</legend>
                    <AvailabilityFields initialValues={initialValues} />

                    <legend className={classes.formLegend}>Education</legend>
                    <EducationFields
                      classes={classes}
                      initialValues={initialValues}
                    />

                    <legend className={classes.formLegend}>
                      Professional Licenses and/or Certifications
                    </legend>
                    <LicensesCertificatesFields
                      initialValues={initialValues}
                      classes={classes}
                    />

                    <legend className={classes.formLegend}>
                      Employment Record
                    </legend>
                    <EmploymentFields
                      classes={classes}
                      initialValues={initialValues}
                    />

                    <legend className={classes.formLegend}>References</legend>
                    <ReferencesFields
                      classes={classes}
                      initialValues={initialValues}
                    />

                    <Box className="w-100 no-print">
                      <legend className={classes.formLegendAlternate}>
                        Resume
                      </legend>
                      <Grid item xs={12} className={classes.uploadDiv}>
                        <Field
                          name={'resume'}
                          component={FormUpload}
                          id={'resume'}
                          restrictions={{
                            allowedExtensions: allowedUploadExtensions,
                            // max size 5 Megabytes
                            maxFileSize: 5242880,
                          }}
                          hint={'File must be type PDF, RTF, DOC, or DOCX.'}
                          disabled={!!initialValues}
                        />
                      </Grid>
                    </Box>

                    <legend className={classes.formLegendAlternate}>
                      Employment Understanding (Please Read and Sign)
                    </legend>
                    <EmploymentUnderstanding initialValues={initialValues} />
                  </Grid>
                </fieldset>

                {formRenderProps.touched &&
                  formRenderProps.visited &&
                  !formRenderProps.valid && (
                    <Box mb={3} ml={2} className={classes.textCenter}>
                      <FormFeedback
                        feedback={errorMessageObjFactory(
                          'Please correct all form errors.'
                        )}
                      />
                    </Box>
                  )}

                <div className={classes.formButtons}>
                  <Button
                    variant="contained"
                    type={'submit'}
                    disabled={
                      !formRenderProps.allowSubmit ||
                      submitting ||
                      initialValues
                    }
                    className={classes.submitButton}
                  >
                    Send
                  </Button>
                </div>

                {submitting && (
                  <Box textAlign="center" m={3}>
                    <h3>
                      <CircularProgress /> Submitting application, please
                      wait...
                    </h3>
                  </Box>
                )}

                <ApplicationError></ApplicationError>
              </FormElement>
            );
          }}
        />
      </Container>
      <Dialog
        open={showSubmittedDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'sm'}
        TransitionComponent={Transition}
      >
        <DialogTitle id="alert-dialog-title">{'Success!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Application successfully submitted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <Button onClick={handleClose} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmploymentApplication;
