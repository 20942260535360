import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Grid,
  GridColumn as Column,
  GridDetailRow,
} from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';

// local
import processEditHistory from './utils/processEditHistory';
import './EditHistoryGrid.css';

class DetailComponent extends GridDetailRow {
  render() {
    return (
      <ul>
        {Object.entries(this.props.dataItem.previous_data || []).map(
          ([k, v], idx) => (
            <li key={idx}>
              <span className="badge-a">{k}</span> changed from{' '}
              <span className="badge-a">{v}</span> to{' '}
              <span className="badge-a">
                {this.props.dataItem.changed_to[k]}
              </span>
            </li>
          )
        )}
      </ul>
    );
  }
}

const pageableSettings = {
  buttonCount: 5,
  info: true,
  type: 'numeric',
  pageSizes: [5, 10, 20, 50, 100],
  previousNext: true,
};

/**
 * EditHistoryGrid
 * @returns EditHistoryGrid Component
 */

function EditHistoryGrid({ metadata, gridRef }) {
  const editHistory = metadata.edit_history;
  const [pagingState, setpagingState] = useState({ skip: 0, take: 10 });
  const [data, setData] = useState(editHistory);
  const [sort, setSort] = useState([]);

  const pageChange = useCallback(event => {
    setpagingState({
      skip: event.page.skip,
      take: event.page.take,
    });
  }, []);

  // Process edit history for display.
  useEffect(() => {
    setData([...processEditHistory(metadata)]);
  }, [metadata]);

  const expandChange = useCallback(event => {
    event.dataItem.expanded = !event.dataItem.expanded;
    // force an update
    setSort(sort => [...sort]);
  }, []);

  const pageData = useMemo(
    () =>
      orderBy(
        data.slice(pagingState.skip, pagingState.take + pagingState.skip),
        sort
      ),
    [data, pagingState.skip, pagingState.take, sort]
  );

  const handleSortChange = useCallback(e => {
    setSort(e.sort);
  }, []);

  return (
    <div ref={gridRef}>
      <Grid
        className={'EditHistoryGrid'}
        onRowClick={e => {}}
        data={pageData}
        skip={pagingState.skip}
        take={pagingState.take}
        total={data.length}
        pageable={pageableSettings}
        onPageChange={pageChange}
        sortable={true}
        sort={sort}
        onSortChange={handleSortChange}
        reorderable={true}
        detail={DetailComponent}
        expandField="expanded"
        onExpandChange={expandChange}
      >
        <Column field="username" title="User" />
        <Column field="edit_type" title="Edit Type" />
        <Column field="edit_date" title="Edit Date" />
        <Column field="fields_altered" title="Fields Altered" />
      </Grid>
    </div>
  );
}

export default EditHistoryGrid;
