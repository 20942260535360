import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

// local
import {
  basicValidator,
  booleanValidator,
  phoneValidator,
} from '../../components/form-validators';
import {
  FormInput,
  FormRadioGroup,
  FormTextArea,
  FormMaskedTextBox,
} from '../../components/form-components';
import { emailValidator } from '../../utils/validators';

const ReferencesFields = ({ classes, initialValues }) => {
  return (
    <>
      <legend className={classes.formLegendAlternate}>Reference One</legend>
      <Grid item xs={12} sm={6}>
        <Field
          name={'reference_1_name'}
          component={FormInput}
          label={'Name'}
          id={'reference_1_name'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'reference_1_company'}
          component={FormInput}
          label={'Company'}
          id={'reference_1_company'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          id={'reference_1_phone'}
          name={'reference_1_phone'}
          label={'Phone'}
          component={FormMaskedTextBox}
          mask="(000) 000-0000"
          validator={phoneValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          name={'reference_1_email'}
          component={FormInput}
          label={'Email'}
          id={'reference_1_email'}
          validator={emailValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          name={'reference_1_location'}
          component={FormInput}
          label={'Location'}
          id={'reference_1_location'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>

      <legend className={classes.formLegendAlternate}>Reference Two</legend>
      <Grid item xs={12} sm={6}>
        <Field
          name={'reference_2_name'}
          component={FormInput}
          label={'Name'}
          id={'reference_2_name'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name={'reference_2_company'}
          component={FormInput}
          label={'Company'}
          id={'reference_2_company'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          id={'reference_2_phone'}
          name={'reference_2_phone'}
          label={'Phone'}
          component={FormMaskedTextBox}
          mask="(000) 000-0000"
          validator={phoneValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          name={'reference_2_email'}
          component={FormInput}
          label={'Email'}
          id={'reference_2_email'}
          validator={emailValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          name={'reference_2_location'}
          component={FormInput}
          label={'Location'}
          id={'reference_2_location'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>

      <legend className={classes.formLegendAlternate}>Other Information</legend>
      <Grid item xs={12}>
        <Typography variant="body1" color={'textSecondary'}>
          If your former employment references, education or military service
          are under another name other than indicated on the top of the
          application, please indicate that here
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Field
          name={'reference_other_info_lastname'}
          component={FormInput}
          label={'Last Name'}
          id={'reference_other_info_lastname'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          name={'reference_other_info_firstname'}
          component={FormInput}
          label={'First Name'}
          id={'reference_other_info_firstname'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          name={'reference_other_info_middle_initial'}
          component={FormInput}
          label={'Middle Initial'}
          id={'reference_other_info_middle_initial'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Field
          name={'reference_other_info_crime_conviction'}
          component={FormRadioGroup}
          data={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          label={'Have you ever been convicted of a crime?'}
          id={'reference_other_info_crime_conviction'}
          validator={booleanValidator}
          disabled={!!initialValues}
          layout={'horizontal'}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Field
          name={'reference_other_info_crime_details'}
          component={FormInput}
          label={'If yes, for what, when and where?'}
          id={'reference_other_info_crime_details'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name={'reference_other_info_further_info'}
          component={FormTextArea}
          label={
            'Use this space to give us further information which will assist us in placing you'
          }
          id={'reference_other_info_further_info'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
    </>
  );
};

export default ReferencesFields;
