import React from 'react';
import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';

// local
import {
  basicValidator,
  zipCodeValidator,
  phoneValidator,
} from '../../components/form-validators';
import {
  FormInput,
  FormRadioGroup,
  FormTextArea,
  FormMaskedTextBox,
  FormDatePicker,
} from '../../components/form-components';

const EmploymentFields = ({ classes, initialValues }) => {
  return (
    <>
      {['One', 'Two', 'Three'].map((el, idx) => (
        <React.Fragment key={idx}>
          <legend className={classes.formLegendAlternate}>
            Employment {el}
          </legend>
          <Grid item xs={12} sm={6}>
            <Field
              name={`employment_${idx + 1}_name`}
              component={FormInput}
              label={'Name'}
              id={`employment_${idx + 1}_name`}
              validator={idx + 1 === 1 ? basicValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={`employment_${idx + 1}_street_address`}
              component={FormInput}
              label={'Street Address'}
              id={`employment_${idx + 1}_street_address`}
              validator={idx + 1 === 1 ? basicValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={`employment_${idx + 1}_city`}
              component={FormInput}
              label={'City'}
              id={`employment_${idx + 1}_city`}
              validator={idx + 1 === 1 ? basicValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={`employment_${idx + 1}_state`}
              component={FormInput}
              label={'State'}
              id={`employment_${idx + 1}_state`}
              validator={idx + 1 === 1 ? basicValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              id={`employment_${idx + 1}_zip_code`}
              name={`employment_${idx + 1}_zip_code`}
              label={'Zip Code'}
              component={FormMaskedTextBox}
              validator={idx + 1 === 1 ? zipCodeValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
              mask="00000"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={`employment_${idx + 1}_supervisor`}
              component={FormInput}
              label={'Supervisor'}
              id={`employment_${idx + 1}_supervisor`}
              validator={idx + 1 === 1 ? basicValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              id={`employment_${idx + 1}_phone`}
              name={`employment_${idx + 1}_phone`}
              label={'Phone Number'}
              component={FormMaskedTextBox}
              mask="(000) 000-0000"
              validator={idx + 1 === 1 ? phoneValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={`employment_${idx + 1}_start_date`}
              component={FormDatePicker}
              label={'Employment Start Date'}
              id={`employment_${idx + 1}_start_date`}
              validator={idx + 1 === 1 ? basicValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={`employment_${idx + 1}_end_date`}
              component={FormDatePicker}
              label={'Employment End Date'}
              id={`employment_${idx + 1}_end_date`}
              validator={idx + 1 === 1 ? basicValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Field
              id={`employment_${idx + 1}_starting_pay_rate`}
              name={`employment_${idx + 1}_starting_pay_rate`}
              label={'Starting Pay Rate'}
              component={FormInput}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Field
              name={`employment_${idx + 1}_starting_pay_type`}
              component={FormRadioGroup}
              data={[
                {
                  label: 'Hourly',
                  value: 'hourly',
                },
                {
                  label: 'Annual Salary',
                  value: 'salary',
                },
              ]}
              label={'Pay Type'}
              id={`employment_${idx + 1}_starting_pay_type`}
              layout={'horizontal'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Field
              id={`employment_${idx + 1}_ending_pay_rate`}
              name={`employment_${idx + 1}_ending_pay_rate`}
              label={'Ending Pay Rate'}
              component={FormInput}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Field
              name={`employment_${idx + 1}_ending_pay_type`}
              component={FormRadioGroup}
              data={[
                {
                  label: 'Hourly',
                  value: 'hourly',
                },
                {
                  label: 'Annual Salary',
                  value: 'salary',
                },
              ]}
              label={'Pay Type'}
              id={`employment_${idx + 1}_ending_pay_type`}
              layout={'horizontal'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={`employment_${idx + 1}_position_duties`}
              component={FormTextArea}
              label={'Position and Duties'}
              id={`employment_${idx + 1}_position_duties`}
              validator={idx + 1 === 1 ? basicValidator : null}
              optional={idx + 1 === 1 ? false : true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={`employment_${idx + 1}_allow_contact`}
              component={FormRadioGroup}
              data={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              label={'May we contact your previous employer?'}
              id={`employment_${idx + 1}_allow_contact`}
              layout={'horizontal'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name={`employment_${idx + 1}_as_homecare_aid`}
              component={FormRadioGroup}
              data={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              label={'Was the previous employment as a Home Care Aide?'}
              id={`employment_${idx + 1}_as_homecare_aid`}
              layout={'horizontal'}
              optional={true}
              disabled={!!initialValues}
            />
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

export default EmploymentFields;
