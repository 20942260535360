// Basic validator
export const basicValidator = value => {
  if (typeof value === 'string') value = value.trim();
  return value ? '' : 'This is a required field.';
};

export const booleanValidator = value =>
  value === true || value === false ? '' : 'This is a required field.';

// Category validator
export const categoryValidator = value =>
  value ? '' : 'Category is a required field. Please select a category.';

// Name validator
export const nameValidator = value =>
  value?.trim() ? '' : 'Document Name is a required field.';

// Username validator
export const usernameValidator = value =>
  value ? '' : 'Username is a required field';

// Password validator
export const passwordValidator = value =>
  value ? '' : 'Password is a required field';

// URL validator
export const urlValidator = value =>
  /^[-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?$/gi.test(
    value
  )
    ? ''
    : 'Please enter a valid URL.';

// threeDigitMoneyValidator
export const threeDigitMoneyValidator = value => {
  // three digits but not three empties (space or zero)
  return /^\$[ \d]{2}(?<!\d )\d\.00$/.test(value) &&
    !/^\$[ 0](?=(00| {2}|0 | 0))[ \d]{2}\.00$/.test(value)
    ? ''
    : 'Please enter your expected starting rate.';
};

// sixDigitMoneyValidator
export const sixDigitMoneyValidator = value => {
  return /^\$[ \d]{3}(?<!\d {2}|\d\d |\d \d| \d | {3}),\d0{2}\.00$/.test(
    value
  ) && !/^\$[ 0]{3},[0 ]{3}\.00$/.test(value)
    ? ''
    : 'Please enter your expected starting rate.';
};

// phoneValidator
export const phoneValidator = value =>
  /^\(\d{3}\) \d{3}-\d{4}$/.test(value)
    ? ''
    : 'Please enter a valid phone number.';

// zipCodeValidator
export const zipCodeValidator = value =>
  /^\d{5}$/.test(value) ? '' : 'Please enter a valid zip code.';

  // ssnValidator
export const ssnValidator = value =>
/^\d{3}-\d{2}-\d{4}$/.test(value)
  ? ''
  : 'Please enter a valid social security number.';
