import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

// local
import { Routes as appRoutes } from '../../routes/appRoutes';
import './SearchResultsGrid.css';

const gridStyle = { maxHeight: '90vh', maxWidth: '92vw' };

const columns = [
  { field: 'name', title: 'Name' },
  { field: 'category', title: 'Category' },
  { field: 'tags', title: 'Tags' },
  { field: 'file_extension', title: 'Type' },
  { field: 'last_modified', title: 'Last Modified', minGridWidth: 400 },
];

const pageableSettings = {
  buttonCount: 5,
  info: true,
  type: 'numeric',
  pageSizes: true,
  previousNext: true,
};

/**
 * SearchResultsGrid
 * @returns SearchResultsGrid Component
 */

function SearchResultsGrid({ searchResults }) {
  const history = useHistory();
  const [pagingState, setPagingState] = useState({ skip: 0, take: 10 });
  const [visibleColumns, setVisibleColumns] = useState(columns);

  const pageChange = useCallback(event => {
    setPagingState({
      skip: event.page.skip,
      take: event.page.take,
    });
  }, []);

  useEffect(() => {
    // remove last modified column on smallest screen sizes.
    const handleResize = () => {
      const grid = document.querySelector('.k-grid');
      const currentVisibleColumns = columns.filter(
        c => !(c.minGridWidth > grid.offsetWidth)
      );
      if (currentVisibleColumns.length !== visibleColumns.length) {
        setVisibleColumns(currentVisibleColumns);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [visibleColumns]);

  const handleRowClick = useCallback(
    e => history.push(appRoutes.makeViewDocUrl(e.dataItem.id)),
    [history]
  );

  const gridData = useMemo(
    () =>
      searchResults.slice(
        pagingState.skip,
        pagingState.take + pagingState.skip
      ),
    [pagingState.skip, pagingState.take, searchResults]
  );

  return (
    <Grid
      className={'SearchResultsGrid'}
      style={gridStyle}
      onRowClick={handleRowClick}
      data={gridData}
      skip={pagingState.skip}
      take={pagingState.take}
      total={searchResults.length}
      pageable={pageableSettings}
      onPageChange={pageChange}
      reorderable={true}
    >
      {visibleColumns.map((column, index) => {
        return <Column field={column.field} title={column.title} key={index} />;
      })}
    </Grid>
  );
}

export default React.memo(SearchResultsGrid);
