import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import Button from '@material-ui/core/Button';
import { Loader } from '@progress/kendo-react-indicators';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

// local imports
import ApiAgent from '../../utils/apiAgent';
import { getUserEditPermissions } from '../../utils/auth/userAuth';
import { FileInput } from './form-components';
import { nameValidator, categoryValidator } from '../form-validators';
import { tags } from '../form-defaults';
import setQuery from '../../redux/actions/search/setQuery';
import {
  networkErrorMessageObjFactory,
  documentSuccessMessageObjFactory,
  FormFeedback,
  toTitleCase,
  scrollFeedbackIntoView,
  nameExistsMessageObjFactory,
} from '../../utils/userFeedback';
import {
  FormFloatingComboBox,
  FormFloatingInput,
  FormFloatingMultiSelect,
} from '../form-components';

const useStyles = makeStyles(
  theme => ({
    formWidth: {
      width: '100%',
      [theme.breakpoints?.down('xs')]: {
        maxWidth: '82vw',
      },
    },
    fieldset: {
      border: 'none',
      padding: 0,
    },
  }),
  { classNamePrefix: 'wp' }
);

/**
 * checkDataChanged
 *
 * Function to check if the form data has changed from last known state.
 * Used to prevent making a duplicate update to document metadata.
 *
 * @param {object} lastUpdate data from DB or from last update to DB
 * @param {obj} formData update document form data
 * @returns boolean
 */

function checkDataChanged(lastUpdate, formData) {
  const lastData = {
    name: lastUpdate.name,
    category: lastUpdate.category,
    tags: lastUpdate.tags,
  };
  const newData = { ...formData };
  newData.tags = newData.tags?.map(string => string.toLowerCase());

  // improve equality check?
  return JSON.stringify(lastData) !== JSON.stringify(newData);
}

/**
 * UpdateDocumentForm
 * @returns UpdateDocumentForm Component
 */

function UpdateDocumentForm({ id, error, loading, data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(state => state.user.token);
  const [formFeedback, setFormFeedback] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [formFeedbackFile, setFormFeedbackFile] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const feedbackRef = useRef(null);
  const nameTimerRef = useRef(null);
  const editPermissions = useMemo(() => getUserEditPermissions(token), [token]);

  const handleSubmit = useCallback(
    async formData => {
      setUpdateLoading(true);
      setFormFeedback(null);

      const editedFormData = { ...formData };
      // KB/file extension display only
      delete editedFormData.file_size_KB;
      delete editedFormData.file_extension;

      const dataChanged = checkDataChanged(lastUpdate || data, editedFormData);
      if (!dataChanged)
        setFormFeedback({
          message: 'Document properties unchanged.',
          type: 'error',
        });
      else {
        const updateResp = await ApiAgent.updateDocMetadata({
          ...editedFormData,
          _token: token,
          id,
        });
        if (updateResp.error) {
          setFormFeedback(networkErrorMessageObjFactory(updateResp.error));
        } else {
          setFormFeedback(
            documentSuccessMessageObjFactory(
              'Document properties updated!',
              'update',
              id
            )
          );
          setLastUpdate(updateResp);
        }
      }
      scrollFeedbackIntoView(feedbackRef);
      setUpdateLoading(false);
    },
    [id, lastUpdate, data, token]
  );

  // search if document with given name already exists using the document search endpoint.
  const searchName = useCallback(
    async name => {
      if (name === '') return;
      // create query for a name that matches name input value.
      const q = `name:"${name}"`;

      const setSearchQuery = () => {
        dispatch(setQuery(q));
      };

      const resp = await ApiAgent.searchDocMetadata({ q, _token: token });

      if (resp.error) {
        setFormFeedback(networkErrorMessageObjFactory(resp.error));
      } else if (resp[0]) {
        // show form feedback about document name collision and pass setSearchQuery
        // which will be called if user clicks link to existing document.
        setFormFeedback(nameExistsMessageObjFactory(name, setSearchQuery));
      }
    },
    [dispatch, token]
  );

  // As name input value changes search for matching document in DB after delay.
  const handleNameChange = useCallback(
    formRenderProps => {
      setFormFeedback(null);
      clearTimeout(nameTimerRef.current);

      nameTimerRef.current = setTimeout(() => {
        searchName(formRenderProps.valueGetter('name'));
      }, 800);
    },
    [searchName]
  );

  // remove timer if component is dismounted.
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(nameTimerRef.current);
    };
  }, []);

  if (loading) return <Loader size="large" type={'infinite-spinner'} />;
  if (error || data?.error)
    return (
      <FormFeedback
        feedback={networkErrorMessageObjFactory(error || data.error)}
      />
    );

  return (
    <>
      <Form
        initialValues={{
          name: data.name,
          category: data.category,
          tags: data.tags?.map(tag => toTitleCase(tag)),
          file_size_KB: Math.round(data.file_size / 1024),
          file_extension: data.file_extension,
        }}
        onSubmit={handleSubmit}
        render={formRenderProps => (
          <FormElement className={classes.formWidth}>
            <fieldset className={'k-form-fieldset'}>
              <legend>
                <Typography variant="body1" color="primary">
                  Update Document Properties
                </Typography>
              </legend>

              {formFeedback && (
                <FormFeedback
                  feedback={formFeedback}
                  feedbackRef={feedbackRef}
                />
              )}

              <Field
                name={'name'}
                id={'name'}
                component={FormFloatingInput}
                label={'Document Name'}
                validator={nameValidator}
                className={classes.formWidth}
                onChange={() => handleNameChange(formRenderProps)}
              />

              <Field
                name={'category'}
                id={'category'}
                component={FormFloatingComboBox}
                label={'Category'}
                data={editPermissions}
                validator={categoryValidator}
              />
              <Field
                name={'tags'}
                id={'tags'}
                component={FormFloatingMultiSelect}
                label={'Tags'}
                formOnChange={formRenderProps.onChange}
                data={tags}
                allowCustom={true}
                allowDuplicates={false}
              />
            </fieldset>
            <div className="k-form-buttons">
              <Button
                variant="contained"
                color="primary"
                disabled={!formRenderProps.allowSubmit || updateLoading}
                type="submit"
              >
                Update
              </Button>
            </div>

            <Box mt={5} className={''}></Box>
            <legend>
              <Typography variant="body1" color="primary">
                Update Document File
              </Typography>
            </legend>

            {formFeedbackFile && <FormFeedback feedback={formFeedbackFile} />}

            <fieldset className={'k-form-fieldset'}>
              <Box className={classes.formWidth}>
                <FileInput
                  setFormFeedback={setFormFeedbackFile}
                  token={token}
                  id={id}
                  formRenderProps={formRenderProps}
                  lastUpdate={lastUpdate || data}
                  setLastUpdate={setLastUpdate}
                />
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    name={'file_size_KB'}
                    component={FormFloatingInput}
                    label={'File Size in KB'}
                    type={'number'}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name={'file_extension'}
                    component={FormFloatingInput}
                    label={'File Extension'}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </FormElement>
        )}
      />
    </>
  );
}

export default UpdateDocumentForm;
