import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// local
import { basicValidator } from '../../components/form-validators';
import { FormInput } from '../../components/form-components';

const AvailabilityFields = ({ initialValues }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2">
          Enter your availability for each day. Example: 8am-5pm. Type N/A if
          you are unavailable for a specific day.
        </Typography>
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Field
          name={'avail_monday'}
          component={FormInput}
          label={'Monday'}
          id={'avail_monday'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Field
          name={'avail_tuesday'}
          component={FormInput}
          label={'Tuesday'}
          id={'avail_tuesday'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Field
          name={'avail_wednesday'}
          component={FormInput}
          label={'Wednesday'}
          id={'avail_wednesday'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Field
          name={'avail_thursday'}
          component={FormInput}
          label={'Thursday'}
          id={'avail_thursday'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Field
          name={'avail_friday'}
          component={FormInput}
          label={'Friday'}
          id={'avail_friday'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Field
          name={'avail_saturday'}
          component={FormInput}
          label={'Saturday'}
          id={'avail_saturday'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Field
          name={'avail_sunday'}
          component={FormInput}
          label={'Sunday'}
          id={'avail_sunday'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
    </>
  );
};

export default AvailabilityFields;
