import { Field } from '@progress/kendo-react-form';
import Grid from '@material-ui/core/Grid';

// local
import {
  basicValidator,
  phoneValidator,
  ssnValidator,
} from '../../components/form-validators';
import {
  FormInput,
  FormDatePicker,
  FormMaskedTextBox,
} from '../../components/form-components';
import { emailValidator } from '../../utils/validators';

const MainApplicationFields = ({ continueApplication, initialValues }) => {
  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          id={'firstname'}
          name={'firstname'}
          component={FormInput}
          label={'First Name'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          id={'middlename'}
          name={'middlename'}
          component={FormInput}
          label={'Middle Name'}
          optional={true}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          id={'lastname'}
          name={'lastname'}
          component={FormInput}
          label={'Last Name'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Field
          name={'date_available'}
          component={FormDatePicker}
          label={'Date Available'}
          id={'date_available'}
          validator={basicValidator}
          disabled={!!initialValues}
        />
      </Grid>
      {!continueApplication && (
        <>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'email'}
              component={FormInput}
              label={'Email'}
              id={'email'}
              validator={emailValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'ssn'}
              component={FormMaskedTextBox}
              mask="000-00-0000"
              label={'Social Security Number'}
              id={'ssn'}
              validator={ssnValidator}
              disabled={!!initialValues}
            />
          </Grid>
        </>
      )}

      {continueApplication && (
        <>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'email'}
              component={FormInput}
              label={'Email'}
              id={'email'}
              validator={emailValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              name={'ssn'}
              component={FormMaskedTextBox}
              mask="000-00-0000"
              label={'Social Security Number'}
              id={'ssn'}
              validator={ssnValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              id={'home_phone'}
              name={'home_phone'}
              label={'Home Phone'}
              component={FormMaskedTextBox}
              mask="(000) 000-0000"
              validator={phoneValidator}
              disabled={!!initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Field
              id={'expected_starting_rate'}
              name={'expected_starting_rate'}
              label={'Expected Starting Rate'}
              component={FormInput}
              disabled={!!initialValues}
              validator={basicValidator}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default MainApplicationFields;
