import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

// local imports
import ApiAgent from "../../utils/apiAgent";
import { networkErrorMessageObjFactory, FormFeedback } from "../../utils/userFeedback";
import login from "../../redux/actions/user/login";
import { usernameValidator, passwordValidator } from "../form-validators";
import { FormFloatingInput } from "../form-components";
import resetNext from "../../redux/actions/app/resetNext";

const formStyle = { maxWidth: 650, width: "100%" };

const useStyles = makeStyles(
  (theme) => ({
    buttonDiv: {
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(0),
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
      },
    },
    loginButton: {
      marginLeft: theme.spacing(2),
    },
  }),
  { classNamePrefix: "wp" }
);

/**
 * LoginForm
 * @returns LoginForm Component
 */

function LoginForm() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formFeedback, setFormFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const next = useSelector((state) => state.app.next);

  useEffect(() => {
    async function processSso() {
      setLoading(true);
      const resp = await ApiAgent.loginSso(ssoIdToken);
      setLoading(false);
      if (!resp.error) {
        dispatch(login(resp.token, resp.user));
        if (next) {
          history.push(next);
          dispatch(resetNext());
        }
      } else {
        setFormFeedback(networkErrorMessageObjFactory(resp.error, true, true));
      }
    }

    // if an sso token is found in the session storage, use to to login
    const ssoIdToken = sessionStorage.getItem("sso_idToken");
    if (ssoIdToken) processSso().catch(console.error);
  }, [dispatch, history, next]);

  const ssoCheck = useCallback(async (formData) => {
    const { username } = formData;
    setLoading(true);
    const resp = await ApiAgent.ssoSupported({ username });
    setLoading(false);
    if (resp.error) {
      setFormFeedback(networkErrorMessageObjFactory(resp.error, true));
    } else {
      if (resp.ssoSupported === true) {
        window.location = "/loginsso/";
      } else {
        setShowPassword(true);
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      const { username, password } = formData;
      setLoading(true);
      const resp = await ApiAgent.login({ username, password });
      setLoading(false);
      if (resp.error) {
        setFormFeedback(networkErrorMessageObjFactory(resp.error, true));
      } else {
        dispatch(login(resp.token, resp.user));
        if (next) {
          history.push(next);
          dispatch(resetNext());
        }
      }
    },
    [dispatch, history, next]
  );

  const handleCancel = useCallback(async (formData) => {
    setShowPassword(false);
    setFormFeedback(null);
    formData.username = "";
    formData.password = "";
  }, []);

  return (
    <Form
      onSubmit={showPassword ? handleSubmit : ssoCheck}
      render={(formRenderProps) => (
        <FormElement style={formStyle}>
          <fieldset className={"k-form-fieldset"}>
            {formFeedback && <FormFeedback feedback={formFeedback} />}
            <Box mb={2}>
              <Field
                name={"username"}
                id={"username"}
                component={FormFloatingInput}
                label={"Username"}
                validator={usernameValidator}
                autoComplete={"username"}
              />
            </Box>

            {showPassword && (
              <Box mb={2}>
                <Field
                  name={"password"}
                  id={"password"}
                  component={FormFloatingInput}
                  label={"Password"}
                  validator={passwordValidator}
                  type={"password"}
                  autoComplete={"current-password"}
                />
              </Box>
            )}
          </fieldset>
          <Box className={classes.buttonDiv} display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              variant="contained"
              type="button"
              onClick={handleCancel}
              className={showPassword ? classes.loginButton : "k-hidden"}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.loginButton}
              disabled={!formRenderProps.allowSubmit || loading}
            >
              Login
            </Button>
          </Box>
        </FormElement>
      )}
    />
  );
}

export default LoginForm;
