import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';
import { decode } from 'jsonwebtoken';
import { SiteHeader } from './SiteHeader/SiteHeader.jsx';
import { Routes as AppRoutes } from '../../routes/appRoutes.js';

const items = () => [
  { text: 'Dashboard', icon: 'k-i-grid', route: AppRoutes.dashboard },
  { text: 'My Courses', icon: 'k-i-check-outline', route: AppRoutes.myCourses },
  { text: 'Documents', icon: 'k-i-txt', route: AppRoutes.documents },
  { separator: true, accessGroup: 'WP-Course-Admin' },
  {
    text: 'Users',
    icon: 'k-i-tell-a-friend',
    route: AppRoutes.adminUsers,
    accessGroup: 'WP-Course-Admin',
  },
  {
    text: 'Course Admin',
    icon: 'k-i-check-circle',
    route: AppRoutes.adminCourses,
    accessGroup: 'WP-Course-Admin',
  },
  {
    text: 'Reports',
    icon: 'k-i-information',
    route: AppRoutes.reports,
    accessGroup: 'WP-Course-Admin',
  },
  {
    text: 'Applications',
    icon: 'k-i-txt',
    route: AppRoutes.applications,
    accessGroup: 'WP-Application-Admin',
  },
];

function getUserMenuItems(token) {
  return items().filter(x => isInGroup(token, x.accessGroup));
}

function isInGroup(token, accessGroup) {
  if (!token) return false;
  if (!accessGroup) return true;
  const tokenData = decode(token);
  return tokenData?.groups.some(x => x === accessGroup);
}

// get header text for pages not accessible from main drawer navigation
const getHeaderTextFromPath = pathname => {
  switch (pathname) {
    case AppRoutes.index: {
      return 'Login';
    }
    case AppRoutes.employmentApplication: {
      return 'Employment Application';
    }
    case AppRoutes.continueApplication: {
      return 'Employment Application';
    }
    default: {
      return null;
    }
  }
};

function SiteLayout(props) {
  const token = useSelector(state => state.user.token);
  const [expanded, setExpanded] = useState(window.innerWidth >= 768);
  const [isSmallerScreen, setIsSmallerScreen] = useState(
    window.innerWidth < 768
  );
  const userMenuItems = useMemo(() => getUserMenuItems(token), [token]);

  useEffect(() => {
    window.addEventListener('resize', resizeWindow);

    return () => {
      window.removeEventListener('resize', resizeWindow);
    };

    function resizeWindow() {
      // resizing from smaller to larger
      if (isSmallerScreen && window.innerWidth >= 768) {
        setExpanded(true); // show menu
      }

      // resizing from larger to smaller
      if (!isSmallerScreen && window.innerWidth < 768) {
        setExpanded(false); // hide menu
      }

      setIsSmallerScreen(window.innerWidth < 768);
    }
  }, [isSmallerScreen]);

  function handleClick() {
    setExpanded(e => !e);
  }

  function handleSelect(e) {
    setExpanded(!isSmallerScreen);
    props.history.push(e.itemTarget.props.route);
  }

  function getSelectedItem(pathName) {
    let currentPath = items().find(item => item.route === pathName);
    if (currentPath?.text) {
      return currentPath.text;
    }

    return null;
  }

  let selected = getSelectedItem(props.location.pathname);

  // todo - need to come up with a better way to determine if the menu should be shown
  const hideMenu =
    !token ||
    props.location.pathname.includes(AppRoutes.takeCourse) ||
    props.location.pathname.includes(AppRoutes.employmentApplication) ||
    props.location.pathname.includes(AppRoutes.continueApplication);

  return (
    <React.Fragment>
      <SiteHeader
        onButtonClick={handleClick}
        page={
          token && selected
            ? selected
            : getHeaderTextFromPath(props.location.pathname)
        }
        hideMenu={hideMenu}
        token={token}
        pathname={props.location.pathname}
      />
      {!hideMenu && (
        <Drawer
          expanded={expanded}
          animation={{ duration: 100 }}
          items={userMenuItems.map(item => ({
            icon: item.icon,
            separator: item.separator,
            route: item.route,
            text: item.text,
            selected: item.text === selected,
          }))}
          position="start"
          mode={isSmallerScreen ? 'overlay' : 'push'}
          mini={isSmallerScreen ? false : true}
          onOverlayClick={handleClick}
          onSelect={handleSelect}
          className="SiteLayout-drawer"
        >
          <DrawerContent>{props.children}</DrawerContent>
        </Drawer>
      )}
      {hideMenu && props.children}
    </React.Fragment>
  );
}

export default withRouter(SiteLayout);
