import axios from 'axios';

import ApiAgent, { Routes as apiRoutes } from '../../utils/apiAgent';
import updateUser from './user/updateUser';

// THUNK CREATORS

export const updateTasksCompleted =
  (user_id, course_id, taskNumber, token, taskData) => async dispatch => {
    try {
      const url = apiRoutes.userTask(user_id, course_id);
      const { data } = await axios.post(url, {
        task_completed: taskNumber,
        _token: token,
        taskData,
      });
      if (data?.user) dispatch(updateUser(data.user));
    } catch (error) {
      console.error(error);
    }
  };

export const resetTasksCompleted =
  (user_id, course_id, token) => async dispatch => {
    try {
      const url = apiRoutes.userTask(user_id, course_id);
      const { data } = await axios.delete(url, {
        data: {
          _token: token,
        },
      });
      if (data?.user) dispatch(updateUser(data.user));
    } catch (error) {
      console.error(error);
    }
  };

export const updateUserThunkCreator = (userId, editData) => async dispatch => {
  try {
    const data = await ApiAgent.patchUser(userId, editData);
    if (data?.user) dispatch(updateUser(data.user));
  } catch (error) {
    console.error(error);
  }
};
