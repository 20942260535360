/** Workplace App reducer. */

import { SET_NEXT, RESET_NEXT } from '../actions/types';

const INIT_STATE = {
  next: null,
};

function app(state = INIT_STATE, action) {
  switch (action.type) {
    case RESET_NEXT:
      return { ...INIT_STATE };

    case SET_NEXT:
      return {
        ...state,
        next: action.payload.next,
      };

    default:
      return state;
  }
}

export default app;
