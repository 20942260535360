import Typography from '@material-ui/core/Typography';
import { Button } from '@progress/kendo-react-buttons';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Routes as appRoutes } from '../../routes/appRoutes';
import UFO from '../../assets/Error_TV.svg';

// local imports

const useStyles = makeStyles(theme => ({
  overlay: {
    height: 'calc(100vh - 80px)',
    backgroundImage: `url(${UFO})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

/**
 * NotFoundPage
 * @returns NotFoundPage View Component
 */

function NotFoundPage() {
  return (
    <Box
      display="flex"
      className={useStyles().overlay}
      justifyContent="space-around"
      alignItems="center"
      flexDirection="column"
    >
      <Box />
      <Box />
      <section>
        <Box mb={2}>
          <Typography component="h1" variant="subtitle1">
            Page Not Found
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="textPrimary" align="center">
            <Link to={appRoutes.index} className="text-decoration-none">
              <Button primary={true}>Go Home</Button>
            </Link>
          </Typography>
        </Box>
      </section>
    </Box>
  );
}

export default NotFoundPage;
