import { React, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Box, Typography } from "@material-ui/core";

// local
import UserCourseCard from "../../components/CourseCards/UserCourseCard";
import DocumentCard from "../../components/DocumentCard/DocumentCard";
import SearchDocumentForm from "../../components/SearchDocumentForm/SearchDocumentForm";
import LinkCard from "../../components/LinkCard/LinkCard";
import { Routes as AppRoutes } from "../../routes/appRoutes";
import ApiAgent from "../../utils/apiAgent";

import links from "../../testing/MockData/Links.json";

const useStyles = makeStyles(
  (theme) => ({
    section: {
      margin: 10,
      [theme.breakpoints.up("md")]: {
        margin: 25,
      },
    },
  }),
  { classNamePrefix: "wp" }
);

function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const [favoriteDocuments, setFavoriteDocuments] = useState(null);
  const params = useRef({ _token: token });

  const courses = (user.courses ?? []).filter((x) => !x.complete);

  // get favorites data
  useEffect(() => {
    const getFavDocs = async () => {
      const promiseArray = [];
      for (let fav of user.favorites) {
        promiseArray.push(ApiAgent.getDocMetadata(fav, params.current));
      }
      const favorites = await Promise.all(promiseArray);
      setFavoriteDocuments(favorites);
    };

    if (user.favorites) getFavDocs();
  }, [user.favorites]);

  const gotoSearch = useCallback(
    () => history.push(AppRoutes.documents),
    [history]
  );

  return (
    <div id="Dashboard">
      <Card className={classes.section}>
        <CardHeader title="Common Links" />
        <CardContent>
          <Grid
            container
            spacing={3}
            direction="row"
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            {links.map((linkInfo, index) => (
              <Grid item xs={"auto"} key={index}>
                <LinkCard linkInfo={linkInfo}></LinkCard>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.section}>
        <CardHeader title="Assigned Courses" />
        <CardContent>
          <Grid
            container
            spacing={3}
            direction="row"
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            {courses.map((userCourse) => (
              <Grid item xs={"auto"} key={userCourse.course_id}>
                <UserCourseCard userCourse={userCourse} />
              </Grid>
            ))}
            {courses.length === 0 && (
              <Box m={4}>
                <Typography>You have no assigned courses.</Typography>
              </Box>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.section}>
        <CardHeader
          title="Favorite Documents"
          action={<SearchDocumentForm callback={gotoSearch} />}
        />
        <CardContent>
          <Grid
            container
            spacing={3}
            direction="row"
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            {favoriteDocuments?.map((document) => (
              <Grid item xs={"auto"} key={document.id}>
                <Link to={AppRoutes.makeViewDocUrl(document.id)}>
                  <DocumentCard document={document}></DocumentCard>
                </Link>
              </Grid>
            ))}
            {(favoriteDocuments == null || favoriteDocuments?.length === 0) && (
              <Box m={4}>
                <Typography>You have no favorite documents.</Typography>
              </Box>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default Dashboard;
