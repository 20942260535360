import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import UserCourseCard from "../../../components/CourseCards/UserCourseCard";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    section: {
      margin: 10,
      [theme.breakpoints.up("md")]: {
        margin: 25,
      },
    },
  }),
  { classNamePrefix: "wp" }
);

function MyCourses() {
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);
  const assignedUserCourses = (user.courses ?? []).filter((el) => !el.complete);
  const completedUserCourses = (user.courses ?? []).filter((el) => el.complete);

  return (
    <div className="courses-page">
      <Card className={classes.section}>
        <CardHeader title="Assigned Courses" />
        <CardContent>
          <Grid
            container
            spacing={3}
            direction="row"
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            {assignedUserCourses.map((userCourse) => (
              <Grid item xs={"auto"} key={userCourse.course_id}>
                <UserCourseCard
                  key={userCourse.course_id}
                  userCourse={userCourse}
                />
              </Grid>
            ))}
            {assignedUserCourses.length === 0 && (
              <Box m={4}>
                <Typography>You have no assigned courses.</Typography>
              </Box>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.section}>
        <CardHeader title="Completed Courses" />
        <CardContent>
          <Grid
            container
            spacing={3}
            direction="row"
            justify={"flex-start"}
            alignItems={"flex-start"}
          >
            {completedUserCourses.map((userCourse) => (
              <Grid item xs={"auto"} key={userCourse.course_id}>
                <UserCourseCard
                  key={userCourse.course_id}
                  userCourse={userCourse}
                />
              </Grid>
            ))}
            {completedUserCourses.length === 0 && (
              <Box m={4}>
                <Typography>You have not completed any courses yet.</Typography>
              </Box>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default MyCourses;
