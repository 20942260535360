import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SearchBar from 'material-ui-search-bar';

// local
import setQuery from '../../redux/actions/search/setQuery';
import resetSearch from '../../redux/actions/search/resetSearch';

/**
 * SearchDocumentForm
 * @returns SearchDocumentForm Component
 *
 * This searchbar form sets search query in Redux state and calls callback
 * if given.
 */

function SearchDocumentForm({
  setSearchResults = () => {},
  previousQuery,
  callback,
  ...others
}) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    q: previousQuery || '',
  });

  const handleSubmit = useCallback(async () => {
    dispatch(setQuery(formData.q));
    if (callback) callback();
  }, [dispatch, formData.q, callback]);

  const handleCancelSearch = useCallback(() => {
    setSearchResults(null);
    setFormData({ q: '' });
    dispatch(resetSearch());
  }, [dispatch, setSearchResults]);

  const handleChange = useCallback(
    newValue =>
      setFormData(formData => ({
        ...formData,
        q: newValue,
      })),
    []
  );

  return (
    <SearchBar
      value={formData.q}
      onChange={handleChange}
      onRequestSearch={handleSubmit}
      onCancelSearch={handleCancelSearch}
      {...others}
    />
  );
}

export default SearchDocumentForm;
