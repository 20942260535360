/**
 * Class to hold methods for convenience of calling API endpoints.
 * Obscures error handling.
 */

import axios from 'axios';
import { appInsights } from '../AppInsights';

export const BASE_URL =
  window.workplaceSettings?.apiUrl ||
  process.env.REACT_APP_BASE_URL ||
  'https://wp-funcapp-dev.azurewebsites.net/api';

class ApiAgent {
  // Auth
  static async login(userData) {
    try {
      const resp = await axios.post(Routes.login, userData);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.login',
        },
      });
      return { error };
    }
  }

  static async ssoSupported(userData) {
    try {
      const resp = await axios.post(Routes.ssoSupported, userData);
      console.log(resp);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.ssoSupported',
        },
      });
      return { error };
    }
  }

  static async loginSso(idToken) {
    try {
      const resp = await axios.post(Routes.loginSso, { token: idToken });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.loginSso',
        },
      });
      return { error };
    }
  }

  static async refreshToken(token) {
    try {
      const resp = await axios.post(Routes.refreshToken, {
        _token: token,
      });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.refreshToken',
        },
      });
      return { error };
    }
  }

  // Document
  static async getDocument(id, params) {
    try {
      const resp = await axios.get(Routes.getDocument(id), { params });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.getDocument',
        },
      });
      return { error };
    }
  }

  static async uploadDocMetadata(uploadData) {
    try {
      const resp = await axios.post(Routes.uploadDocMetadata, uploadData);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.uploadDocMetadata',
        },
      });
      return { error };
    }
  }

  static async getDocMetadata(id, params) {
    const url = Routes.documentMetadata(id);
    try {
      const resp = await axios.get(url, { params });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.getDocMetadata',
        },
      });
      return { error };
    }
  }
  static async updateDocMetadata(editData) {
    const url = Routes.documentMetadata(editData.id);
    try {
      const resp = await axios.patch(url, editData);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.updateDocMetadata',
        },
      });
      return { error };
    }
  }

  static async searchDocMetadata(params) {
    try {
      const resp = await axios.get(Routes.searchDocMetadata, {
        params,
      });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.searchDocMetadata',
        },
      });
      return { error };
    }
  }

  static async deleteDocument(id, deleteData) {
    try {
      const resp = await axios.delete(Routes.deleteDocument(id), {
        data: deleteData,
      });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.deleteDocument',
        },
      });
      return { error };
    }
  }

  // Course
  static async getCourse(id, version, params) {
    try {
      const resp = await axios.get(Routes.getCourse(id, version), { params });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.getCourse',
        },
      });
      return { error };
    }
  }

  // Course Admin
  static async getCourseAdmin(id, params) {
    try {
      const resp = await axios.get(Routes.courseAdmin(id), { params });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.getCourseAdmin',
        },
      });
      return { error };
    }
  }

  static async pushCourseAdmin(id, editData) {
    try {
      const resp = await axios.post(Routes.courseAdmin(id), editData);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.pushCourseAdmin',
        },
      });
      return { error };
    }
  }

  static async putCourseAdmin(id, editData) {
    try {
      const resp = await axios.put(Routes.courseAdmin(id), editData);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.putCourseAdmin',
        },
      });
      return { error };
    }
  }

  static async deleteCourse(id, deleteData) {
    try {
      const resp = await axios.delete(Routes.courseAdmin(id), {
        data: deleteData,
      });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.deleteCourse',
        },
      });
      return { error };
    }
  }

  // Assign Course
  static async assignCourse(user_id, course_id, token, update) {
    try {
      let resp;
      if (update) {
        // add or replace course assignment
        resp = await axios.put(Routes.assignCourse(user_id, course_id), {
          _token: token,
        });
      } else {
        // add if absent course assignment
        resp = await axios.post(Routes.assignCourse(user_id, course_id), {
          _token: token,
        });
      }

      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.assignCourse',
        },
      });
      return { error };
    }
  }

  // User
  static async getUsers(query) {
    try {
      const resp = await axios.get(Routes.getUsers(query));
      const data = resp.data.map((x) => {
        return { ...x, timestamp: new Date(x._ts * 1000) };
      });
      return data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.getUsers',
        },
      });
      return { error };
    }
  }

  static async createUser(user_id, user) {
    try {
      const resp = await axios.post(Routes.createUser(user_id), user);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.createUser',
        },
      });
      return { error };
    }
  }

  static async patchUser(id, editData) {
    try {
      const resp = await axios.patch(Routes.editUser(id), editData);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.patchUser',
        },
      });
      return { error };
    }
  }

  // Reports
  static async getCourseAssignments(token, courseName) {
    try {
      const resp = await axios.get(
        Routes.getCourseAssignments(token, encodeURIComponent(courseName))
      );
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.getCourseAssignments',
        },
      });
      return { error };
    }
  }

  // Applications
  static async submitApplication(data, fileUid) {
    try {
      const url = Routes.applicationId(fileUid);
      const resp = await axios.post(url, data);
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.submitApplication',
        },
      });
      return { error };
    }
  }

  static async getResume(id, params) {
    try {
      const url = Routes.getResume(id);
      const resp = await axios.get(url, { params });
      return resp.data;
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          name: 'ApiAgent.getResume',
        },
      });
      return { error };
    }
  }
}

/**
 * Class to construct API endpoint URLs.
 */

export class Routes {
  static get login() {
    return `${BASE_URL}/login`;
  }
  static get ssoSupported() {
    return `${BASE_URL}/sso-supported`;
  }
  static get loginSso() {
    return `${BASE_URL}/login-sso`;
  }
  static get refreshToken() {
    return `${BASE_URL}/token-refresh`;
  }
  static get searchDocMetadata() {
    return `${BASE_URL}/document-metadata`;
  }
  static documentMetadata(id) {
    return `${BASE_URL}/document-metadata/${id}`;
  }
  static getDocument(id) {
    return `${BASE_URL}/document-blob/${id}`;
  }
  static uploadDoc(id, token, category) {
    const queryString = `?_token=${token}&category=${category}`;
    return `${BASE_URL}/document-blob/${id}${queryString}`;
  }
  static get uploadDocMetadata() {
    return `${BASE_URL}/document-metadata`;
  }
  static deleteDocument(id) {
    return `${BASE_URL}/document/${id}`;
  }
  static getCourse(id, version) {
    return `${BASE_URL}/course/${id}/${version}`;
  }
  static get getCourses() {
    return `${BASE_URL}/course`;
  }
  static courseAdmin(id) {
    return `${BASE_URL}/course-admin/${id}`;
  }
  static assignCourse(user_id, course_id) {
    return `${BASE_URL}/user/${user_id}/assign-course/${course_id}`;
  }
  static userTask(user_id, course_id) {
    return `${BASE_URL}/user/${user_id}/user-task/${course_id}`;
  }
  static getUsers(query) {
    return query
      ? `${BASE_URL}/users?query=${query}`
      : `${BASE_URL}/users`;
  }
  static getUser(id) {
    return `${BASE_URL}/user/${id}`;
  }
  static getCourseAssignments(token, courseName) {
    return `${BASE_URL}/course-assignments/${
      courseName !== 'All' ? courseName : ''
    }?_token=${token}`;
  }
  static get getPastDueCourses() {
    return `${BASE_URL}/courses/past-due`;
  }
  static createUser(id) {
    return `${BASE_URL}/user/${id}`;
  }
  static editUser(id) {
    return `${BASE_URL}/user/${id}`;
  }
  static get getApplications() {
    return `${BASE_URL}/application`;
  }
  static applicationId(id) {
    return `${BASE_URL}/application/${id}`;
  }
  static getResume(id) {
    return `${BASE_URL}/resume/${id}`;
  }
  static getAccessGroups() {
    return `${BASE_URL}/access-groups`;
  }
  static get return200() {
    return `${BASE_URL}/Return-200`;
  }
}

export default ApiAgent;
