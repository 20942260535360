import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';

import congratulations from '../../../assets/congratulations.jpg';
import { useDispatch } from 'react-redux';
import { resetTasksCompleted } from '../../../redux/actions/thunkCreators';

const useStyles = makeStyles(
  theme => ({
    cardImage: {
      width: '100%',
      maxWidth: 400,
    },
    completeTaskHeader1: {
      fontSize: 'calc(1vw + 12px)',
      marginTop: 20,
    },
    completeTaskHeader2: {
      fontSize: 'calc(1.5vw + 12px)',
    },
    completeTaskDetails: {
      border: '2px solid gray',
      minWidth: 200,
      padding: 10,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 20,
      display: 'inline-block',
      textAlign: 'left',
    },
  }),
  { classNamePrefix: 'wp' }
);

const TasksComplete = ({
  user,
  userCourse,
  passingScore,
  token,
  setTaskNumber,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleResetCourse = async () => {
    if (!token) return;
    dispatch(resetTasksCompleted(user.id, userCourse.course_id, token));
    setTaskNumber(0);
  };

  if (!Number.isFinite(userCourse.score)) return null;

  // get completed date from record or current time
  const dateCompleted = userCourse.completed_date
    ? DateTime.fromISO(userCourse.completed_date)
    : DateTime.now();
  const userPassedCourse = userCourse.score >= passingScore;

  return (
    <div style={{ textAlign: 'center' }}>
      {userPassedCourse && (
        <>
          <img
            src={congratulations}
            alt="Congratulations"
            className={classes.cardImage}
          />
          <Typography
            variant="h2"
            className={classes.completeTaskHeader1}
            gutterBottom
          >
            You have completed
          </Typography>
        </>
      )}

      <Typography
        variant="h1"
        className={classes.completeTaskHeader2}
        gutterBottom
      >
        {userCourse.title}
      </Typography>

      {!userPassedCourse && (
        <>
          <Typography
            variant="h2"
            className={classes.completeTaskHeader1}
            gutterBottom
          >
            Sorry, you did not pass the course
          </Typography>
          <Typography
            variant="h2"
            className={classes.completeTaskHeader1}
            style={{ transform: 'rotateZ(90deg)' }}
            gutterBottom
          >
            :(
          </Typography>
        </>
      )}
      <Box className={classes.completeTaskDetails}>
        <Typography variant="body1">
          Name: {`${user.firstname} ${user.lastname}`}
        </Typography>

        <Typography variant="body1">Score: {userCourse.score}%</Typography>

        <Typography variant="body1">
          Date: {dateCompleted.toLocaleString(DateTime.DATETIME_MED)}
        </Typography>
        <Typography variant="body1">
          Course Version: {userCourse.course_version}
        </Typography>
      </Box>
      <Box marginTop={4} displayPrint="none">
        <Typography variant="body2">
          {userPassedCourse && (
            <Button
              variant="contained"
              onClick={() => {
                window.print();
                return false;
              }}
            >
              Print this page for your records
            </Button>
          )}
          {!userPassedCourse && (
            <Button variant="contained" onClick={handleResetCourse}>
              Take Course Again
            </Button>
          )}
        </Typography>
      </Box>
    </div>
  );
};

export default TasksComplete;
