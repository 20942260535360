import { reactPlugin } from './AppInsights';
import { AppInsightsErrorBoundary, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import '@progress/kendo-theme-material/dist/all.css';

// local imports
import AppRoutes from './routes/appRoutes';
import AppError from './views/AppError/AppError';
import './App.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3A6E8F',
      light: '#c4d4dd',
      dark: '#255172',
      contrastText: '#fff',
    },
    secondary: {
      main: '#e72432',
      light: '#f8bdc2',
      dark: '#dd161f',
      contrastText: '#000',
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
  },
});

function App() {
  return (
    <AppInsightsErrorBoundary
      onError={() => <AppError />}
      appInsights={reactPlugin}
    >
      <ThemeProvider theme={theme}>
        <div className="App">
          <AppRoutes />
        </div>
      </ThemeProvider>
    </AppInsightsErrorBoundary>
  );
}

export default withAITracking(reactPlugin, App);
