import { useRef, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import * as colors from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';

// local
import EditHistoryGrid from './EditHistoryGrid/EditHistoryGrid';
import { fileIconDictionary } from '../../../utils/documentTypes.js';
import { processMetatdata } from '../../../utils/dataTransformations';
import { Routes as AppRoutes } from '../../../routes/appRoutes';
import { updateUserThunkCreator } from '../../../redux/actions/thunkCreators';

const useStyles = makeStyles(
  theme => ({
    card: {
      marginTop: '15px',
      padding: '1rem 1rem 0rem 1rem',
      maxWidth: '92vw',
      width: '100%',
      boxSizing: 'border-box',
    },
    avatar: {
      backgroundColor: colors.teal[600],
    },
    filename: {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(0),
      },
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: '1px lightgray solid',
    },
    fadedSmall: {
      fontSize: '13px',
      color: '#656565',
    },
  }),
  { classNamePrefix: 'wp' }
);

function MetadataCard({ fileMetadata, downloadButton }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const user = useSelector(state => state.user.user);
  const token = useSelector(state => state.user.token);
  const [isFavorite, setIsFavorite] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // process metadata for display
  const processedMetadata = useMemo(
    () => processMetatdata(fileMetadata),
    [fileMetadata]
  );

  const cardData = {
    headerTitle: processedMetadata.name,
    headerSubtitle: processedMetadata.category,
    headerSubtitle2: processedMetadata.tags,
    lastModified: processedMetadata.last_modified,
    file_size_KB: Math.round(processedMetadata.file_size / 1024),
    extension: processedMetadata.file_extension,
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditDocument = () => {
    history.push(AppRoutes.makeUpdateUrl(fileMetadata.id));
  };

  // update isFavorite state when user.favorites changes
  useEffect(() => {
    setIsFavorite(user.favorites?.includes(fileMetadata.id));
  }, [user.favorites, fileMetadata.id]);

  // add or remove document as user favorite
  const handleFavorite = async () => {
    let updatedFavorites;
    if (isFavorite) {
      updatedFavorites = user.favorites.filter(el => el !== fileMetadata.id);
    } else {
      updatedFavorites = user.favorites
        ? [...user.favorites, fileMetadata.id]
        : [fileMetadata.id];
    }
    dispatch(
      updateUserThunkCreator(user.id, {
        favorites: updatedFavorites,
        _token: token,
      })
    );
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="file symbol"
            className={classes.avatar}
            role="img"
          >
            {fileIconDictionary(processedMetadata.file_extension)}
          </Avatar>
        }
        action={
          <>
            <IconButton
              aria-label="settings"
              onClick={handleMenuOpen}
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEditDocument}>Edit</MenuItem>
            </Menu>
          </>
        }
        title={cardData.headerTitle}
        titleTypographyProps={{ variant: 'h4', component: 'h1' }}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Typography
                variant="subtitle1"
                component="h2"
                color="textSecondary"
              >
                Category: {cardData.headerSubtitle}
              </Typography>
              {cardData.headerSubtitle2 && (
                <Box mt={0.5}>
                  <Typography
                    variant="subtitle1"
                    component="h2"
                    color="textSecondary"
                  >
                    Tags: {cardData.headerSubtitle2}
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              container
              direction="column"
              justify="flex-end"
              alignItems="flex-end"
            >
              {downloadButton}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Box>
          <Tooltip title="Favorite">
            <IconButton
              aria-label="add to favorites"
              onClick={handleFavorite}
              role="button"
            >
              <FavoriteIcon color={isFavorite ? 'secondary' : 'action'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit History">
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <span className={'k-icon k-i-track-changes'} />
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography variant="body2" color="textSecondary" component="p">
          <Box component={'span'} mr={3}>
            {cardData.file_size_KB} KB
          </Box>
          <Box component={'span'} mr={2}>
            {cardData.extension}
          </Box>
        </Typography>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box mb={2}>
            <Typography component="h5" variant="subtitle1">
              Edit History
            </Typography>
          </Box>
          <EditHistoryGrid metadata={processedMetadata} gridRef={gridRef} />
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default MetadataCard;
