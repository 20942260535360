import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

// local
import ApiAgent from '../../../../../utils/apiAgent';
import UploadDocumentFields from './UploadDocumentFields/UploadDocumentFields';
import UpdateDocumentFields from './UpdateDocumentFields/UpdataeDocumentFields';
import { FormFeedback } from '../../../../../utils/userFeedback';

const useStyles = makeStyles(
  theme => ({
    root: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    legend: {
      marginLeft: theme.spacing(1),
    },
  }),
  { classNamePrefix: 'wp' }
);

function DocumentTaskFields({
  additionalDocumentForm,
  setAdditionalDocumentForm,
  documentFormFeedback,
  setDocumentFormFeedback,
  documentFeedbackRef,
  formRenderPropsRef,
  initialState,
  documentId,
}) {
  const classes = useStyles();
  const token = useSelector(state => state.user.token);
  // document request variables
  const params = useRef({ _token: token });
  const [resp, setResp] = useState(null);
  const [error, setError] = useState(null);

  // if there is a document id get the document metadata for the update doc. form
  useEffect(() => {
    const getDocMetadata = async () => {
      // get document metadata hook
      const resp = await ApiAgent.getDocMetadata(
        documentId || initialState.document_id,
        params.current
      );
      if (resp.error) setError(resp.error);
      else {
        setResp(resp);
        setAdditionalDocumentForm('update');
      }
    };
    if (documentId || initialState.document_id) {
      getDocMetadata();
    }
  }, [documentId, initialState, setAdditionalDocumentForm]);

  const setDocumentId = useCallback(
    id => {
      setDocumentFormFeedback(null);
      formRenderPropsRef.current.onChange('document_id', { value: id });
      setTimeout(() => {
        setDocumentFormFeedback(null);
        setAdditionalDocumentForm('update');
      }, 8000);
    },
    [formRenderPropsRef, setAdditionalDocumentForm, setDocumentFormFeedback]
  );

  if (!additionalDocumentForm) return null;

  return (
    <fieldset className={classes.root}>
      {documentFormFeedback && (
        <FormFeedback
          feedback={documentFormFeedback}
          feedbackRef={documentFeedbackRef}
        />
      )}

      {additionalDocumentForm === 'upload' && (
        <>
          <legend className={'k-form-legend'}>
            <span className={classes.legend}>
              <Typography variant="overline" color="secondary">
                Upload Task Document
              </Typography>
            </span>
          </legend>
          <UploadDocumentFields idCallback={setDocumentId} />
        </>
      )}

      {additionalDocumentForm === 'update' && (
        <>
          <legend className={'k-form-legend'}>
            <span className={classes.legend}>
              <Typography variant="overline" color="secondary">
                Update Task Document
              </Typography>
            </span>
          </legend>
          <UpdateDocumentFields
            id={formRenderPropsRef.current.valueGetter('document_id')}
            error={error}
            loading={!error && !resp}
            data={resp}
          />
        </>
      )}
    </fieldset>
  );
}

export default DocumentTaskFields;
