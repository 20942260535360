/** Functions to transform data as necessary. */

import { DateTime } from 'luxon';

// local
import { toTitleCase } from './userFeedback';

/**
 * Process metadata for display. Date and tags.
 */

export function processMetatdata(metadata) {
  const copy = { ...metadata };
  // make timestamp human readable and save at last_modified property
  copy.last_modified = DateTime.fromISO(copy.last_modified).toLocaleString(
    DateTime.DATETIME_MED
  );
  // Process tags array for display.
  copy.tags = copy.tags?.map(tag => toTitleCase(tag)).join(', ');

  return copy;
}
