/** Root reducer for WorkPlace. Combines sub-reducers. */

import { combineReducers } from 'redux';
import user from './user';
import search from './search';
import app from './app';

export default combineReducers({
  user,
  search,
  app,
});
