import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

// local imports
import ApiAgent from '../../utils/apiAgent';
import setQuery from '../../redux/actions/search/setQuery';
import { FileInput } from './form-components';
import { getUserEditPermissions } from '../../utils/auth/userAuth';
import { tags } from '../form-defaults';
import {
  FormFloatingComboBox,
  FormFloatingMultiSelect,
  FormFloatingInput,
} from '../form-components';
import {
  networkErrorMessageObjFactory,
  documentSuccessMessageObjFactory,
  FormFeedback,
  nameExistsMessageObjFactory,
  scrollFeedbackIntoView,
} from '../../utils/userFeedback';

const useStyles = makeStyles(
  theme => ({
    formWidth: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '82vw',
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

/**
 * UploadDocumentForm
 * @returns UploadDocumentForm Component
 *
 * Note: Metadata portion of form has no validators but will use
 *       sensible defaults. This is to guarantee if file upload has
 *       success metadata upload will have success as well.
 */

function UploadDocumentForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(state => state.user.token);
  const [formFeedback, setFormFeedback] = useState(null);
  const feedbackRef = useRef(null);
  const nameTimerRef = useRef(null);
  const editPermissions = useMemo(() => getUserEditPermissions(token), [token]);

  // search if document with given name already exists using the document search endpoint.
  const searchName = useCallback(
    async name => {
      if (name === '') return;
      // create query for a name that matches name input value.
      const q = `name:"${name}"`;

      const setSearchQuery = () => {
        dispatch(setQuery(q));
      };

      const resp = await ApiAgent.searchDocMetadata({ q, _token: token });

      if (resp.error) {
        setFormFeedback(networkErrorMessageObjFactory(resp.error));
      } else if (resp[0]) {
        // show form feedback about document name collision and pass setSearchQuery
        // which will be called if user clicks link to existing document.
        setFormFeedback(nameExistsMessageObjFactory(name, setSearchQuery));
      }
    },
    [dispatch, token]
  );

  // As name input value changes search for matching document in DB after delay.
  const handleNameChange = useCallback(
    formRenderProps => {
      setFormFeedback(null);
      clearTimeout(nameTimerRef.current);

      nameTimerRef.current = setTimeout(() => {
        searchName(formRenderProps.valueGetter('name'));
      }, 800);
    },
    [searchName]
  );

  // remove timer if component is dismounted.
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(nameTimerRef.current);
    };
  }, []);

  // upload document metadata on submit.
  const handleSubmit = useCallback(
    async formData => {
      const editedFormData = { ...formData };
      // delete display-only data size KB
      delete editedFormData.file_size_KB;
      // edit form data making sure defaults are added if needed
      // as form currently has no validation checks.
      // if category is blank or missing use "General"
      editedFormData.category = editedFormData.category || 'General';
      // if "name" was blank use original filename
      editedFormData.name =
        editedFormData.name === ''
          ? formData.id.match(/^\S+?_(.+)$/)[1]
          : formData.name;

      const resp = await ApiAgent.uploadDocMetadata({
        ...editedFormData,
        _token: token,
      });
      if (resp.error) {
        setFormFeedback(networkErrorMessageObjFactory(resp.error));
      } else {
        setFormFeedback(
          documentSuccessMessageObjFactory(
            'Document uploaded successfully!',
            'upload',
            formData.id
          )
        );
      }
      scrollFeedbackIntoView(feedbackRef);
    },
    [token]
  );

  return (
    <Form
      initialValues={{
        category: 'General',
      }}
      onSubmit={handleSubmit}
      render={formRenderProps => (
        <FormElement className={classes.formWidth}>
          <fieldset className={'k-form-fieldset'}>
            {formFeedback && (
              <FormFeedback feedback={formFeedback} feedbackRef={feedbackRef} />
            )}

            <Field
              name={'name'}
              id={'name'}
              component={FormFloatingInput}
              label={'Document Name'}
              autoComplete={'off'}
              hint={'Leave blank to autofill when file is chosen'}
              onChange={() => handleNameChange(formRenderProps)}
              className={classes.formWidth}
            />

            <Field
              name={'category'}
              id={'category'}
              component={FormFloatingComboBox}
              label={'Category'}
              data={editPermissions}
            />

            <Field
              name={'tags'}
              id={'tags'}
              component={FormFloatingMultiSelect}
              label={'Tags'}
              formOnChange={formRenderProps.onChange}
              data={tags}
              allowCustom={true}
              allowDuplicates={false}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Field
                  name={'file_size_KB'}
                  id={'file_size_KB'}
                  component={FormFloatingInput}
                  label={'File Size in KB'}
                  type={'number'}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name={'file_extension'}
                  id={'file_extension'}
                  component={FormFloatingInput}
                  label={'File Extension'}
                  disabled={true}
                />
              </Grid>
            </Grid>

            <Box my={4} className={classes.formWidth}>
              <FileInput
                formRenderProps={formRenderProps}
                setFormFeedback={setFormFeedback}
                token={token}
                feedbackRef={feedbackRef}
                handleNameChange={handleNameChange}
              />
            </Box>

            <Field name={'id'} component={Input} type={'hidden'} />
            <Field name={'file_size'} component={Input} type={'hidden'} />
          </fieldset>
        </FormElement>
      )}
    />
  );
}

export default UploadDocumentForm;
