import React, { useState, useRef } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Hidden from '@material-ui/core/Hidden';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '@progress/kendo-react-indicators';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

// local
import { AxiosGetHook } from '../../../hooks/axiosHook';
import { Routes as appRoutes } from '../../../routes/appRoutes';
import { Routes as apiRoutes } from '../../../utils/apiAgent';
import DocumentTask from '../../../components/CourseTask/DocumentTask/DocumentTask';
import SignatureTask from '../../../components/CourseTask/SignatureTask/SignatureTask';
import QuizTask from '../../../components/CourseTask/QuizTask/QuizTask';
import VideoTask from '../../../components/CourseTask/VideoTask/VideoTask';
import TasksComplete from '../../../components/CourseTask/TasksComplete/TasksComplete';
import { updateTasksCompleted } from '../../../redux/actions/thunkCreators';
import {
  networkErrorMessageObjFactory,
  FormFeedback,
} from '../../../utils/userFeedback';

import './TakeCourse.scss';

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      height: 'calc(100vh - 80px)',
      position: 'relative',
      paddingTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        paddingTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 25,
      },
    },
    card: {
      margin: 0,
      overflowY: 'auto',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 25,
        marginRight: 25,
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: 25,
        marginRight: 25,
      },
    },
    header: {
      paddingBottom: 0,
    },
    bottomNavigation: {
      justifyContent: 'space-between',
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
    bottomNavigationAction: {
      color: 'white',
    },
    cardContent: {
      height: 'calc(100vh - 135px)',
      marginBottom: 0,
      [theme.breakpoints.up('md')]: {
        height: 'calc(100vh - 250px)',
      },
    },
    loader: {
      marginTop: theme.spacing(26),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(12),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(30),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(36),
      },
    },
  }),
  { classNamePrefix: 'wp' }
);

function TakeCourse() {
  const { id: courseId, version } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const token = useSelector(state => state.user.token);
  const userCourse = user.courses.find(el => el.course_id === courseId);
  const [taskNumber, setTaskNumber] = useState(
    userCourse.tasks_completed.length
  );
  const [allowNext, setAllowNext] = useState(true);
  const [completeTaskDialogOpen, setCompleteTaskDialogOpen] = useState(false);
  const quizAnswers = useRef({});

  // get course
  const url = apiRoutes.getCourse(courseId, version);
  const params = useRef({ _token: token });
  const { resp, error, loading } = AxiosGetHook(url, params.current);

  if (error)
    return <FormFeedback feedback={networkErrorMessageObjFactory(error)} />;
  if (loading)
    return (
      <Box
        className={classes.loader}
        display="flex"
        direction="row"
        justifyContent="center"
      >
        <Loader size="large" type={'infinite-spinner'} />
      </Box>
    );

  const course = resp.course;
  const currTask = course.tasks[taskNumber];
  const numberOfTasks = course.tasks.length;
  const currTaskResult = userCourse.tasks_completed[taskNumber];
  const tasksDone = taskNumber === numberOfTasks;

  // Next/ Back buttons go forward, backward, or back to "My Courses"
  const onBottomNavChange = (_, newValue) => {
    if (newValue === 'next' && !tasksDone) {
      if (!allowNext) {
        setCompleteTaskDialogOpen(true);
      } else {
        // if a new task was completed for the user update user DB record
        if (!userCourse.tasks_completed[taskNumber])
          dispatch(updateTasksCompleted(user.id, courseId, taskNumber, token));
        setTaskNumber(taskNumber + 1);
      }
    } else if (newValue === 'back' && taskNumber > 0) {
      setTaskNumber(taskNumber - 1);
    } else if ((newValue === 'next' && tasksDone) || newValue === 'back') {
      history.push(appRoutes.myCourses);
    }
  };

  const handleCloseDialog = () => setCompleteTaskDialogOpen(false);

  return (
    <Box className={classes.wrapper}>
      <Card className={classes.card}>
        <Hidden smDown>
          <CardHeader
            title={`Course: ${userCourse.title}`}
            className={classes.header}
          />
        </Hidden>
        <CardContent className={classes.cardContent}>
          {currTask?.type === 'Document' && (
            <DocumentTask
              currTask={currTask}
              token={token}
              setAllowNext={setAllowNext}
            />
          )}
          {currTask?.type === 'Signature' && (
            <SignatureTask
              currTask={currTask}
              setAllowNext={setAllowNext}
              currTaskDone={!!currTaskResult}
              setTaskNumber={setTaskNumber}
              userCourse={userCourse}
              taskNumber={taskNumber}
              user={user}
              token={token}
            />
          )}
          {currTask?.type === 'Question' && (
            <QuizTask
              setAllowNext={setAllowNext}
              currTask={currTask}
              currTaskResult={currTaskResult}
              userId={user.id}
              courseId={courseId}
              taskNumber={taskNumber}
              token={token}
              quizAnswers={quizAnswers}
            />
          )}

          {currTask?.type === 'Video' && (
            <VideoTask
              currTask={currTask}
              setAllowNext={setAllowNext}
              currTaskDone={!!currTaskResult}
              userId={user.id}
              userCourse={userCourse}
              taskNumber={taskNumber}
              token={token}
            />
          )}
          {tasksDone && (
            <TasksComplete
              user={user}
              userCourse={userCourse}
              token={token}
              passingScore={course.passing_percentage}
              setTaskNumber={setTaskNumber}
            />
          )}
        </CardContent>
      </Card>

      <BottomNavigation
        showLabels
        onChange={onBottomNavChange}
        className={classes.bottomNavigation}
      >
        <BottomNavigationAction
          label="Back"
          value="back"
          className={classes.bottomNavigationAction}
          icon={<ArrowBack />}
        />
        <BottomNavigationAction
          label={tasksDone ? 'Finish' : 'Next'}
          value="next"
          className={classes.bottomNavigationAction}
          icon={<ArrowForward />}
        />
      </BottomNavigation>

      <Dialog
        open={completeTaskDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Please complete current task.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You must complete the current task before proceeding to the next
            task.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TakeCourse;
