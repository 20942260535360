import { useEffect, useCallback, useRef, useState, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Form, FormElement } from '@progress/kendo-react-form';
import { RadioButton } from '@progress/kendo-react-inputs';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';

// local
import { updateTasksCompleted } from '../../../redux/actions/thunkCreators';
import {
  successMessageObjFactory,
  errorMessageObjFactory,
  FormFeedback,
} from '../../../utils/userFeedback';
import './QuizTask.scss';

const useStyles = makeStyles(
  theme => ({
    quizQuestion: {
      fontSize: 'min(2vw + 14px, 4vh + 14px)',
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '10px',
      lineHeight: 1.25,
      [theme.breakpoints.up('sm')]: {
        margin: '20px 40px 0',
      },
    },
    choicesDiv: {
      [theme.breakpoints.up('md')]: {
        padding: '0 5vw',
      },
    },
    quizChoices: {
      fontSize: 'min(1.5vw + 12px, 2.5vh + 12px)',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
    },
    feedbackDiv: {
      height: 40,
    },
  }),
  { classNamePrefix: 'wp' }
);

// highlight correct answer in green and show user answer in red if incorrect
const highlightAnswer = (
  answer,
  userAnswer,
  answerChoices,
  newResult,
  setFormFeedback
) => {
  const answerCorrect = userAnswer === answer;
  if (newResult)
    answerChoices[answer - 1].current.classList.add(
      'highlight-answer',
      'highlight-answer-animate'
    );
  else answerChoices[answer - 1].current.classList.add('highlight-answer');

  if (answerCorrect) {
    setFormFeedback(successMessageObjFactory('Correct!'));
  } else {
    answerChoices[userAnswer - 1].current.style.color = '#eb2f2f';
    answerChoices[userAnswer - 1].current.style.fontStyle = 'italic';
    setFormFeedback(errorMessageObjFactory('Incorrect!'));
  }
};

const QuizTask = ({
  currTask,
  setAllowNext,
  currTaskResult,
  userId,
  courseId,
  taskNumber,
  token,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formFeedback, setFormFeedback] = useState(null);
  const [selectedAnswerChoice, setSelectedAnswerChoice] = useState(null);
  const feedbackRef = useRef(null);
  const formRenderPropsRef = useRef(null);
  const [answerChoices, setAnswerChoices] = useState([]);

  // create refs for radio button highlighting
  useEffect(() => {
    setAnswerChoices(() => {
      return Array(currTask.choices.length)
        .fill()
        .map((_, i) => createRef());
    });
    if (!currTaskResult) {
      setSelectedAnswerChoice(null);
    }
  }, [currTask, currTaskResult]);

  // update feedback, highlighting, answer choice selection
  useEffect(() => {
    // highlight answer on component load if task already done
    if (currTaskResult && answerChoices[0]) {
      highlightAnswer(
        currTask.answer,
        currTaskResult.answer,
        answerChoices,
        false,
        setFormFeedback
      );
      setSelectedAnswerChoice(currTaskResult.answer);
    } else {
      // clear feedback and any radio button selection
      setFormFeedback(null);
      setSelectedAnswerChoice(null);
    }
  }, [currTask.answer, currTaskResult, answerChoices]);

  // make form change to enable submit button
  const enableForm = useCallback(
    () =>
      formRenderPropsRef.current.onChange('title', {
        value: formRenderPropsRef.current.valueGetter('title'),
      }),
    []
  );

  const handleChange = useCallback(
    e => {
      setSelectedAnswerChoice(e.value);
      enableForm();
    },
    [setSelectedAnswerChoice, enableForm]
  );

  const handleSubmit = useCallback(() => {
    if (!currTaskResult) {
      dispatch(
        updateTasksCompleted(
          userId,
          courseId,
          taskNumber,
          token,
          selectedAnswerChoice
        )
      );
    }
    setAllowNext(true);

    highlightAnswer(
      currTask.answer,
      selectedAnswerChoice,
      answerChoices,
      true,
      setFormFeedback
    );
  }, [
    courseId,
    currTask.answer,
    dispatch,
    setAllowNext,
    taskNumber,
    token,
    userId,
    currTaskResult,
    selectedAnswerChoice,
    answerChoices,
  ]);

  // reset form on task number change
  useEffect(() => {
    formRenderPropsRef.current.onFormReset();
  }, [taskNumber]);

  useEffect(() => {
    if (!currTaskResult) setAllowNext(false);
    else setAllowNext(true);
  }, [setAllowNext, currTaskResult]);

  return (
    <Form
      onSubmit={handleSubmit}
      className={classes.quizForm}
      render={formRenderProps => {
        formRenderPropsRef.current = formRenderProps;

        return (
          <FormElement>
            <legend className={classes.quizQuestion}>{currTask.text}</legend>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box className={classes.choicesDiv}>
                <Box className={classes.feedbackDiv}>
                  {formFeedback && (
                    <FormFeedback
                      feedback={formFeedback}
                      feedbackRef={feedbackRef}
                      variant="h5"
                      style={{ textAlign: 'center' }}
                    />
                  )}
                </Box>
                <ul
                  className="k-radio-list k-list-vertical"
                  aria-labelledby=""
                  aria-describedby=" "
                  dir="ltr"
                >
                  {currTask.choices.map((el, i) => (
                    <li
                      className="k-radio-item"
                      key={el.value}
                      ref={answerChoices[i]}
                    >
                      <Box className={classes.quizChoices}>
                        <RadioButton
                          name="choices"
                          value={el.value}
                          checked={selectedAnswerChoice === el.value}
                          label={el.label}
                          onChange={handleChange}
                        />
                      </Box>
                    </li>
                  ))}
                </ul>
              </Box>
              <Box display="flex" justifyContent="center" mt={2} pb={3}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || !!currTaskResult}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </FormElement>
        );
      }}
    />
  );
};

export default QuizTask;
