import { useState } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import { v4 as uuidv4 } from 'uuid';

// local imports
import { Routes as ApiRoutes } from '../../utils/apiAgent';
import {
  networkErrorMessageObjFactory,
  scrollFeedbackIntoView,
} from '../../utils/userFeedback';
import { allowedUploadExtensions } from '../../utils/documentTypes';

// File  Input
export const FileInput = ({
  formRenderProps,
  setFormFeedback,
  token,
  feedbackRef,
  handleNameChange,
}) => {
  const [files, setFiles] = useState([]);
  const [saveUrl, setSaveUrl] = useState(null);

  // When file is first added.
  function onAdd(event) {
    // formReset();
    // get file and file data variables
    const file = event.affectedFiles[0];
    const filenameGroups = file.name.match(/^(.+)\.\w+$/);
    const filename = filenameGroups[1];
    const fileExtension = file.extension;
    const uuid = uuidv4();
    const id = uuid + '_' + filename;
    const fileSizeKb = Math.round(file.size / 1024);

    // set form data from file data
    // if user hasn't entered a name for the file use filename from above.
    const nameValue = formRenderProps.valueGetter('name');
    if (!nameValue) {
      formRenderProps.onChange('name', { value: filename });
      if (handleNameChange) handleNameChange(formRenderProps);
    }
    formRenderProps.onChange('file_extension', { value: fileExtension });
    formRenderProps.onChange('file_size_KB', { value: fileSizeKb });
    formRenderProps.onChange('file_size', { value: file.size });
    formRenderProps.onChange('id', { value: id });

    // create / set file upload URL
    // category is used for upload authorization
    const category = formRenderProps.valueGetter('category') || 'General';
    const uploadUrl = ApiRoutes.uploadDoc(id, token, category);
    setSaveUrl(uploadUrl);

    setFormFeedback(null);
    setFiles(event.newState);
  }

  // When file is removed from upload component
  function onRemove(event) {
    // reset form
    formRenderProps.onChange('name', { value: '' });
    formRenderProps.onChange('file_extension', { value: '' });
    formRenderProps.onChange('file_size_KB', { value: 0 });
    formRenderProps.onChange('file_size', { value: 0 });
    formRenderProps.onChange('id', { value: '' });
    setFormFeedback(null);
    setFiles(event.newState);
  }

  function onProgress(event) {
    setFiles(event.newState);
  }

  // When file upload status changes
  function onStatusChange(event) {
    const uploadFailureStatus = 0;
    const uploadSuccessfulStatus = 4;
    const status = event.newState[0].status;

    if (status === uploadFailureStatus) {
      setFormFeedback(networkErrorMessageObjFactory(event.response));
      if (feedbackRef.current) scrollFeedbackIntoView(feedbackRef);
    }
    // if file uploaded successfully submit metadata form.
    else if (status === uploadSuccessfulStatus) {
      formRenderProps.onSubmit();
    }
    setFiles(event.newState);
  }

  return (
    <Upload
      batch={false}
      restrictions={{
        allowedExtensions: allowedUploadExtensions,
        // max size 25 Megabytes
        maxFileSize: 26214400,
      }}
      multiple={false}
      files={files}
      onAdd={onAdd}
      onRemove={onRemove}
      onProgress={onProgress}
      onStatusChange={onStatusChange}
      withCredentials={false}
      autoUpload={false}
      saveUrl={saveUrl}
      // removeUrl necessary for delete button to show after file upload complete
      // which is used to reset form and upload another file.
      // Also the link in the form feedback success alert hooks into (clicks)
      // delete button to reset form.
      removeUrl={ApiRoutes.return200}
    />
  );
};
